import React from "react";
import "./Dashcards.css";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function Dashcards(props) {
  const navigate = useNavigate();
  return (
    <>
      <Row
        onClick={() => {
          if (props.path) {
            navigate(`${props.path}`);
          }
        }}
      >
        <Col>
          <div id="dashcard1">
            <Container>
              <Row>
                <Col
                  md={3}
                  lg={3}
                  sm={3}
                  xs={3}
                  style={{
                    border: props.border,
                    background: props.background,
                    padding: props.padding,
                    fontSize: props.fontsize,
                    fontWeight: props.fontweight,
                    margin: props.margin,
                    cursor: props.cursor,
                    width: props.width,
                    borderRadius: props.borderRadius,
                  }}
                >
                  <div id="dashcard2">{props.icon}</div>
                </Col>
                <Col md={9} lg={9} sm={9} xs={9}>
                  <div id="dashcard4">{props.title}</div>
                  <div id="dashcard5">{props.amount}</div>
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
      </Row>
    </>
  );
}
