import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row, Form, Modal } from "react-bootstrap";
import "./AddCourseAdminModal.css";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import GlobalButton from "../../components/GlobalButton/GlobalButton";
import Utility from "../../utils/Utility";
import { apicaller } from "../../utils/api";
import { clear } from "../../Redux/Slice/UserSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const AddCourseAdminModal = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Conformpassref = useRef(null);
  const [ispasswordvisible, setispasswordvisible] = useState({
    password: false,
    confirmpassword: false,
  });

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [number, setNumber] = useState("");
  const [privileges, setPrivileges] = useState(["create", "edit", "remove"]);
  const [loading, setLoading] = useState(false);

  const regEmail =
    /^[a-zA-Z0-9+.-]+@[a-zA-Z]+?(\.[a-zA-Z]{2,3})|(\.[a-zA-Z]{2,3}\.[a-zA-Z]{2,3})$/;

  const validation = () => {
    if (
      firstname?.trim() === "" ||
      lastname?.trim() === "" ||
      email?.trim() === "" ||
      number?.trim() === "" ||
      password?.trim() === "" ||
      confirmPassword?.trim() === ""
    ) {
      Utility.eToast("Please fill all the fields");
      return false;
    } else if (!regEmail.test(email)) {
      Utility.eToast("Enter A Valid Email");
      return false;
    } else if (number.length !== 10) {
      Utility.eToast("Enter a valid Phone Number");
      return false;
    } else if (password !== confirmPassword) {
      Utility.eToast("The Confirm Password should be equal to Password");
      Conformpassref.current.focus();
      return false;
    }

    return true;
  };

  const handleInputChange = (setData, e) => {
    setData(e.target.value);
  };

  const Passwordhideunhidecomponent = ({ hidden, clicked }) => {
    return (
      <span
        onClick={clicked}
        className="Passwordhideunhidecomponent translate-middle-y"
      >
        {hidden ? <FaEyeSlash /> : <FaEye />}
      </span>
    );
  };
  const handlePasswordVisibilityToggle = (field) => {
    setispasswordvisible((prevVisibility) => ({
      ...prevVisibility,
      [field]: !prevVisibility[field],
    }));
  };

  const createSubadmin = async () => {
    if (validation()) {
      setLoading(true);
      const data = {
        first_name: firstname,
        last_name: lastname,
        email: email,
        password: password,
        phone_number: number,
        priviliges: privileges,
        login: true,
      };
      apicaller("course-admin-register", data, "POST", null)
        .then((response) => {
          setFirstname("");
          setLastname("");
          setEmail("");
          setPassword("");
          setConfirmPassword("");
          setNumber("");
          props.getallcourseadmin();
          props.onHide();
        })
        .catch((err) => {
          if (err?.response?.data?.error === "Token Session expired") {
            Utility.eToast(
              "Your token has expired, Please Login again to continue!"
            );
            dispatch(clear());
            navigate("/login");
          } else {
            Utility.eToast("Unable to upload! Please try again.");
          }
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>Add Course Admin</Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label className="Academicaddacourseadmintext5 mt-0">
                    First Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter full name"
                    value={firstname}
                    onChange={(e) => handleInputChange(setFirstname, e)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label className="Academicaddacourseadmintext5 mt-0">
                    Last Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter full name"
                    value={lastname}
                    onChange={(e) => handleInputChange(setLastname, e)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group>
              <Form.Label className="Academicaddacourseadmintext5">
                Phone Number
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Your number"
                value={number}
                onChange={(e) => handleInputChange(setNumber, e)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="Academicaddacourseadmintext5">
                Email
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email address"
                autoComplete="on"
                value={email}
                onChange={(e) => handleInputChange(setEmail, e)}
              />
            </Form.Group>
            <Form.Group className="position-relative">
              <Form.Label className="Academicaddacourseadmintext5">
                Password
              </Form.Label>
              <Form.Control
                type={ispasswordvisible.password ? "text" : "password"}
                placeholder="Enter password"
                value={password}
                autoComplete="current-password"
                onChange={(e) => handleInputChange(setPassword, e)}
              />
              <Passwordhideunhidecomponent
                hidden={ispasswordvisible.password}
                clicked={() => handlePasswordVisibilityToggle("password")}
              />
            </Form.Group>
            <Form.Group className="position-relative">
              <Form.Label className="Academicaddacourseadmintext5">
                Confirm Password
              </Form.Label>
              <Form.Control
                ref={Conformpassref}
                type={ispasswordvisible.confirmpassword ? "text" : "password"}
                id="AcademicaddacourseadminconfirmPassword"
                placeholder="Confirm password"
                value={confirmPassword}
                onChange={(e) => handleInputChange(setConfirmPassword, e)}
              />
              <Passwordhideunhidecomponent
                hidden={ispasswordvisible.confirmpassword}
                clicked={() =>
                  handlePasswordVisibilityToggle("confirmpassword")
                }
              />
            </Form.Group>
            <div className="Academicaddacourseadminheading mt-3">
              Permissions
            </div>

            <Form>
              {["checkbox"].map((type) => (
                <div key={`inline-${type}`} className="mb-3">
                  <Form.Check
                    className="Academicaddacourseadmintext5"
                    inline
                    label="Create Access"
                    name="access"
                    type={type}
                    id={`inline-${type}-1`}
                    defaultChecked
                    disabled
                  />
                  <Form.Check
                    className="Academicaddacourseadmintext5"
                    inline
                    label="Editing Access"
                    name="access"
                    type={type}
                    id={`inline-${type}-2`}
                    defaultChecked
                    disabled
                  />
                </div>
              ))}
            </Form>
            <div id="addCourseAdminModalAlertLine">
              If you want to change access, Please go to{" "}
              <span style={{ fontWeight: "600" }}>View Details</span> of Course
              Admin.
            </div>
            <GlobalButton
              title="Create"
              loading={loading}
              onclick={createSubadmin}
            />
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddCourseAdminModal;
