import React, { useState } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import "./CourseCard.css";
import { useNavigate } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import EditNewCourseModal from "../EditCourseModal/EditCourseModal";
import { useSelector } from "react-redux";
import { getUserToken } from "../../Redux/Slice/UserSlice";

export default function CourseCard({ courseDetails, onEdit }) {
  const navigate = useNavigate();
  const [showEditCourseModal, setShowEditCourseModal] = useState(false);
  const token = useSelector(getUserToken);
  console.log(courseDetails, "courseDetails");
  return (
    <Container id="cardContainter">
      <Row>
        <Col id="cc1">
          <div id="cc3">
            <Image
              src={courseDetails?.img}
              alt="Course image"
              className="tcc15"
            />
          </div>
          <div id="cc2">
            <div style={{ width: "80%" }}>{courseDetails?.name || "NA"}</div>
            <div className="tcc11">
              {courseDetails?.visiblility === "inactive" ? "HIDDEN" : "LIVE"}
            </div>
          </div>
          {/* <div id="cc4">
            by {courseDetails?.createdBy?.first_name}{" "}
            {courseDetails?.createdBy?.last_name}
          </div> */}
          <Row>
            <Col xs={4} sm={4} md={4} lg={4} className="tcc13">
              {courseDetails?.price + "/-" || "NA"}
            </Col>
            <Col xs={8} sm={8} md={8} lg={8} className="tcc14">
              <Rating
                stop={5}
                initialValue={courseDetails?.ratings || 0}
                allowFraction
                size={20}
                readonly
              />
              {/* <StarComponent courseRating={courseDetails?.ratings || 0} /> */}
            </Col>
          </Row>
          <Row>
            <Col className="tcc16">
              {courseDetails?.students?.length > 0
                ? `${courseDetails?.students?.length} Students Enrolled`
                : `People haven't enrolled recently`}
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                className="tcc17"
                onClick={() => {
                  if (token) {
                    navigate(
                      `/courses/coursedetails?category=${courseDetails?.category?.category_name
                        ?.replace(/\s+/g, "-")
                        ?.toLowerCase()}&coursename=${encodeURIComponent(
                        courseDetails?.name?.replace(/\s+/g, "-")
                      )?.toLowerCase()}&id=${courseDetails?._id}`
                    );
                  } else {
                    setTimeout(() => {
                      navigate("/");
                    }, 1000);
                  }
                }}
              >
                Manage
              </Button>
            </Col>
            <Col>
              <Button className="tcc17" onClick={onEdit}>
                Edit
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
