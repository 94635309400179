import React from "react";
import { useState } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "./ServicesEdit.css";
import Header2 from "../../../../components/Header2/Header2";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useDispatch, useSelector } from "react-redux";
import { clear, getUserToken } from "../../../../Redux/Slice/UserSlice";
import { API, apicaller } from "../../../../utils/api";
import Utility from "../../../../utils/Utility";
import GlobalButton from "../../../../components/GlobalButton/GlobalButton";

export default function ServiceEdit() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const aboutusedit = location.state.id;
  const aboutid = location.state.id._id;
  const [aboutDescription, setAboutDescription] = useState(
    aboutusedit.description
  );
  const [title, setTitle] = useState(aboutusedit.title);

  const [loading, setLoading] = useState(false);

  const token = useSelector(getUserToken);

  const saveBtn = async (e) => {
    if (aboutDescription.length > 0) {
      setLoading(true);
      const data = {
        _id: aboutid,
        description: aboutDescription,
        title: title,
      };

      await apicaller(`service`, data, "PUT", token, "application/json")
        .then((res) => {
          Utility.sToast("Data Saved Successfully");
          setLoading(false);
          navigate(-1);
        })
        .catch((err) => {
          if (err?.response?.data?.error === "Token Session expired") {
            Utility.eToast(
              "Your token has expired, Please Login again to continue!"
            );
            dispatch(clear());
            navigate("/login");
          } else {
            Utility.eToast("Could not Update!");
          }
          setLoading(false);
        });
    } else {
      Utility.eToast("Add Description !");
      return false;
    }
  };

  return (
    <>
      <Container>
        <Row id="privacyEdit1">
          <Col>
            <Form>
              <Form.Group className="mt-3 mb-3" controlId="formBasicEmail">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Description</Form.Label>
                <CKEditor
                  editor={ClassicEditor}
                  data={`${
                    aboutDescription ? aboutDescription : "<p>Loading...</p>"
                  }`}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setAboutDescription(data);
                  }}
                />
              </Form.Group>
            </Form>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: 10,
              }}
            >
              <GlobalButton
                title="Cancel"
                width={150}
                height={46}
                backgroundColor="transparent"
                borderColor="lightslategray"
                textColor="lightslategray"
                fontWeight={400}
                onclick={() => {
                  navigate(-1);
                }}
              />
              <GlobalButton
                title={loading ? "Updating" : "Update"}
                loading={loading}
                width={150}
                height={46}
                onclick={saveBtn}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
