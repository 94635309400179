import React from "react";
import "./ConfirmationModal.css";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Loader from "../Loader/Loader";

export default function ConfirmationModal(props) {
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {" "}
          <div style={{ fontSize: "1.1rem", fontWeight: "500" }}>
            {props?.header || "Enter your header"}
          </div>
        </Modal.Header>
        <Modal.Body
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{ color: "#565756", fontSize: "1rem", fontWeight: "400" }}
          >
            {props?.title || "Enter your title"}
          </div>
        </Modal.Body>
        <Row id="cnf1">
          <Col md={4} lg={4} sm={12} xs={12}></Col>
          <Col md={2} lg={2} sm={12} xs={12}>
            <Button size="sm" id="cnf3" onClick={props.onHide}>
              No
            </Button>
          </Col>
          <Col md={2} lg={2} sm={12} xs={12}>
            {" "}
            <Button size="sm" id="cnf2" onClick={props.onYes}>
              {props.isApproving ? <Loader size="sm" /> : "Yes"}
            </Button>
          </Col>
          <Col md={4} lg={4} sm={12} xs={12}></Col>
        </Row>
      </Modal>
    </>
  );
}
