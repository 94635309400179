import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import "./EditSlotPage.css";
import { apicaller } from "../../utils/api";
import { useSelector } from "react-redux";
import Utility from "../../utils/Utility";
import Loader from "../Loader/Loader";
import { IoIosCloseCircle } from "react-icons/io";

export default function EditSlotPage() {
  const location = useLocation();
  const { token, userid, isLoading, userInfo } = useSelector(
    (state) => state?.user
  );
  console.log("Token is......", token);
  const navigate = useNavigate();

  const time = location?.state?.res?.time;
  const startdate = location?.state?.res?.start_date;
  const enddate = location?.state?.res?.end_date;
  const slotid = location?.state?.res?._id;

  const [slotArray, setSlotArray] = useState(location?.state?.res.time);
  const [startHour, setStartHour] = useState("");
  const [startMinute, setStartMinute] = useState("");
  const [startAm, setStartAm] = useState("AM");
  const [endHour, setEndHour] = useState("");
  const [endMinute, setEndMinute] = useState("");
  const [endAm, setEndAm] = useState("AM");
  const [courseStartDate, setCourseStartDate] = useState(startdate);
  const [courseEndDate, setCourseEndDate] = useState(enddate);
  const [updateBtnLoader, setUpdateBtnLoader] = useState(false);

  console.log("course id...", slotid);

  const removeSlot = (index) => {
    const updatedSlots = [...slotArray];
    updatedSlots.splice(index, 1);
    setSlotArray(updatedSlots);
  };

  function validation() {
    if (slotArray.length === 0) {
      Utility.eToast("Please Add a Slot To Update");
      return false;
    } else {
      return true;
    }
  }

  const addSlotFunction = async () => {
    if (
      startHour === "" ||
      endHour === "" ||
      startMinute === "" ||
      endMinute === ""
    ) {
      Utility.eToast("Please Fill All Fields For Slot");
      return false;
    } else if (
      parseInt(startHour) <= 0 ||
      parseInt(startHour) > 12 ||
      parseInt(endHour) <= 0 ||
      parseInt(endHour) > 12 ||
      parseInt(startMinute) < 0 ||
      parseInt(startMinute) > 59 ||
      parseInt(endMinute) < 0 ||
      parseInt(endMinute) > 59
    ) {
      Utility.eToast("Please Check The Added Slot");
      return false;
    } else {
      const startSlot = `${startHour}:${startMinute}:${startAm}`;
      const endSlot = `${endHour}:${endMinute}:${endAm}`;
      const final = `${startSlot}-${endSlot}`;
      slotArray.splice(0, 0, final);
      const slot = slotArray?.map((item) => item);
      setSlotArray(slot);
      setStartHour("");
      setEndHour("");
      setStartMinute("");
      setEndMinute("");
    }
  };

  const removeSlotFunction = (i) => {
    slotArray.splice(i, 1);
    const slot = slotArray?.map((item) => item);
    setSlotArray(slot);
  };

  const updateSlot = async () => {
    if (validation()) {
      setUpdateBtnLoader(true);
      const data = {
        time: slotArray,
        _id: slotid,
        start_date: courseStartDate,
        end_date: courseEndDate,
      };
      await apicaller("slot", data, "put", token)
        .then((res) => {
          Utility.sToast(res?.data?.message);
          navigate(-1);
        })
        .catch(() => {
          Utility.eToast("Something Went Wrong");
        })
        .finally(() => {
          setUpdateBtnLoader(false);
        });
    }
  };

  return (
    <>
      {/* <HeaderNav /> */}
      <Container>
        <Row id="editslot1">
          <Col>
            <Row>
              <Col id="editslot2"> Edit slot</Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col id="editslot1">
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Select Course Start Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={courseStartDate}
                    onChange={(e) => setCourseStartDate(e.target.value)}
                    id="addcoursemodal1"
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Enter Course End Date</Form.Label>
                  <Form.Control
                    type="date"
                    value={courseEndDate}
                    onChange={(e) => setCourseEndDate(e.target.value)}
                    id="addcoursemodal1"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col id="editslot3"> Select start time and end time</Col>
            </Row>
            <Row>
              <Col lg={2} md={2} xs={4} sm={4} style={{ marginBottom: "1rem" }}>
                <Form.Control
                  type="text"
                  placeholder="Starting Hours"
                  id="editslot7"
                  vaclue={startHour}
                  onChange={(e) => setStartHour(e.target.value)}
                />
              </Col>
              <Col lg={2} md={2} xs={4} sm={4} style={{ marginBottom: "1rem" }}>
                <Form.Control
                  type="text"
                  placeholder="Starting Minutes"
                  value={startMinute}
                  onChange={(e) => setStartMinute(e.target.value)}
                  id="editslot7"
                />
              </Col>
              <Col lg={2} md={2} xs={4} sm={4} style={{ marginBottom: "1rem" }}>
                <Form.Select
                  id="editslot7"
                  value={startAm}
                  onChange={(e) => setStartAm(e.target.value)}
                >
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </Form.Select>
              </Col>
              <Col lg={2} md={2} xs={4} sm={4} style={{ marginBottom: "1rem" }}>
                <Form.Control
                  type="text"
                  placeholder="End Hours"
                  id="editslot7"
                  value={endHour}
                  onChange={(e) => setEndHour(e.target.value)}
                />
              </Col>
              <Col lg={2} md={2} xs={4} sm={4} style={{ marginBottom: "1rem" }}>
                <Form.Control
                  type="text"
                  placeholder="Minutes"
                  id="editslot7"
                  value={endMinute}
                  onChange={(e) => setEndMinute(e.target.value)}
                />
              </Col>
              <Col lg={2} md={2} xs={4} sm={4} style={{ marginBottom: "1rem" }}>
                <Form.Select
                  id="editslot7"
                  value={endAm}
                  onChange={(e) => setEndAm(e.target.value)}
                >
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </Form.Select>
              </Col>
            </Row>
            <Row>
              <Col>
                {" "}
                <Button onClick={() => addSlotFunction()} id="editslot4">
                  Add Slot
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row id="editslot1">
          {" "}
          <Col>
            <Row>
              <Col id="editslot2"> All Slots </Col>
            </Row>
            <hr></hr>
            <Row>
              {slotArray?.map((item, index) => (
                <Col md={3} lg={3} xl={3} sm={6} xs={6} className="mt-2 mb-2">
                  <div id="editslot6" onClick={() => removeSlot(index)}>
                    <IoIosCloseCircle id="editslot5" />
                  </div>
                  <div id="editslot10"> {item}</div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>

        <Row>
          <Col>
            <Button id="editslot9" onClick={updateSlot}>
              {updateBtnLoader ? (
                <Loader animation="border" size="sm" />
              ) : (
                "Update"
              )}
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}
