import React, { useEffect, useState } from "react";
import moment from "moment";
import Utility from "../../../utils/Utility";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GrDocumentText } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { clear, setActiveMenu } from "../../../Redux/Slice/UserSlice";
import GlobalButton from "../../GlobalButton/GlobalButton";
import { apicaller } from "../../../utils/api";

const styles = {
  // fontFamily: "IBM Plex Sans",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "20px",
  letterSpacing: "0.05em",
};

const ExamStyles = {
  // fontFamily: "IBM Plex Sans",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "20px",
  letterSpacing: "0.05em",
  marginBottom: "0.6rem",
};

export default function useCourseSection() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token, isLoading, userInfo } = useSelector((state) => state?.user);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const courseId = searchParams.get("id");
  const createPrivilege = userInfo?.priviliges?.includes("create");
  const editPrivilege = userInfo?.priviliges?.includes("edit");
  const deletePrivilege = userInfo?.priviliges?.includes("remove");

  //   loader states
  const [isCourseSectionLoading, setIsCourseSectionLoading] = useState(false);
  //   loader states

  const [value, setValue] = useState("studentEnrolled");
  const [sectionName, setSectionName] = useState("");
  const [courseSectionList, setCourseSectionList] = useState([]);
  const [sectionChapter, setSectionChapter] = useState([]);
  const [showAddChapterModal, setShowAddChapterModal] = useState(false);
  const [showEditSectionModal, setShowEditSectionModal] = useState(false);
  const [showEditChapterModal, setShowEditChapterModal] = useState(false);
  const [courseAndSectionId, setCourseAndSectionId] = useState({});
  const [sectionDataToModal, setSectionDataToModal] = useState({});
  const [selectedSectionId, setSelectedSectionId] = useState("");
  const [allAssignments, setAllAssignments] = useState([]);
  const [allExams, setAllExams] = useState([]);
  const [editChapterData, setEditChapterData] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedExamId, setSelectedExamId] = useState("");
  const [isApproving, setIsApproving] = useState(false);
  const [selectedChapter, setSelectedChapter] = useState("");
  const [courseAllDetails, setcourseAllDetails] = useState(false);
  const [isConfirmedDeleteChapter, setIsConfirmedDeleteChapter] =
    useState(false);
  const [isDeletingChapter, setIsDeletingChapter] = useState(false);
  const [selectedChapterId, setSelectedChapterId] = useState("");

  useEffect(() => {
    getCourseSectionList();
    getAllAssignmentOfCourse();
    getAllExamOfCourse();
    getCourseDetails();
  }, []);

  const getCourseDetails = async () => {
    await apicaller(`course/${courseId}`, null, "get", token)
      .then((res) => {
        console.log("course details", res?.data);
        setcourseAllDetails(res?.data?.course);
      })
      .catch(() => {
        Utility.eToast("Oops Something Went Wrong");
      });
  };

  const getCourseSectionList = async () => {
    try {
      await apicaller(`get-section/${courseId}`, null, "GET", token).then(
        (res) => {
          setCourseSectionList(res?.data);
          console.log("course section list res", res?.data);
        }
      );
    } catch (err) {
      if (err?.response?.data?.error === "Token Session expired") {
        Utility.eToast("Token expired, Please login again ! ");
        dispatch(clear());
        navigate("/");
      } else {
        Utility.eToast(err?.response?.data?.error || err?.message);
      }
    }
  };

  const getCourseChapterBySection = async (sectionId) => {
    try {
      await apicaller(`course-module/${sectionId}`, null, "GET", token).then(
        (res) => {
          console.log("course chapter res", res);
          setSelectedSectionId(sectionId);
          setSectionChapter(res?.data);
          console.log("section chapter", res.data);
        }
      );
    } catch (err) {
      if (err?.response?.data?.error === "Token Session expired") {
        Utility.eToast("Token expired, Please login again ! ");
        dispatch(clear());
        // dispatch(setActiveMenu("Dashboard"));
        navigate("/");
      } else {
        Utility.eToast(err?.response?.data?.error || err?.message);
      }
    }
  };

  const getAllAssignmentOfCourse = async () => {
    await apicaller(`assignment/${courseId}`, null, "GET", token)
      .then((res) => {
        console.log("all assignment of course res", res?.data?.reverse());
        setAllAssignments(res?.data?.reverse());
      })
      .catch((err) => {
        console.error("err in all assignment of course res", err);
      });
  };

  const getAllExamOfCourse = async () => {
    await apicaller(`exambycoursee/${courseId}`, null, "GET", token)
      .then((res) => {
        console.log("all exam of course res", res?.data?.examData);
        setAllExams(res?.data?.examData);
      })
      .catch((err) => {
        console.error("err in all exam of course res", err);
      });
  };

  const isValidCourseSectionName = () => {
    if (sectionName?.length === 0) {
      Utility.eToast("Please Enter a Section Name");
      return false;
    } else {
      return true;
    }
  };

  const handleAddCourseSection = async () => {
    if (isValidCourseSectionName()) {
      setIsCourseSectionLoading(true);
      const data = {
        course_id: courseId,
        title: sectionName,
      };
      await apicaller("add-section", data, "POST", token)
        .then((res) => {
          Utility.sToast(res?.data?.message);
          getCourseSectionList();
          setSectionName("");
          setIsCourseSectionLoading(false);
        })
        .catch((err) => {
          Utility.eToast(err?.response?.data?.error || err?.message);
          setIsCourseSectionLoading(false);
        });
    }
  };

  const handleAddCourseChapter = async (sectionId) => {
    const data = {
      sectionId: sectionId,
      courseId: courseId,
    };
    await setCourseAndSectionId(data);
    setSelectedSectionId(sectionId);
    setShowAddChapterModal(true);
  };

  const handleEditSectionName = async (sectionId, sectionName) => {
    const data = {
      sectionId: sectionId,
      sectionName: sectionName,
    };
    await setSectionDataToModal(data);
    setShowEditSectionModal(true);
  };

  const handleUpdateChapterInactive = async (sectionId) => {
    if (window.confirm("Are you want to Inactive this chapter ?")) {
      const data = {
        _id: sectionId,
        visiblility: "inactive",
      };
      console.log("data going", data);

      await apicaller(`course-module`, data, "PUT", token)
        .then((res) => {
          console.log("section update res", res);
          getCourseChapterBySection(selectedSectionId);
          Utility.sToast("Chapter  is inactive");
        })
        .catch((err) => {
          console.error("chapter update res", err);
        });
    }
  };

  const handleUpdateChapterActive = async (sectionId) => {
    if (window.confirm("Are you want to Active this chapter ?")) {
      const data = {
        _id: sectionId,
        visiblility: "active",
      };
      console.log("data going", data);
      await apicaller("course-module", data, "PUT", token)
        .then((res) => {
          console.log("section update res", res);
          getCourseChapterBySection(selectedSectionId);
          Utility.sToast("Chapter is active");
        })
        .catch((err) => {
          console.error("section update res", err);
        });
    }
  };

  const handleApproveExam = async () => {
    setIsApproving(true);
    const data = {
      isCourseAdminApproved: "approved",
    };

    await apicaller(`exambycourseidedit/${selectedExamId}`, data, "PUT", token)
      .then((res) => {
        getAllExamOfCourse();
        setSelectedExamId("");
        setIsApproving(false);
        Utility.sToast("Exam Approved");
        setShowConfirmationModal(false);
      })
      .catch((err) => {
        if (err?.response?.data?.error === "Token Session expired") {
          Utility.eToast("Token expired, Please login again ! ");
          dispatch(clear());
          // dispatch(setActiveMenu("Dashboard"));
          navigate("/");
        } else {
          Utility.eToast(err?.response?.data?.error || err?.message);
        }
        setIsApproving(false);
      });
  };

  function returnStatusColorAsperStatus(status) {
    switch (status) {
      case "approved":
        return "green";
      default:
        return "#F5A201";
    }
  }
  const studentEnrolledList =
    courseAllDetails?.students?.length > 0 &&
    courseAllDetails?.students?.map((item, i) => ({
      studentid: i + 1,
      name: item?.first_name + " " + item?.last_name || "NA",
      email: item?.email || "NA",
      contact: item?.phone_number || "NA",
    }));

  console.log("student list", courseAllDetails?.students, studentEnrolledList);

  const allAssignmentList = allAssignments?.map((item, i) => ({
    slno: i + 1,
    topic: item?.topic,
    date: moment(item?.createdAt).format("DD/MM/YYYY"),
    student: item?.student?.map((item, i) => (
      <div key={i}>
        {item?.first_name} {item?.last_name},{" "}
      </div>
    )),
    assignmentdoc: (
      <a
        href={item?.document}
        target="_blank"
        style={{ cursor: "pointer", styles }}
      >
        <GrDocumentText size={21} />
      </a>
    ),
  }));

  const allExamList = allExams?.map((item, i) => ({
    slno: i + 1,
    examtopic: item?.topic || "NA",
    date: item?.examSlot.map((item) => (
      <div style={ExamStyles}>{moment(item?.date).format("DD-MM-YYYY")}</div>
    )),
    startime: item?.examSlot.map((item) => (
      <div style={ExamStyles}>{item?.startTime || "NA"}</div>
    )),
    endtime: item?.examSlot.map((item) => (
      <div style={ExamStyles}>{item?.endTime || "NA"}</div>
    )),
    slotcapacity: item?.examSlot.map((item) => item?.slotCapacity || "NA"),
    examlink: (
      <div
        style={{
          cursor: "pointer",
          color: "blue",
        }}
      >
        {item?.examLink || "NA"}
      </div>
    ),
    examstatus: item?.isCourseAdminApproved?.toUpperCase(),
    action: editPrivilege ? (
      <GlobalButton
        title="Approve"
        onclick={() => {
          setSelectedExamId(item?._id);
          setShowConfirmationModal(true);
        }}
        disabled={item?.isCourseAdminApproved == "approved" ? true : false}
      />
    ) : null,
  }));

  const handleDeleteChapter = async (sectionId) => {
    try {
      setIsDeletingChapter(true);
      const response = await apicaller(
        `course-module/${sectionId}`,
        {},
        "DELETE",
        token
      );
      Utility.sToast("Chapters deleted successfully");
      getCourseChapterBySection(selectedSectionId);
      console.log("getting deleted chapter", response);
    } catch (err) {
      if (err?.response?.data?.error === "Token Session expired") {
        Utility.eToast("Token expired, Please login again ! ");
        dispatch(clear());
        navigate("/");
      } else {
        Utility.eToast(err?.response?.data?.error || err?.message);
      }
      setIsApproving(false);
    } finally {
      setIsDeletingChapter(false);
      setIsConfirmedDeleteChapter(false);
    }
  };

  return {
    createPrivilege,
    editPrivilege,
    deletePrivilege,
    value,
    isApproving,
    courseAllDetails,
    sectionName,
    courseSectionList,
    showAddChapterModal,
    editChapterData,
    selectedSectionId,
    sectionChapter,
    showEditSectionModal,
    sectionDataToModal,
    allExamList,
    allAssignmentList,
    showEditChapterModal,
    showConfirmationModal,
    selectedChapter,
    setValue,
    setEditChapterData,
    setSectionName,
    setSelectedSectionId,
    setShowAddChapterModal,
    setShowEditSectionModal,
    setShowEditChapterModal,
    setShowConfirmationModal,
    setSelectedChapter,
    studentEnrolledList,
    isCourseSectionLoading,
    isLoading,
    handleAddCourseSection,
    getCourseChapterBySection,
    getCourseSectionList,
    handleAddCourseChapter,
    handleEditSectionName,
    handleUpdateChapterInactive,
    handleUpdateChapterActive,
    handleApproveExam,
    courseAndSectionId,
    dispatch,
    handleDeleteChapter,
    setIsConfirmedDeleteChapter,
    isConfirmedDeleteChapter,
    isDeletingChapter,
    setSelectedChapterId,
    selectedChapterId,
  };
}
