import React from "react";
import "./RoundLargeButton.css";

function RoundLargeButton(props) {
  return (
    <>
      <div
        className="rlb11"
        style={{
          backgroundColor: props?.backgroundColor
            ? props?.backgroundColor
            : "#f3f5fc",
          height: props?.height ?? "",
        }}
      >
        <div onClick={props.event}>
          <div className="rlb15">
            <div className="rlb14">
              <div className="rlb13">
                <div className="rlb12">{props.icon}</div>
                <div className="rlb12">{props.name}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default React.memo(RoundLargeButton);
