import React, { useEffect, useState } from "react";
import { Button, Container, Spinner } from "react-bootstrap";
import "./SettingsCoupon.css";
import TableComponent1 from "../../../../components/TableComponent1/TableComponent1";
import SettingsGlobalHeader from "../SettingsGlobalHeader/SettingsGlobalHeader";
import AddCouponModal from "../../../../components/CouponModal/AddCouponModal/AddCouponModal";
// import EditCoupnModal from "../../../../components/CouponModal/EditCouponModal/EditCouponModal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  GetAllCourses,
  clear,
  getUserToken,
} from "../../../../Redux/Slice/UserSlice";
import Utility from "../../../../utils/Utility";
import axios from "axios";
import { API, apicaller } from "../../../../utils/api";
import moment from "moment/moment";
import { unwrapResult } from "@reduxjs/toolkit";

const columns = [
  {
    accessorKey: "no",
    header: "No",
  },
  {
    accessorKey: "code",
    header: "Code",
  },
  {
    accessorKey: "percentage",
    header: "Percentage",
  },
  {
    accessorKey: "expiry",
    header: "Expiry",
  },
  {
    accessorKey: "courseId",
    header: "Courses",
  },
  {
    accessorKey: "status",
    header: "Status",
  },
  {
    accessorKey: "action",
    header: "Action",
  },
];

export default function SettingsCoupon() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector(getUserToken);

  const [couponData, setCouponData] = useState([]);
  const [addCouponModal, setAddCouponModal] = useState(false);
  // const [editCoupon, setEditCoupon] = useState(false);
  const [loadingCoupons, setLoadingCoupons] = useState(false);
  const [loadingEditCoupon, setLoadingEditCoupon] = useState("");
  const [loadingRemoveCoupon, setLoadingRemoveCoupon] = useState("");

  useEffect(() => {
    setLoadingCoupons(true);
    dispatch(GetAllCourses({ token: token }))
      .then(unwrapResult)
      .then((res) => {
        getCouponData();
      });
  }, []);

  const getCouponData = () => {
    setLoadingCoupons(true);
    apicaller("coupons", null, "get", token)
      .then((res) => {
        setCouponData(res?.data?.reverse());
        console.log("res in get coupon data", res?.data);
      })
      .catch((err) => {
        if (err?.response?.data?.error === "Token Session expired") {
          Utility.eToast(
            "Your token has expired, Please Login again to continue!"
          );
          dispatch(clear());
          navigate("/login");
          setLoadingCoupons(false);
        }
      })
      .finally(() => {
        setLoadingCoupons(false);
      });
  };

  const data = couponData?.map((item, i) => ({
    no: i + 1,
    code: <div id="tableCodeStyle">{item?.code}</div>,
    percentage: <div id="tablePercentageStyle">{item?.discountPercent}%</div>,
    expiry: (
      <div id="tableDateStyle">
        {moment(item?.expiryDate).format("DD-MM-YYYY")}
      </div>
    ),
    courseId: item?.courseId?.map((item, i) => (
      <div id="tableDateStyle" key={i} style={{marginBottom: 5, width: 300}}>
        {item?.name} ,
      </div>
    )),
    status: (
      <div id="tableStatusStyle">
        {moment().isAfter(moment(item?.expiryDate))
          ? "Expired"
          : item?.isActive
          ? "Active"
          : "Not Active"}
      </div>
    ),
    action: (
      <div id="tableActionDiv">
        <Button
          variant="danger"
          id="tableEditBtn"
          disabled={
            moment().isAfter(moment(item?.expiryDate)) ||
            loadingEditCoupon === item?._id
          }
          onClick={() => changeStatusOfCoupon(item)}
        >
          {item?.isActive ? "Deactivate" : "Activate"}{" "}
          {loadingEditCoupon === item?._id && (
            <Spinner
              animation="border"
              style={{ color: "#AE2A00" }}
              size="sm"
            />
          )}
        </Button>{" "}
        <Button
          variant="danger"
          id="tableRemoveBtn"
          disabled={loadingRemoveCoupon === item?._id}
          onClick={() => deleteCoupon(item)}
        >
          Remove{" "}
          {loadingRemoveCoupon === item?._id && (
            <Spinner
              animation="border"
              style={{ color: "#ED122C" }}
              size="sm"
            />
          )}
        </Button>
      </div>
    ),
  }));

  const deleteCoupon = (item) => {
    if (window.confirm(`Are you sure to delete coupon ${item?.code} ?`)) {
      setLoadingRemoveCoupon(item?._id);
      var config = {
        method: "delete",
        url: `${API}coupons/${item?._id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      axios(config)
        .then(function (response) {
          getCouponData();
          Utility.sToast("Coupon Deleted Sucessfully");
        })
        .catch(function (err) {
          if (err?.response?.data?.error === "Token Session expired") {
            Utility.eToast(
              "Your token has expired, Please Login again to continue!"
            );
            dispatch(clear());
            navigate("/login");
          } else {
            Utility.eToast("Unable to Delete Coupon !");
          }
        })
        .finally(() => {
          setLoadingRemoveCoupon("");
        });
    }
  };

  const changeStatusOfCoupon = async (item) => {
    if (
      window.confirm(
        `Are you Sure want to ${
          item?.isActive ? "Deactivate" : "Activate"
        } coupon ${item?.code} ?`
      )
    ) {
      setLoadingEditCoupon(item?._id);
      const data = {
        isActive: item?.isActive ? false : true,
      };
      await apicaller(
        `coupons/${item?._id}`,
        data,
        "PUT",
        token,
        "application/json"
      )
        .then((res) => {
          getCouponData();
          Utility.sToast("Coupon Status Updated Successfully");
        })
        .catch((err) => {
          if (err?.response?.data?.error === "Token Session expired") {
            Utility.eToast(
              "Your token has expired, Please Login again to continue!"
            );
            dispatch(clear());
            navigate("/login");
          } else {
            Utility.eToast("Unable to Change Coupon Status !");
          }
        })
        .finally(() => {
          setLoadingEditCoupon("");
        });
    }
  };

  return (
    <Container fluid>
      {loadingCoupons ? (
        <div
          style={{
            minHeight: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner animation="border" style={{ color: "#1814F3" }} />
        </div>
      ) : (
        <>
          <SettingsGlobalHeader
            headingText="Coupon"
            showButton={true}
            onClick={() => {
              setAddCouponModal(true);
            }}
          />
          {/* GLOBAL TABLE  COMPONENT */}
          {couponData?.length > 0 && (
            <TableComponent1 columns={columns} data={data} customStyle={true} />
          )}
          {/* ADD COUPON MODAL */}
          <AddCouponModal
            show={addCouponModal}
            onHide={() => {
              getCouponData();
              setAddCouponModal(false);
            }}
          />
          {/* UPDATE COUPON MODAL */}
          {/* <EditCoupnModal show={editCoupon} onHide={() => setEditCoupon(false)} /> */}
        </>
      )}
    </Container>
  );
}
