import React from "react";
import { NavLink } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Accordion,
  Button,
  Image,
} from "react-bootstrap";
import { MdSpaceDashboard, MdPeopleAlt } from "react-icons/md";
import { FaBookBookmark } from "react-icons/fa6";
import { FaCartFlatbed } from "react-icons/fa6";
import { FaUserGear } from "react-icons/fa6";
import { FaUserGraduate,FaHandsHelping } from "react-icons/fa";
import "./Sidebar.css";
import { HiOutlineLogout } from "react-icons/hi";
import Logoutmodal from "../../components/Logoutmodal/Logoutmodal";
import logo from "../../assets/Logowhite.png";
import { AiFillSetting } from "react-icons/ai";
import GlobalButton from "../../components/GlobalButton/GlobalButton";
import { useDispatch } from "react-redux";
import { setChosenSettingsTab } from "../../Redux/Slice/UserSlice";
import { IoMdPin } from "react-icons/io";

const Sidebar = () => {
  const [showmodal, setShowmodal] = React.useState(false);
  const dispatch = useDispatch();

  return (
    <>
      <Container fluid>
        <Row>
          <Col id="sidebar1">
            <Navbar expand="lg">
              <Navbar.Toggle />
              <Navbar.Collapse id="sidebar-nav">
                <div className="sidebar-container">
                  <Nav>
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0" id="sidebar_4">
                        <Accordion.Body id="sidebar_3">
                          <div>
                            <Image
                              src={logo}
                              alt="logo"
                              style={{ width: "7.5rem" }}
                            />
                          </div>
                          <div style={{ minHeight: "30px" }}> </div>
                          <Nav.Item>
                            <NavLink to="/" className="nav-link" id="sidebar_2">
                              <span>
                                {" "}
                                <MdSpaceDashboard
                                  style={{ marginRight: "10px" }}
                                />{" "}
                              </span>{" "}
                              <span>Dashboard</span>
                            </NavLink>
                          </Nav.Item>

                          <Nav.Item>
                            <NavLink
                              to="/students"
                              className="nav-link"
                              id="sidebar_2"
                            >
                              <span>
                                <MdPeopleAlt style={{ marginRight: "10px" }} />
                              </span>
                              Students
                            </NavLink>
                          </Nav.Item>

                          <Nav.Item>
                            <NavLink
                              to="/faculty"
                              className="nav-link"
                              id="sidebar_2"
                            >
                              <span>
                                <FaUserGraduate
                                  style={{ marginRight: "10px" }}
                                />
                              </span>
                              Faculty
                            </NavLink>
                          </Nav.Item>

                          <Nav.Item>
                            <NavLink
                              to="/center"
                              className="nav-link"
                              id="sidebar_2"
                            >
                              <span>
                                <IoMdPin style={{ marginRight: "10px" }} />
                              </span>
                              Center
                            </NavLink>
                          </Nav.Item>

                          <Nav.Item>
                            <NavLink
                              to="/academic-admins"
                              className="nav-link"
                              id="sidebar_2"
                            >
                              <span>
                                <FaUserGear style={{ marginRight: "10px" }} />
                              </span>
                              Academic
                            </NavLink>
                          </Nav.Item>

                          <Nav.Item>
                            <NavLink
                              to="/finance"
                              className="nav-link"
                              id="sidebar_2"
                            >
                              <span>
                                <FaCartFlatbed
                                  style={{ marginRight: "10px" }}
                                />
                              </span>
                              Finance
                            </NavLink>
                          </Nav.Item>

                          {/* <Nav.Item>
                            <NavLink
                              to="/totalcourses"
                              className="nav-link"
                              id="sidebar_2"
                            >
                              <span>
                                {" "}
                                <FaBookBookmark
                                  style={{ marginRight: "10px" }}
                                />{" "}
                              </span>{" "}
                              Courses
                            </NavLink>
                          </Nav.Item> */}

                          <Nav.Item>
                            <NavLink
                              to="/courses"
                              className="nav-link"
                              id="sidebar_2"
                            >
                              <span>
                                {" "}
                                <FaBookBookmark
                                  style={{ marginRight: "10px" }}
                                />{" "}
                              </span>{" "}
                              Courses
                            </NavLink>
                          </Nav.Item>

                          <Nav.Item>
                            <NavLink
                              to="/settings"
                              className="nav-link"
                              id="sidebar_2"
                              onClick={() =>
                                dispatch(setChosenSettingsTab("aboutUs"))
                              }
                            >
                              <span>
                                {" "}
                                <AiFillSetting
                                  style={{ marginRight: "10px" }}
                                />
                              </span>{" "}
                              Settings
                            </NavLink>
                          </Nav.Item>
                          {/* <Nav.Item>
                            <NavLink
                              to="/help"
                              className="nav-link"
                              id="sidebar_2"
                            >
                              <span>
                                {" "}
                                <FaHandsHelping
                                  style={{ marginRight: "10px" }}
                                />
                              </span>{" "}
                              Help
                            </NavLink>
                          </Nav.Item> */}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Nav>
                </div>
              </Navbar.Collapse>
            </Navbar>
            <div id="sidebar7" style={{ zIndex: 111111 }}>
              <GlobalButton
                title="Logout"
                RightIcon={HiOutlineLogout}
                iconSize={22}
                onclick={() => setShowmodal(true)}
              />
            </div>
          </Col>
        </Row>
        <Logoutmodal show={showmodal} onHide={() => setShowmodal(false)} />
      </Container>
    </>
  );
};

export default Sidebar;
