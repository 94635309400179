import React, { useEffect, useState } from "react";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { apicaller } from "../../utils/api";
import { useSelector } from "react-redux";


const size = {
  width: 350,
  height: 250,
};

export default function DashPieChart() {
  const [year, setYear] = useState(2024);
  const [courseWiseData, setCourseWiseData] = useState([]);

  console.log(year);

  const handleChange = (event) => {
    setYear(event.target.value);
  };

  const token = useSelector((state) => state?.user?.token);
  // console.log("PPPPPPPPPPPPP", token);

  useEffect(() => {
    adminCourseWiseData();
  }, [year]);

  const adminCourseWiseData = async () => {
    await apicaller(
      `get-admin-students-by-Course?year=${year}`,
      null,
      "GET",
      token,
      null
    )
      .then((res) => {
        console.log("rtrtrtrtr", res);
        setCourseWiseData(res?.data);
      })
      .catch((err) => console.log(err));
  };

  const data = courseWiseData;

  return (
    <div>
      <h6>Course Statistics</h6>
      <PieChart
        series={[
          {
            arcLabel: (item) => `${item.label} (${item.value})`,
            arcLabelMinAngle: 45,
            data,
            highlightScope: { faded: "global", highlighted: "item" },
            faded: { innerRadius: 30, additionalRadius: -30, color: "gray" },
            cx: 150,
          },
        ]}
        height={200}
        slotProps={{ legend: { hidden: true } }}
        sx={{
          [`& .${pieArcLabelClasses.root}`]: {
            fill: "white",
            fontWeight: "bold",
            fontSize: "0.7rem",
          },
        }}
        {...size}
      />
      <span style={{ position: "absolute", top: 0, right: 0 }}>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="demo-select-small-label">Year</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={year}
            label="year"
            onChange={handleChange}
          >
            <MenuItem value={2024}>2024</MenuItem>
            <MenuItem value={2025}>2025</MenuItem>
            <MenuItem value={2026}>2026</MenuItem>
            <MenuItem value={2027}>2027</MenuItem>
            <MenuItem value={2028}>2028</MenuItem>
            <MenuItem value={2029}>2029</MenuItem>
            <MenuItem value={2030}>2030</MenuItem>
          </Select>
        </FormControl>
      </span>
    </div>
  );
}
