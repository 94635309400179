import React, { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import "./Aboutus.css";
import AddAboutModal from "./components/AddAboutModal/AddAboutModal";
import { apicaller } from "../../utils/api";
import Utility from "../../utils/Utility";
import { useDispatch, useSelector } from "react-redux";
import { clear, getUserToken } from "../../Redux/Slice/UserSlice";
import { useNavigate } from "react-router-dom";
import SettingsGlobalHeader from "../Settings/components/SettingsGlobalHeader/SettingsGlobalHeader";
import GlobalButton from "../../components/GlobalButton/GlobalButton";

export default function Aboutus() {
  const [modalShow, setModalShow] = useState(false);
  const [aboutUsData, setAboutUsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deletingData, setDeletingData] = useState(null);
  const navigate = useNavigate();
  const token = useSelector(getUserToken);

  useEffect(() => {
    getAboutUsData();
  }, []);

  const dispatch = useDispatch();

  const getAboutUsData = () => {
    apicaller("get-about-us", null, "get", null)
      .then((res) => {
        setAboutUsData(res?.data?.result);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        if (e?.response?.data?.error === "Token Session expired") {
          Utility.eToast(
            "Your token has expired, Please Login again to continue!"
          );
          dispatch(clear());
          navigate("/login");
        } 
      });
  };

  const deleteAboutus = async (id) => {
    if (window.confirm("Are you sure, you want to remove ?")) {
      setDeletingData(id);
      await apicaller(`delete-about-us/${id}`, null, "delete", token, null)
        .then((res) => {
          getAboutUsData();
          Utility.sToast("Data deleted successfully");
        })
        .catch((err) => {
          if (err?.response?.data?.error === "Token Session expired") {
            Utility.eToast(
              "Your token has expired, Please Login again to continue!"
            );
            dispatch(clear());
            navigate("/login");
          } else {
            Utility.eToast("Unable to delete! Please try again.");
          }
          setDeletingData(null);
        })
        .finally(() => setDeletingData(null));
    }
  };

  return (
    <Container>
      {loading ? (
        <div id="abt9">
          <Spinner animation="border" style={{ color: "#1814F3" }} />
        </div>
      ) : (
        <>
          <SettingsGlobalHeader
            headingText="Add About us"
            showButton={true}
            onClick={() => setModalShow(true)}
          />
          <AddAboutModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            getAddedData={() => getAboutUsData()}
          />

          {aboutUsData?.map((item, i) => (
            <Row key={i} id="abt11">
              <Col md={12}>
                <Row>
                  <Col style={{ marginTop: "1.4rem", marginBottom: "1.6rem" }}>
                    {" "}
                    <span id="abt12"> {i + 1}</span>{" "}
                  </Col>
                </Row>
                <div
                  dangerouslySetInnerHTML={{
                    __html: item?.description,
                  }}
                />
              </Col>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                <GlobalButton
                  title="Edit"
                  width={130}
                  height={46}
                  onclick={() =>
                    navigate("/settings/aboutus-edit", {
                      state: { id: item },
                    })
                  }
                />
                <GlobalButton
                  title={deletingData === item?._id ? "Removing" : "Remove"}
                  width={130}
                  height={46}
                  backgroundColor="transparent"
                  borderStyle="dashed"
                  borderColor="#EF0940"
                  textColor="#EF0940"
                  fontWeight={400}
                  loading={deletingData === item?._id}
                  loadingColor="#EF0940"
                  onclick={() => deleteAboutus(item?._id)}
                />
              </div>
            </Row>
          ))}
        </>
      )}
    </Container>
  );
}
