import React, { useEffect, useState } from "react";
import "./ManageSubadmin.css";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { apicaller } from "../../utils/api";
import { clear, getUserToken, getUser_Id } from "../../Redux/Slice/UserSlice";
import Utility from "../../utils/Utility";
import RoundLargeButton from "../../components/RoundLargeButton/RoundLargeButton";
import { AiOutlineClockCircle } from "react-icons/ai";
import DataTable from "react-data-table-component";
import AddCourseAdminModal from "../AddCourseAdminModal/AddCourseAdminModal";
import moment from "moment";
import Dashcards from "../Dashcards/Dashcards";
import { FaUserLock } from "react-icons/fa";

export default function ManageSubadmin() {
  const [spin, setSpin] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Token = useSelector(getUserToken);
  const [show, setShow] = useState(false);
  const [allcourseadmin, setAllcourseadmin] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filterText, setFilterText] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    getAllcourseadmin();
  }, []);

  const getAllcourseadmin = async () => {
    await apicaller(`course-admin-get`, null, "get", Token)
      .then((res) => {
        setAllcourseadmin(res?.data?.data);
        setFilteredUsers(res?.data?.data);
        console.log("Courseadmin are......", res?.data?.data);
      })
      .catch((err) => {
        if (err?.response?.data?.error === "Token Session expired") {
          Utility.eToast(
            "Your token has expired, Please Login again to continue!"
          );
          dispatch(clear());
          navigate("/login");
        } else {
          Utility.eToast("Unable to upload! Please try again.");
        }
        setSpin(false);
      })
      .finally(() => setSpin(false));
  };

  const handleViewDetails = (row) => {
    navigate(
      `/academic-admins/academic-admins-details?name=${row?.first_name}-${row?.last_name}&id=${row?._id}`,
      {
        state: { selectedRow: row },
      }
    );
  };

  const handleSearchChange = (event) => {
    const searchText = event.target.value.toLowerCase();
    setFilterText(searchText);

    const filtered = allcourseadmin?.filter((user) => {
      const fullName = `${user?.first_name} ${user?.last_name}`.toLowerCase();
      return (
        user?.first_name?.toLowerCase()?.includes(searchText) ||
        user?.last_name?.toLowerCase()?.includes(searchText) ||
        user?.email?.toLowerCase()?.includes(searchText) ||
        fullName?.toLowerCase()?.includes(searchText)
      );
    });
    setFilteredUsers(filtered);
  };

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold"
      },
    },
  };

  const columns = [
    {
      name: "NO",
      cell: (row, index) => index + 1,
      sortable: true,
      width: "100px",
    },
    {
      name: "Join Date",
      cell: (row) => moment(row?.createdAt).format("DD-MM-YYYY"),
      sortable: true,
    },
    {
      name: "UID",
      cell: (row) => row?.uid,
      sortable: true,
    },
    {
      name: "Name",
      cell: (row) => row?.first_name + " " + row?.last_name,
      sortable: true,
    },
    {
      name: "Email",
      cell: (row) => row?.email,
      sortable: true,
      width: "330px",
    },
    {
      name: "Contact",
      cell: (row) => row?.phone_number,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <div>
          <Button
            size="sm"
            id="managesubadmin1"
            onClick={() => handleViewDetails(row)}
          >
            View Details
          </Button>
        </div>
      ),
      sortable: true,
    },
  ];
  return (
    <>
      <Container fluid>
        {spin ? (
          <div id="abt9">
            <Spinner animation="border" variant="warning" />
          </div>
        ) : (
          <>
            <Container fluid>
              <Row>
                <Col md={3}>
                  <div className="roundLargeButtonContainer ">
                    <RoundLargeButton
                      icon={"+"}
                      name={"Create"}
                      event={handleShow}
                    />
                    <AddCourseAdminModal
                      show={show}
                      onHide={handleClose}
                      getallcourseadmin={getAllcourseadmin}
                    />
                  </div>
                </Col>
                <Col md={3} lg={3} sm={12} xs={12}>
                  <div>
                    <Dashcards
                      icon={<FaUserLock id="dash15" />}
                      title="Total Course Admin"
                      amount={allcourseadmin?.length || 0}
                      background="#EDE7FF"
                      borderRadius="20rem"
                    />
                  </div>
                </Col>
                {/* <Col md={9}> */}
                {/* <div className="roundLargeButtonContainer ">
                    <div className="detailContainerWrapper">
                      <span className="detailsHeader">Course admins</span>
                      {data?.map((item, index) => (
                        <div className="detailsContainer mb-3" key={index}>
                          <span className="firstCol">{item?.firstCol}</span>
                          <span className="secCol">
                            <AiOutlineClockCircle /> {item?.secCol}
                          </span>
                          <span className="thirdCol" style={item?.thirdColStyle}>
                            {item?.thirdColText}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div> */}
                {/* </Col> */}
              </Row>

              <Row id="au1">
            <Col>
              {/* <FaUserFriends /> All Students */}
            </Col>
            <Col className="searchInputContainer">
              {" "}
              <input
                type="text"
                placeholder="Search by name..."
                className="searchInput"
                value={filterText}
                onChange={handleSearchChange}
              />
            </Col>
          </Row>
              <Row className=" mt-5">
                
                <DataTable   columns={columns}
            data={filteredUsers}
            customStyles={customStyles}
            pagination />
              </Row>
            </Container>
          </>
        )}
      </Container>
    </>
  );
}
