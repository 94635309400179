import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getUserToken } from "../../Redux/Slice/UserSlice";
import { IoSearchOutline } from "react-icons/io5";
import CourseCard from "../CourseCard/CourseCard";
import "./AllCourses.css";
import EditNewCourseModal from "../EditCourseModal/EditCourseModal";

export default function AllCourses({
  getAllCourseList,
  courseList,
  setCourseList,
  filterCourseList,
  setFilterCourseList,
}) {
  const token = useSelector(getUserToken);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [showEditCourseModal, setShowEditCourseModal] = useState(false);

  const [selectedCourse, setSelectedCourse] = useState(null);
  useEffect(() => {
    getAllCourseList();
  }, []);

  const handleFilterCourseArray = async (e) => {
    const inputValue = e?.target?.value.trim().toLowerCase(); // Convert input to lowercase and trim whitespace
    setSearchInputValue(inputValue);

    if (!inputValue) {
      setCourseList(filterCourseList);
    } else {
      const searchResult = filterCourseList.filter((course) => {
        const isMatchingName = course?.name?.toLowerCase().includes(inputValue);
        const isMatchingPrice =
          typeof course?.price === "number" &&
          course?.price?.toString().includes(inputValue);
        return isMatchingName || isMatchingPrice;
      });

      setCourseList(searchResult);
    }
  };

  // function handleClose() {
  //   getAllCourseList();
  // }
  const handleEditCourse = (course) => {
    console.log(course, "course");
    setSelectedCourse(course);

    setShowEditCourseModal(true);
  };

  const handleClose = () => {
    setSelectedCourse(null);
    setShowEditCourseModal(false);

    getAllCourseList();
  };
  console.log(courseList, "courseList");
  return (
    <Container>
      <Row className="course5">
        <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} className="course4">
          All Courses
        </Col>
        <Col></Col>
        <Col xs={3} md={3} lg={3} xl={3} xxl={3}>
          <Form.Group className="course6 mt-3">
            <Form.Control
              type="text"
              placeholder="Search for courses"
              className="course7"
              value={searchInputValue}
              onChange={handleFilterCourseArray}
            />
            <IoSearchOutline size={25} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        {courseList?.map((item) => (
          <Col xs={12} sm={12} md={6} lg={4} xl={4} xxl={4}>
            <CourseCard
              courseDetails={item}
              onHide={handleClose}
              onEdit={() => handleEditCourse(item)} // Pass the course data to the handler
            />
          </Col>
        ))}
        {courseList?.length === 0 && (
          <div className="course8">No Course Found</div>
        )}
        {selectedCourse && (
          <EditNewCourseModal
            show={showEditCourseModal}
            onHide={handleClose}
            response={selectedCourse} // Pass the selected course to the modal
          />
        )}
      </Row>
    </Container>
  );
}
