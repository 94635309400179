import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import moment from "moment";

function StudentExam({ examDetails }) {
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
      },
    },
  };

  const columns = [
    {
      name: "NO",
      cell: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Course",
      cell: (row) => row?.course_id?.name,
      sortable: true,
    },
    {
      name: "Exam Topic",
      cell: (row) => row?.topic,
      sortable: true,
    },
    {
      name: "Selected Date & time",
      cell: (row) => {
        if (!row.slotByStudent[0].date) {
          return "Slot not chosen yet.";
        } else {
          const date = new Date(row.slotByStudent[0]?.date);
          const startTime = row.slotByStudent[0]?.startTime;
          const endTime = row.slotByStudent[0]?.endTime;
          const formattedDate = moment(row.slotByStudent[0]?.date).format(
            "Do MMM YYYY"
          );
          const startAMPM = new Date(
            `1970-01-01T${startTime}`
          ).toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          });
          const endAMPM = new Date(`1970-01-01T${endTime}`).toLocaleTimeString(
            "en-US",
            { hour: "numeric", minute: "numeric", hour12: true }
          );
          return `${formattedDate}, [${startAMPM} - ${endAMPM}]`;
        }
      },

      sortable: true,
    },
    // {
    //   name: "Total Marks",
    //   cell: (row) => row.totalmarks,
    //   sortable: true,
    // },
    // {
    //   name: "Score",
    //   cell: (row) => row.score,
    //   sortable: true,
    // },

    // {
    //   name: "Feedback",
    //   cell: (row) => row.feedback,
    //   sortable: true,
    // },
  ];

  return (
    <DataTable
      columns={columns}
      data={examDetails}
      pagination
      paginationPerPage={4}
      customStyles={customStyles}
    />
  );
}

export default StudentExam;
