import React from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";

export default function BackButton({ backColor }) {
  const navigate = useNavigate();
  return (
    <div className="mt-3 d-flex flex-direction-row align-items-center">
      <span
        style={{
          fontSize: "1.5rem",
          cursor: "pointer",
          fontWeight: "500",
          color: backColor,
        }}
        onClick={() => navigate(-1)}
      >
        <IoMdArrowRoundBack /> <span className="mx-1">Back</span>
      </span>
    </div>
  );
}
