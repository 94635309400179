import axios from "axios";

// export const API = "http://192.168.29.167:8002/api/";
// export const API = "http://192.168.29.17:8002/api/";
// export const API = "https://api-cybernetics-ai.onrender.com/api/";
// export const API = "http://192.168.29.167:8002/api/";
// export const API = "https://api-krist-ai.onrender.com/api/";
export const API = "https://api.kristellarcyberspace.in/api/";

export const apicaller = (
  uri,
  data = {},
  method,
  Token,
  contentType = "application/json"
) => {
  console.log(`Api Caller Data Of ${uri} : `, data);
  return new Promise((resolve, reject) => {
    var config = {
      method: method,
      url: API + `${uri}`,
      headers: {
        Authorization: "Bearer " + Token,
        "Content-Type": contentType,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(`Api Caller Response Of ${uri} : `, response?.data);
        resolve(response);
      })
      .catch(function (error) {
        console.log(`Api Caller Error Of ${uri} : `, error);
        reject(error);
      });
  });
};
