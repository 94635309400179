import React, { useEffect, useState } from "react";
import "./ContactUs.css";
import Table from "react-bootstrap/Table";
import Header2 from "../../components/Header2/Header2";
import Container from "react-bootstrap/Container";
import { apicaller } from "../../utils/api";
import { useDispatch, useSelector } from "react-redux";
import { clear, getUserToken } from "../../Redux/Slice/UserSlice";
import Loader from "../../components/Loader/Loader";
import { Col, Row } from "react-bootstrap";
import Utility from "../../utils/Utility";
import { useNavigate } from "react-router-dom";

export default function ContactUs() {
  const [data, setData] = useState([]);
  useEffect(() => {
    getContactUsData();
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = useSelector(getUserToken);

  const getContactUsData = async () => {
    await apicaller("contactus-form", null, "GET", token)
      .then((res) => {
        setData(res?.data?.data);
      })
      .catch((err) => {
        if (err?.response?.data?.error === "Token Session expired") {
          Utility.eToast(
            "Your token has expired, Please Login again to continue!"
          );
          dispatch(clear());
          navigate("/login");
        }
      });
  };

  // console.log("==========>", data);
  return (
    <>
      {/* <Header2 /> */}
      <>
        <Container fluid id="contact2">
          <Row>
            <Col id="contact6">
              <div id="contact5">Feedbacks</div>
            </Col>
          </Row>
          <Row>
            <Col id="contact4">
              <Table striped bordered hover>
                <thead id="contact3">
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone Number</th>

                    <th id="contact1">Feedback</th>
                    <th id="contact1">Company Website</th>
                    <th>City</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((item, i) => (
                    <tr key={i}>
                      <td>{item?.first_name + " " + item?.last_name}</td>
                      <td>{item.email}</td>
                      <td>{item?.phone_no}</td>

                      <td>{item.feedback}</td>
                      <td>{item.companyWebsite}</td>
                      <td>{item.selectCity}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </>
    </>
  );
}
