import React, { useState, useEffect } from "react";
import "./Center.css";
import DataTable from "react-data-table-component";
import { apicaller } from "../../utils/api";
import { useSelector } from "react-redux";
import { getUserToken } from "../../Redux/Slice/UserSlice";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Center = () => {
  const [allCenter, setAllCenter] = useState([]);
  const Token = useSelector(getUserToken);
  const navigate = useNavigate();

  const columns = [
    {
      name: "States",
      selector: (row) => row?.district_id?.states,
      sortable: true,
    },
    {
      name: "District",
      selector: (row) => row?.district_id?.district_name,
      sortable: true,
    },
    {
      name: "Center Name",
      selector: (row) => row?.center_name,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <Button
          id="center3"
          onClick={() => navigate(`/centerdetails/${row?._id}`, { state: { centerName: row?.center_name } })}
        >
          View
        </Button>
      ),
    },
  ];

  useEffect(() => {
    getAllCenter();
  }, []);

  const getAllCenter = async () => {
    try {
      const response = await apicaller("get-center", null, "get", Token);
      setAllCenter(response?.data?.center || []);
    } catch (error) {
      console.error("Error fetching centers:", error);
    }
  };

  const handleViewClick = (row) => {
    navigate(`/centerdetails/${row._id}`);
  };

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
      },
    },
  };

  return (
    <div id="center1">
      <div id="center2">All Centers</div>
      <hr></hr>
      <DataTable
        columns={columns}
        data={allCenter}
        customStyles={customStyles}
        pagination
      />
    </div>
  );
};

export default Center;
