import React, { useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import "./TopratedCourseCard.css";
import StarComponent from "../StarComponent/StarComponent";
import { Link, useNavigate } from "react-router-dom";
import Course from "../../assets/cardImg.png";
import { BiHide, BiShow } from "react-icons/bi";
import { FaEdit } from "react-icons/fa";
import { MdModeEditOutline } from "react-icons/md";
import EditCourseModal from "../EditCourseModal/EditCourseModal";
import live from "../../assets/live.gif";
import { Rating } from "react-simple-star-rating";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { BsPencilSquare } from "react-icons/bs";
export default function TopratedCourseCard(props) {
  const [modalShow, setModalShow] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <Row>
        <Col id="tcc1">
          <Row>
            <Col id="trcc19">
              {/* <div id="trcc14">
                {props.item.class_mode === "Offline" && (
                  <div id="trcc12">Offline</div>
                )}
                {props.item.class_mode === "Online" && (
                  <div id="trcc13">Online</div>
                )}
              </div> */}
              <div id="trcc14">
                {props.class_mode === "Offline" && (
                  <div id="trcc12">Offline</div>
                )}
                {props.class_mode === "Online" && <div id="trcc13">Online</div>}
              </div>
            </Col>
          </Row>
          <EditCourseModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            item={props.item}
          />

          <div id="tcc3">
            <Image
              src={props.item.course.img}
              alt="Course image"
              id="trcc8"
              onClick={() => {
                navigate(
                  `/courses/coursedetails?category=${props.item.course.category
                    ?.replace(/\s+/g, "-")
                    ?.toLowerCase()}&coursename=${encodeURIComponent(
                    props?.item?.course?.name?.replace(/\s+/g, "-")
                  )?.toLowerCase()}&id=${props?.item?.course?._id}`,
                  {
                    state: { item: props.item, author: props.author },
                  }
                );
              }}
            />
          </div>
          <Row style={{ marginTop: "1rem" }}>
            {" "}
            <Col md={9} lg={9} xl={9} xs={9} sm={9}>
              <div id="tcc17">{props.item.course.name}</div>
            </Col>
            <Col
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <div>
                {props.item.course.visiblility === "active" ? (
                  <div id="tcc15">Live</div>
                ) : (
                  <div id="tcc16">Hidden</div>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              xs={5}
              sm={5}
              md={5}
              lg={5}
              xl={5}
              xxl={5}
              style={{ display: "flex" }}
            >
              <span id="trcc10"> $ {props.item.course.price}</span>
            </Col>
            <Col
              xs={7}
              sm={7}
              md={7}
              lg={7}
              xl={7}
              xxl={7}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Rating
                stop={5}
                initialValue={props.item.course.ratings}
                allowFraction
                size={20}
                readonly
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
