import React, { useEffect, useState } from "react";
import "./AddCourseModal.css";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { IoClose } from "react-icons/io5";
import {
  Checkbox,
  Divider,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { apicaller } from "../../utils/api";
import Utility from "../../utils/Utility";
import { clear } from "../../Redux/Slice/UserSlice";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  padding: "1rem",
  boxShadow: 24,
  p: 4,
  height: "90vh",
  overflowY: "auto",
};

export default function AddCourseModal(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state?.user);

  const [categ, setCateg] = useState([]);
  const [tchr, setTchr] = useState([]);

  const [imgFile, setImgFile] = useState([]);

  const [courseName, setCourseName] = useState([]);
  const [courseType, setCourseType] = useState("");
  const [coursePrice, setCoursePrice] = useState([]);
  const [classLink, setClassLink] = useState([]);

  const [personName, setPersonName] = React.useState([]);
  const [personId, setPersonId] = React.useState([]);
  const [courseDesc, setCourseDesc] = useState([]);
  const [allcourseadmin, setAllcourseadmin] = useState([]);
  const [selectedCourseadmin, setSelectedCourseadmin] = useState("");
  const [allCenters, setAllCenters] = useState([]);
  const [selectedCenter, setSelectedCenter] = useState("");

  const [spinLoad2, setSpinLoad2] = useState(false);

  useEffect(() => {
    getCateg();
    getTchr();
    fetchAllCenters();
    getAllcourseadmin();
  }, []);

  const getCateg = () => {
    apicaller("get-category", null, "GET", token, null)
      .then((res) => {
        // console.log(">>>>>>>", res);
        setCateg(res?.data?.category);
      })
      .catch((err) => {
        if (err?.response?.data?.error === "Token Session expired") {
          Utility.eToast("Token expired, Please login again ! ");
          dispatch(clear());
          navigate("/");
        } else {
          Utility.eToast(err?.response?.data?.error || err?.message);
        }
      });
  };

  // code for multi select
  const getTchr = () => {
    apicaller("admin/teacher-list", null, "GET", token, null)
      .then((res) => {
        console.log("res in all teacher list", res);
        setTchr(res?.data?.teacher);
      })
      .catch((err) => {
        if (err?.response?.data?.error === "Token Session expired") {
          Utility.eToast("Token expired, Please login again ! ");
          dispatch(clear());
          navigate("/");
        } else {
          Utility.eToast(err?.response?.data?.error || err?.message);
        }
      });
  };

  const fetchAllCenters = async () => {
    await apicaller("get-center", null, "GET", token)
      .then((res) => {
        setAllCenters(res?.data?.center);
      })
      .catch((err) => {
        if (err?.response?.data?.error === "Token Session expired") {
          Utility.eToast("Token expired, Please login again ! ");
          dispatch(clear());
          navigate("/");
        } else {
          Utility.eToast(err?.response?.data?.error || err?.message);
        }
      });
  };

  const getAllcourseadmin = async () => {
    await apicaller(`course-admin-get`, null, "get", token)
      .then((res) => {
        setAllcourseadmin(res?.data?.data);
        console.log("Courseadmin are......", res?.data?.data);
      })
      .catch((err) => {
        if (err?.response?.data?.error === "Token Session expired") {
          Utility.eToast(
            "Your token has expired, Please Login again to continue!"
          );
          dispatch(clear());
          navigate("/login");
        } else {
          Utility.eToast("Unable to upload! Please try again.");
        }
      });
  };

  const ITEM_HEIGHT = 45;
  const ITEM_PADDING_TOP = 0;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  // const names = tchr?.map((item) => item?.first_name);
  const names = tchr?.map((item) => ({
    id: item?._id,
    name: item?.first_name,
  }));

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    const selectedIds = value.map((selectedName) => {
      const selectedTeacher = tchr.find(
        (teacher) => teacher.first_name === selectedName
      );
      return selectedTeacher ? selectedTeacher._id : null;
    });

    const filteredIds = selectedIds.filter((id) => id !== null);

    setPersonName(value);
    setPersonId(filteredIds);
  };

  // upload function start

  const validation = () => {
    if (imgFile === "" || imgFile === undefined) {
      Utility.eToast("Course image required!");
      return false;
    } else if (courseName === "" || courseName === undefined) {
      Utility.eToast("Course name required!");
      return false;
    } else if (courseType === "" || courseType === undefined) {
      Utility.eToast("Course type is required!");
      return false;
    } else if (coursePrice === "" || coursePrice === undefined) {
      Utility.eToast("Price required!");
      return false;
    } else if (classLink?.trim() === "") {
      Utility.eToast("Add Class Link");
      return false;
    } else if (personId.length === 0) {
      Utility.eToast("Select atleast one teacher!");
      return false;
    } else {
      return true;
    }
  };

  const postCourse = async () => {
    if (validation()) {
      setSpinLoad2(true);
      const data = {
        img: imgFile,
        name: courseName?.trimStart(),
        category: courseType,
        price: coursePrice?.trim(),
        class_link: classLink?.trim(),
        teachers: personId,
        description: courseDesc,
        createdBy: selectedCourseadmin,
        centerId: selectedCenter,
      };

      console.log("Data is......", data);

      await apicaller("course", data, "POST", token, "multipart/form-data")
        .then((res) => {
          Utility.sToast("New Course Added successfully");
          setImgFile("");
          setCourseName("");
          setCourseType("");
          setCoursePrice("");
          setClassLink("");
          setPersonId([]);
          // setCourseStartDate("");
          // setCourseEndDate("");
          setCourseDesc("");
          props.fetchCourseList();
          props.close();
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setSpinLoad2(false);
        });
    }
  };

  // upload function end

  return (
    <>
      <Modal
        open={props.open}
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Row>
            <Col xs={6} sm={6} md={6} lg={6} className="acm3">
              Add Course
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} className="acm1">
              <IoClose className="acm2" onClick={props.close} />
            </Col>
          </Row>
          <Divider />
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Form>
              <Form.Group controlId="formFileSm" className="mb-3">
                <Form.Label id="acm6">Upload course image</Form.Label>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <Form.Control
                      type="file"
                      accept="image/*"
                      size="sm"
                      onChange={(e) => setImgFile(e.target.files[0])}
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label id="acm6">Enter course name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter course name"
                  value={courseName}
                  onChange={(e) => {
                    const input = e.target.value;
                    // Check if the input matches the alphabetic pattern
                    if (/^[A-Za-z\s]+$/.test(input) || input === "") {
                      setCourseName(input);
                    }
                  }}
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput2"
              >
                <Form.Label id="acm6">Enter course type</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => setCourseType(e.target.value)}
                >
                  <option>Course type</option>
                  {categ?.map((item, i) => (
                    <>
                      <option value={item?._id} key={i}>
                        {item?.category_name}
                      </option>
                    </>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput3"
              >
                <Form.Label id="acm6">Enter course price</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter price"
                  value={coursePrice}
                  onChange={(e) => {
                    setCoursePrice(e.target.value);
                  }}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label id="acm6">Add class link</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Your Class Link"
                  id="classLinkForm"
                  value={classLink}
                  onChange={(e) => {
                    setClassLink(e.target.value);
                  }}
                />
              </Form.Group>

              <Row className="mb-3">
                <Col md={12} lg={12} sm={12} xs={12}>
                  <Form.Label id="acm6">Choose Center</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    id="addfaculty5"
                    value={selectedCenter}
                    onChange={(e) => setSelectedCenter(e.target.value)}
                  >
                    <option value="">Choose Center</option>
                    {allCenters?.map((center, index) => (
                      <option key={index} value={center?._id}>
                        {center?.center_name},{" "}
                        {center?.district_id?.district_name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col md={12} lg={12} sm={12} xs={12}>
                  <Form.Label id="acm6">Assign Academic to Course</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    id="addfaculty5"
                    value={selectedCourseadmin}
                    onChange={(e) => setSelectedCourseadmin(e.target.value)}
                  >
                    <option value="">Choose Academic</option>
                    {allcourseadmin?.map((item, index) => (
                      <option key={index} value={item?._id}>
                        {item?.first_name} {item?.last_name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput5"
              >
                <Form.Label id="acm6">Assign Teacher to Course</Form.Label>
                <Row className="acm5">
                  <Select
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    multiple
                    value={personName}
                    onChange={handleChange}
                    // input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                    size="small"
                  >
                    {names?.map((item) => (
                      <MenuItem key={item?._id} value={item?.name}>
                        <Checkbox
                          checked={personName.indexOf(item?.name) > -1}
                        />
                        <ListItemText primary={item?.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </Row>
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label id="acm6">Course description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  value={courseDesc}
                  onChange={(e) => setCourseDesc(e.target.value)}
                />
              </Form.Group>
            </Form>

            <Button className="acm4" onClick={postCourse}>
              {spinLoad2 ? (
                <Spinner animation="border" size="sm" variant="light" />
              ) : (
                "Create"
              )}
            </Button>
          </Typography>
        </Box>
      </Modal>
    </>
  );
}
