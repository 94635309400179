import React, { useState } from "react";
import { Col, Container, Form, Row, Image, Button } from "react-bootstrap";
import "./Login.css";
import { BsPersonFill } from "react-icons/bs";
import { IoKey } from "react-icons/io5";
import AtharwLogo from "../../assets/logo.png";
import { apicaller } from "../../utils/api";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import Loader from "../../components/Loader/Loader";
import logo from "../../assets/login/logo.svg";
import {
  setUserEmail,
  setUserType,
  setUserToken,
  setUser_Id,
} from "../../Redux/Slice/UserSlice";
import Utility from "../../utils/Utility";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [revealPassword, setRevealPassword] = useState(false);

  // to show loader
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const regEmail = /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w\w+)+$/;

  function validation() {
    if (email.length === 0) {
      Utility.eToast("Enter your Email Id");
      return false;
    } else if (!regEmail.test(email)) {
      Utility.eToast("Enter a valid Email Id");
      return false;
    } else if (password.length === 0) {
      Utility.eToast("Enter a valid Password");
      return false;
    } else {
      return true;
    }
  }

  const loginUserFun = async () => {
    if (validation()) {
      setIsLoading(true);

      const data = {
        email: email,
        password: password,
      };

      await apicaller("admin/login", data, "POST", null)
        .then((res) => {
          // setIsLoading(false);
          console.log("data was ", data);
          var decoded = jwt_decode(res?.data?.token);
          console.log("my token data is ", decoded.data);

          var user = decoded.data;
          dispatch(setUserEmail(user?.email));
          dispatch(setUserType(user?.user_type));
          dispatch(setUser_Id(user._id));
          dispatch(setUserToken(res?.data?.token));
          navigate("/");
        })
        .catch((err) => {
          console.log("erererererere", err);
          Utility.eToast(err?.message);
          Utility.eToast(err?.response?.data?.error);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="login1">
      <div className="login2">
        <div className="login4">
          <Image src={logo} alt="" className="login3" />
        </div>
        <div className="login5">Admin Login</div>
        <div>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="login6">Email</Form.Label>
            <Form.Control
              type="text"
              placeholder="name@example.com"
              className="login7"
              style={{ boxShadow: "none" }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="login6">Password</Form.Label>
            <div className="password-input" style={{ position: "relative" }}>
              <Form.Control
                type={showPassword ? "text" : "password"}
                placeholder="password"
                style={{ boxShadow: "none" }}
                className="login7"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span
                onClick={togglePasswordVisibility}
                style={{
                  position: "absolute",
                  top: 4,
                  right: 10,
                  fontSize: "1.3rem",
                  color: "#9A9AB0",
                  cursor: "pointer",
                }}
              >
                {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
              </span>
            </div>
          </Form.Group>
          <div>
            <Button id="login8" onClick={() => loginUserFun()}>
              {isLoading ? (
                <Loader animation="border" size="sm" variant="light" />
              ) : (
                "Login"
              )}
            </Button>
          </div>
        </div>
        <Row>
          <span
            onClick={() => {
              navigate("/reset-password");
            }}
            className="d-flex justify-content-end login6 forgotPassword"
          >
            Forgot password?{" "}
          </span>
        </Row>
        <div className="login9">
          {/* Do not have an Account ? <span className="login10">Signup</span> */}
        </div>
      </div>
    </div>
  );
};

export default Login;
