import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clear, getUserToken } from "../../../Redux/Slice/UserSlice";
import { useNavigate } from "react-router-dom";
import { apicaller } from "../../../utils/api";
import Utility from "../../../utils/Utility";
import moment from "moment/moment";

export default function useTeacher() {
  const [spin, setSpin] = useState(true);
  const [teacherList, setTeacherList] = useState([]);
  const [approvedFacultyList, setApprovedFacultyList] = useState([]);

  useEffect(() => {
    getAllTeacher();
  }, []);

  const Token = useSelector(getUserToken);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getAllTeacher = async () => {
    await apicaller("admin/teacher-list", null, "get", Token)
      .then((res) => {
        console.log("all teacher in teacher detils", res?.data?.teacher);
        setTeacherList(res?.data?.teacher);
        const approvedTeacherList = res?.data?.teacher?.filter(
          (teacher) => teacher?.admin_approval === "approved"
        );
        setApprovedFacultyList(approvedTeacherList);
      })
      .catch((err) => {
        Utility.eToast("Oops Something Went Wrong");
        if (err?.response?.data?.error === "Token Session expired") {
          Utility.eToast(
            "Your token has expired, Please Login again to continue!"
          );
          dispatch(clear());
          navigate("/login");
        }
      })
      .finally(() => {
        setSpin(false);
      });
  };

  // Filter Today Joined Faculty Count
  const todayJoinedTeacherCount = () => {
    const todayJoinedTeacherArray = approvedFacultyList?.filter((item) => {
      const teacherJoinedDate = moment(item?.createdAt).startOf("day");
      // Today Date
      const currentDate = moment().startOf("day");

      // Compare dates
      return teacherJoinedDate?.isSame(currentDate, "day");
    });

    console.log("today joined", todayJoinedTeacherArray?.length)
    return todayJoinedTeacherArray?.length || 0;
  };

  const approveTeacher = async (id) => {
    console.log("teacher id", id);
    setSpin(true);
    // setLoader(id);
    const data = {
      teacher_id: id,
      status: "approved",
    };
    await apicaller("admin/teacher-status", data, "put", Token)
      .then(() => {
        Utility.sToast("Teacher Approved Successfully");
        getAllTeacher();
      })
      .catch((e) => {
        Utility.eToast(e.response.data.err);
      })
      .finally(() => {
        setSpin(false);
      });
  };

  const rejectTeacher = async (id) => {
    console.log("teacher id", id);
    setSpin(true);
    const data = {
      teacher_id: id,
      status: "rejected",
    };
    await apicaller("admin/teacher-status", data, "put", Token)
      .then(() => {
        Utility.sToast("Teacher SignUp Request Rejected");
        getAllTeacher();
      })
      .catch((e) => {
        Utility.eToast(e.response.data.err);
      })
      .finally(() => {
        setSpin(false);
      });
  };

  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;

  const pageCount = Math.ceil(teacherList.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  return {
    spin,
    teacherList,
    approveTeacher,
    rejectTeacher,
    pageNumber,
    usersPerPage,
    pagesVisited,
    pageCount,
    changePage,
    navigate,
    todayJoinedTeacherCount
  };
}
