import React, { useState } from "react";
import {
  Col,
  Container,
  Form,
  Row,
  Image,
  Button,
  InputGroup,
} from "react-bootstrap";
import logo from "../../assets/login/logo.svg";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import Loader from "../../components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
import Utility from "../../utils/Utility";
import { useDispatch } from "react-redux";
import { apicaller } from "../../utils/api";
import "./ResetPassword.css";
import BackButton from "../../components/BackButton";
function ResetPassword() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [seconds, setSeconds] = useState(59);
  const [emailOtp, setEmailOtp] = useState("");
  const [emailVerification, setEmailVerification] = useState("");
  const [emailVerify, setEmailVerify] = useState(false);
  const [emailOtpId, setEmailOtpId] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emailLoading, setEmailLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const emailVerifyFunction = () => {
    if (email.length === 0) {
      Utility.eToast("Enter Your Email");
      return false;
    } else if (email.indexOf("@") === -1 || email.indexOf(".") === -1) {
      Utility.eToast("Enter A Valid Email");
      return false;
    } else {
      return true;
    }
  };
  const emailOtpSendFunction = async () => {
    if (emailVerifyFunction()) {
      setSeconds(0);

      const data = {
        email: email,
      };

      try {
        const response = await apicaller("forgot-password", data, "POST", null);
        if (
          response?.data?.message ===
          "Sent Successfully Please Check Your Mail...."
        ) {
          setEmailVerify(!emailVerify);
          Utility.sToast("Sent Successfully. Please check your email");
          console.log();
          setEmailOtpId(response?.data?._id);
        } else {
          Utility.eToast(
            "No user found with this email address. Please sign up."
          );
        }
      } catch (error) {
        Utility.eToast(`${error ? error : "Something Went Wrong"}`);
      }
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  function changePasswordValidation() {
    if (email.length === 0) {
    } else if (!emailVerify) {
      Utility.eToast("Please verify your email");
      return false;
    } else if (!emailOtp) {
      Utility.eToast("Please Enter OTP Password");
      return false;
    } else if (password.length === 0) {
      Utility.eToast("Please Enter New Password");
      return false;
    } else if (confirmPassword.length === 0) {
      Utility.eToast("Please Enter Confirm Password");
      return false;
    } else if (password !== confirmPassword) {
      Utility.eToast("New password & Confirm Password Doesnot Match");
      return false;
    } else if (password === confirmPassword) {
      return true;
    }
  }
  const handleResetPassword = async () => {
    if (changePasswordValidation()) {
      const data = {
        email: email,
        password: confirmPassword,
        token: emailOtp,
      };
      setEmailLoading(true);

      console.log("submit otp data going email...", data);
      try {
        const response = await apicaller("reset-password", data, "POST", null);
        if (response?.data?.message !== "Wrong Verification Code") {
          Utility.sToast(`Password Changed Successfully.`);
          setEmailOtp("");
          setEmail("");
          setEmailVerify(false);
          setPassword("");
          setConfirmPassword("");
        } else {
          Utility.eToast(`Wrong Verification Code Enter`);
        }
        setEmailLoading(false);
      } catch (error) {
        setEmailLoading(false);
      }

      setEmailLoading(false);
    }
  };

  return (
    <div className="reset1">
      <div className="ms-4 resetBack">
        <BackButton backColor="white" />
      </div>
      <div className="login2">
        <div className="login4">
          <Image src={logo} alt="" className="login3" />
        </div>
        <div className="login5">Reset Password</div>
        <div>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" style={{position: "relative"}}>
            <Form.Label className="emailField">Email</Form.Label>
            <Form.Control
              type="text"
              placeholder="name@example.com"
              className="login7"
              style={{ boxShadow: "none" }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {emailVerification !== "" ? (
              <div id="signupform15">
                <FaCheckCircle id="signupform16" />
              </div>
            ) : (
              <>
                {!emailVerify ? (
                  <div
                    id="emailFieldVerify"
                    onClick={() => emailOtpSendFunction()}
                    style={{ display: "inline-block" }}
                  >
                    verify
                  </div>
                ) : null}
                {emailVerify ? (
                  <div
                    id="emailFieldVerify"
                    onClick={() => {
                      setEmailVerify(!emailVerify);
                      setEmailOtp("");
                    }}
                    style={{ display: "inline-block" }}
                  >
                    cancel
                  </div>
                ) : null}
              </>
            )}
          </Form.Group>
          {emailVerify ? (
            <Form.Group className="mb-3">
              <div className="login6 mb-2">Enter Your OTP</div>
              <InputGroup id="signupForm8" className="emailField">
                <Form.Control
                  type="text"
                  placeholder="Enter Otp*"
                  id="signupForm10"
                  className="login7"
                  value={emailOtp}
                  onChange={(e) => setEmailOtp(e.target.value)}
                  maxLength={6}
                />
              </InputGroup>
            </Form.Group>
          ) : null}
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="login6">Password</Form.Label>
            <div className="password-input" style={{ position: "relative" }}>
              <Form.Control
                type="password"
                placeholder="password"
                style={{ boxShadow: "none" }}
                className="login7"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span
                onClick={togglePasswordVisibility}
                style={{
                  position: "absolute",
                  top: 4,
                  right: 10,
                  fontSize: "1.3rem",
                  color: "#9A9AB0",
                  cursor: "pointer",
                }}
              ></span>
            </div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="login6">Confirm Password</Form.Label>
            <div className="password-input" style={{ position: "relative" }}>
              <Form.Control
                type={showPassword ? "text" : "password"}
                placeholder="Confirm password"
                style={{ boxShadow: "none" }}
                className="login7"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <span
                onClick={togglePasswordVisibility}
                style={{
                  position: "absolute",
                  top: 4,
                  right: 10,
                  fontSize: "1.3rem",
                  color: "#9A9AB0",
                  cursor: "pointer",
                }}
              >
                {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
              </span>
            </div>
          </Form.Group>
          <div>
            <Button id="login8" onClick={() => handleResetPassword()}>
              {isLoading ? (
                <Loader animation="border" size="sm" variant="light" />
              ) : (
                "Reset"
              )}
            </Button>
          </div>
        </div>

        <div className="login9">
          {/* Do not have an Account ? <span className="login10">Signup</span> */}
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
