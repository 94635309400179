import React, { useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import "./Terms.css";
import { MdDelete, MdEdit } from "react-icons/md";
import { useState } from "react";
import { API, apicaller } from "../../utils/api";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import TermsModal from "./components/TermsModal/Termsmodal";
import { useDispatch, useSelector } from "react-redux";
import { clear, getUserToken } from "../../Redux/Slice/UserSlice";
import Utility from "../../utils/Utility";
import SettingsGlobalHeader from "../Settings/components/SettingsGlobalHeader/SettingsGlobalHeader";
import GlobalButton from "../../components/GlobalButton/GlobalButton";

export default function Terms() {
  const [modal, setModal] = useState(false);
  const [deletingData, setDeletingData] = useState(null);
  const [terms, setTerms] = useState([]);

  useEffect(() => {
    getTermsData();
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector(getUserToken);

  const getTermsData = () => {
    apicaller("get-terms", null, "get", token, "application/json")
      .then((res) => {
        setTerms(res.data);
        console.log("data is showing...$$$$", res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.error === "Token Session expired") {
          Utility.eToast(
            "Your token has expired, Please Login again to continue!"
          );
          dispatch(clear());
          navigate("/login");
        }
      });
  };

  const deletedata = (id) => {
    if (window.confirm("Are you sure to delete your data")) {
      setDeletingData(id);
      var config = {
        method: "delete",
        url: `${API}/delete-terms/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      axios(config)
        .then(function (response) {
          getTermsData();
          Utility.sToast("Data Deleted Sucessfully");
        })
        .catch(function (err) {
          if (err?.response?.data?.error === "Token Session expired") {
            Utility.eToast(
              "Your token has expired, Please Login again to continue!"
            );
            dispatch(clear());
            navigate("/login");
          } else {
            Utility.eToast(
              "Unable to delete! Please try again. !"
            );
          }
        })
        .finally(() => {
          setDeletingData(null);
        });
    }
  };
  const handleClose = () => {
    setModal(false);
  };

  return (
    <>
      <Container>
        <SettingsGlobalHeader
          headingText="Terms & Conditions"
          showButton={true}
          onClick={() => {
            setModal(true);
          }}
        />
        <TermsModal
          show={modal}
          onHide={handleClose}
          getData={getTermsData}
        />
        {terms?.map((list, index) => (
          <Row key={index} id="tc5">
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
              <div
                dangerouslySetInnerHTML={{
                  __html: list?.description,
                }}
              />
            </Col>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 10,
              }}
            >
              <GlobalButton
                title="Edit"
                width={130}
                height={46}
                onclick={() => {
                  navigate("/settings/terms-edit", {
                    state: { id: list },
                  });
                }}
              />
              <GlobalButton
                title={deletingData === list?._id ? "Removing" : "Remove"}
                width={130}
                height={46}
                backgroundColor="transparent"
                borderStyle="dashed"
                borderColor="#EF0940"
                textColor="#EF0940"
                fontWeight={400}
                loading={deletingData === list?._id}
                loadingColor="#EF0940"
                onclick={() => {
                  deletedata(list?._id);
                }}
              />
            </div>
          </Row>
        ))}
      </Container>
    </>
  );
}
