import React, { useEffect, useState } from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { clear, getUserToken } from "../../Redux/Slice/UserSlice";
import { apicaller } from "../../utils/api";
import Utility from "../../utils/Utility";
import DataTable from "react-data-table-component";
import { FaUserFriends } from "react-icons/fa";
import "./AllUser.css";
import Mapcomp from "../../components/Mapcomp/Mapcomp";
import SaleStatistics from "../Finance/Components/SaleStatistics";
import Dashcards from "../Dashcards/Dashcards";
import { FaUserGraduate } from "react-icons/fa6";
import AllCourses from "../../components/AllCourses/AllCourses";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import moment from "moment";

function AllUser() {
  const [spin, setSpin] = useState(true);
  const [allUser, setAllUser] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [userData, setUserData] = useState("");
  const [monthData, setMonthData] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const currentYear = moment().year();
  const [year, setYear] = useState(currentYear);
  const [yearList, setYearList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (newPerPage, page) => {
    setRowsPerPage(newPerPage);
    setCurrentPage(page);
  };
  const handleChange = (value) => {
    setYear(value);
    setYear(value);
  };
  const Token = useSelector(getUserToken);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getAllUser();
    getMonthWiseUser();
  }, [year]);

  useEffect(() => {
    const startYear = 2020;
    const currentYear = new Date().getFullYear();
    getYearList(startYear, currentYear);
  }, []);

  function getYearList(startYear, endYear) {
    const years = Array.from(
      { length: endYear - startYear + 1 },
      (_, index) => startYear + index
    );
    setYearList(years);
  }

  const getAllUser = async () => {
    try {
      const res = await apicaller("admin/student-list", null, "get", Token);
      setAllUser(res?.data?.student);
      setFilteredUsers(res?.data?.student);

      setSpin(false);
    } catch (err) {
      if (err?.response?.data?.error === "Token Session expired") {
        Utility.eToast(
          "Your token has expired, Please Login again to continue!"
        );
        dispatch(clear());
        navigate("/login");
      }
    }
  };
  const getMonthWiseUser = async () => {
    try {
      const res = await apicaller(
        `month-wise-user?year=${year}`,
        null,
        "get",
        Token
      );
      console.log(res?.data?.result?.month, "abcd");
      setUserData(res?.data?.result?.data);
      setMonthData(res?.data?.result?.month);
    } catch (err) {
      if (err?.response?.data?.error === "Token Session expired") {
        Utility.eToast(
          "Your token has expired, Please Login again to continue!"
        );
        dispatch(clear());
        navigate("/login");
      }
    }
  };

  const handleSearchChange = (event) => {
    const searchText = event.target.value.toLowerCase();
    setFilterText(searchText);

    const filtered = allUser.filter((user) => {
      const fullName = `${user?.first_name} ${user?.last_name}`.toLowerCase();
      return (
        user?.first_name?.toLowerCase()?.includes(searchText) ||
        user?.last_name?.toLowerCase()?.includes(searchText) ||
        user?.email?.toLowerCase()?.includes(searchText) ||
        user?.phone_number?.includes(searchText) ||
        fullName?.toLowerCase()?.includes(searchText)
      );
    });
    setFilteredUsers(filtered);
  };

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
      },
    },
  };
  const columns = [
    {
      name: "NO",
      cell: (row, index) => (currentPage - 1) * rowsPerPage + index + 1,
      sortable: true,
    },
    {
      name: "Name",
      selector: "",
      cell: (row) => `${row?.first_name} ${row?.last_name}`,
      sortable: true,
    },
    {
      name: "Center",
      selector: "",
      cell: (row) =>
        row?.centerId?.center_name && row?.centerId?.district_id
          ? `${row?.centerId?.center_name}, ${row?.centerId?.district_id?.district_name}, ${row?.centerId?.district_id?.states}`
          : "No Center Assigned",
      sortable: true,
    },
    {
      name: "Email Id",
      cell: (row) => row?.email,
      sortable: true,
    },
    {
      name: "Contact",
      cell: (row) => `${row?.phone_number}`,
      sortable: true,
    },
    {
      name: "View",
      cell: (row) => (
        <div
          onClick={() => {
            const name = `${row?.first_name}-${row?.last_name}`.toLowerCase();
            const id = row?._id;
            navigate(`/students/details?name=${name}&id=${id}`, {
              state: { res: row },
            });
          }}
          className="detailsBtn"
        >
          View Details
        </div>
      ),
      sortable: true,
    },
  ];
  console.log(filteredUsers, columns, "users");

  return (
    <Container fluid>
      {spin ? (
        <div id="abt9">
          <Spinner animation="border" variant="warning" />
        </div>
      ) : (
        <Container fluid>
          <Row>
            <Col md={4} lg={4} sm={12} xs={12}>
              <Dashcards
                icon={<FaUserGraduate id="dash14" />}
                title="Student"
                amount={filteredUsers?.length}
                background="#FFE7F1"
                borderRadius="20rem"
              />
            </Col>
            <Col md={8} lg={8} sm={12} xs={12}>
              <div id="dash21" style={{ position: "relative" }}>
                <span style={{ position: "absolute", top: 8, right: 8 }}>
                  <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel id="demo-select-small-label">Year</InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={year}
                      label="Year"
                      onChange={(e) => handleChange(e.target.value)}
                    >
                      {yearList.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </span>
                <div className="mx-5">
                  {userData && monthData && (
                    <SaleStatistics
                      height={150}
                      label="Students"
                      data={userData}
                      months={monthData}
                      title={"Statistics for student"}
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>
          <Row id="au1">
            <Col>
              <FaUserFriends /> All Students
            </Col>
            <Col className="searchInputContainer">
              {" "}
              <input
                type="text"
                placeholder="Search by name..."
                className="searchInput"
                value={filterText}
                onChange={handleSearchChange}
              />
            </Col>
          </Row>
          <DataTable
            columns={columns}
            data={filteredUsers}
            customStyles={customStyles}
            pagination
            paginationTotalRows={filteredUsers.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
          />
        </Container>
      )}
    </Container>
  );
}

export default AllUser;
