import React, { useState } from "react";
import "./Courseadmindetails.css";
import { Col, Container, Row } from "react-bootstrap";
import { Button, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import Courseadmincards from "../../components/Courseadmincards/Courseadmincards";
import { GiBookmark } from "react-icons/gi";
import { FaUserGraduate } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { apicaller } from "../../utils/api";
import { useDispatch, useSelector } from "react-redux";
import { clear, getUserToken } from "../../Redux/Slice/UserSlice";
import Utility from "../../utils/Utility";
import Loader from "../../components/Loader/Loader";
import { useEffect } from "react";
import { Rating } from "react-simple-star-rating";
import BackButton from "../../components/BackButton";
import Courseadminpiechart from "../../components/Courseadminpiechart/Courseadminpiechart";
import CourseCard from "../../components/CourseCard/CourseCard";
export default function Courseadmindetails() {
  const Token = useSelector(getUserToken);
  const location = useLocation();
  const { selectedRow } = location.state;
  const courseAdminPriviliges = selectedRow?.priviliges;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [createAccess, setCreateAccess] = useState(
    courseAdminPriviliges?.includes("create") ? "yes" : "no"
  );
  const [editAccess, setEditAccess] = useState(
    courseAdminPriviliges?.includes("edit") ? "yes" : "no"
  );
  const [isloading, setIsloading] = useState(false);
  const [courses, setCourses] = useState("");
  const [totalStudents, setTotalStudents] = useState(0);
  const [removeaccess, setRemoveaccess] = useState(
    courseAdminPriviliges?.includes("remove") ? "yes" : "no"
  );

  const handleCreateAccessChange = (event) => {
    setCreateAccess(event.target.value);
  };

  const handleEditAccessChange = (event) => {
    setEditAccess(event.target.value);
  };
  const handleRemoveAccessChange = (event) => {
    setRemoveaccess(event.target.value);
  };

  const updatePrivillage = async () => {
    const privileges = [];

    if (createAccess === "yes") {
      privileges.push("create");
    }

    if (editAccess === "yes") {
      privileges.push("edit");
    }

    if (removeaccess === "yes") {
      privileges.push("remove");
    }
    setIsloading(true);
    const data = {
      _id: selectedRow?._id,
      priviliges: privileges,
    };

    console.log("Data is......", data);

    await apicaller(`course-admin-update`, data, "PUT", Token)
      .then((res) => {
        if (res.status === 201 || res?.status === 200) {
          Utility.sToast("Privileges updated Successfully ! ");
          setIsloading(false);
        }
      })
      .catch((err) => {
        Utility.eToast("Oops Something Went Wrong");
        if (err?.response?.data?.error === "Token Session expired") {
          Utility.eToast(
            "Your token has expired, Please Login again to continue!"
          );
          dispatch(clear());
          navigate("/login");
        }
      });
  };

  useEffect(() => {
    getAllcoursecards();
  }, []);

  const getAllcoursecards = async () => {
    await apicaller(
      `course-by-author?id=${selectedRow?._id}`,
      null,
      "get",
      Token
    )
      .then((res) => {
        setCourses(res?.data?.course);
        console.log("Courses are......", res?.data?.course);
        setTotalStudents(res?.data?.students);
      })
      .catch((err) => {
        Utility.eToast("Unable to fetch Courses !");
        if (err?.response?.data?.error === "Token Session expired") {
          Utility.eToast(
            "Your token has expired, Please Login again to continue!"
          );
          dispatch(clear());
          navigate("/login");
        }
      });
  };

  const disableAccount = async () => {
    const enablecheck = !selectedRow?.login;

    if (
      window.confirm(
        `Are You Sure Want to ${
          enablecheck ? "Active" : "Suspend"
        } This Account ?`
      )
    ) {
      const data = {
        _id: selectedRow?._id,
        login: enablecheck,
      };

      console.log("Disable are......", data);

      await apicaller(`course-admin-update`, data, "PUT", Token)
        .then((res) => {
          if (res?.status === 201 || res?.status === 200) {
            if (enablecheck) {
              Utility.sToast("Account Activated Successfully ! ");
            } else {
              Utility.sToast("Account Suspended Successfully");
            }
            navigate("/academic-admins");
            getAllcoursecards();
          }
        })
        .catch((err) => {
          Utility.eToast("Unable to Update Details !");
          if (err?.response?.data?.error === "Token Session expired") {
            Utility.eToast(
              "Your token has expired, Please Login again to continue!"
            );
            dispatch(clear());
            navigate("/login");
          }
        });
    }
  };

  return (
    <>
      <Container>
        <BackButton />
        <div id="courseadmin1" className="mt-4">
          <Row>
            <Col md={10} lg={10} sm={10} xs={10}>
              <div id="courseadmin2">
                {selectedRow?.first_name} {selectedRow?.last_name || "N/A"}'s
                Details
              </div>
            </Col>
            <Col md={2} lg={2} sm={2} xs={2}>
              <div id="courseadmin6">
                <Button
                  variant="contained"
                  onClick={() => disableAccount()}
                  style={{
                    backgroundColor: selectedRow?.login ? "red" : "green",
                  }}
                >
                  {selectedRow?.login ? "Suspend Account" : "Activate Account"}
                </Button>
              </div>
            </Col>
          </Row>
          <hr></hr>
          <Row>
            <Row id="courseadmin12">
              <Col md={4} lg={4} sm={12} xs={12}>
                <div>
                  <Courseadmincards
                    title="Total Course Added"
                    count={courses?.length || 0}
                    icon={<GiBookmark id="dash20" />}
                  />
                </div>
                <div>
                  <div id="courseadmin13">
                    <Row id="courseadmin5">
                      <Col md={3} lg={3} sm={3} xs={3}>
                        <div id="courseadmin3">Name</div>
                      </Col>
                      <Col md={9} lg={9} sm={9} xs={9}>
                        <div id="courseadmin4">
                          {" "}
                          : {selectedRow?.first_name}{" "}
                          {selectedRow?.last_name || "N/A"}
                        </div>
                      </Col>
                    </Row>
                    <Row id="courseadmin5">
                      <Col md={3} lg={3} sm={3} xs={3}>
                        <div id="courseadmin3">Email</div>
                      </Col>
                      <Col md={9} lg={9} sm={9} xs={9}>
                        <div id="courseadmin4">
                          : {selectedRow?.email || "N/A"}
                        </div>
                      </Col>
                    </Row>
                    <Row id="courseadmin5">
                      <Col md={3} lg={3} sm={3} xs={3}>
                        <div id="courseadmin3">Contact</div>
                      </Col>
                      <Col md={9} lg={9} sm={9} xs={9}>
                        <div id="courseadmin4">
                          {" "}
                          : {selectedRow?.phone_number || "N/A"}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col md={4} lg={4} sm={12} xs={12}>
                <div>
                  <Courseadmincards
                    title="Total Student Enrolled"
                    count={totalStudents || 0}
                    icon={<FaUserGraduate id="dash14" />}
                  />
                </div>
                <div id="courseadmin14">
                  <Courseadminpiechart />
                </div>
              </Col>
              <Col md={4} lg={4} sm={12} xs={12}>
                <div id="courseadmin8">
                  <Row>
                    <Col md={12} lg={12} sm={12} xs={12}>
                      <div id="courseadmin7">Priviliges</div>
                    </Col>
                  </Row>
                  <hr></hr>
                  <Row>
                    <Col md={6} lg={6} sm={6} xs={6}>
                      <div id="courseadmin11">Create Access</div>
                    </Col>
                    <Col md={3} lg={3} sm={3} xs={3}>
                      <div>
                        <RadioGroup
                          row
                          aria-label="createAccess"
                          name="createAccess"
                          value={createAccess}
                          onChange={handleCreateAccessChange}
                        >
                          <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                          />
                        </RadioGroup>
                      </div>
                    </Col>
                    <Col md={3} lg={3} sm={3} xs={3}>
                      <div>
                        <RadioGroup
                          row
                          aria-label="createAccess"
                          name="createAccess"
                          value={createAccess}
                          onChange={handleCreateAccessChange}
                        >
                          <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6} lg={6} sm={6} xs={6}>
                      <div id="courseadmin11">Edit Access</div>
                    </Col>
                    <Col md={3} lg={3} sm={3} xs={3}>
                      <div>
                        <RadioGroup
                          row
                          aria-label="editAccess"
                          name="editAccess"
                          value={editAccess}
                          onChange={handleEditAccessChange}
                        >
                          <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                          />
                        </RadioGroup>
                      </div>
                    </Col>
                    <Col md={3} lg={3} sm={3} xs={3}>
                      <div>
                        <RadioGroup
                          row
                          aria-label="editAccess"
                          name="editAccess"
                          value={editAccess}
                          onChange={handleEditAccessChange}
                        >
                          <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} lg={6} sm={6} xs={6}>
                      <div id="courseadmin11">Remove Access</div>
                    </Col>
                    <Col md={3} lg={3} sm={3} xs={3}>
                      <div>
                        <RadioGroup
                          row
                          aria-label="removeaccess"
                          name="removeaccess"
                          value={removeaccess}
                          onChange={handleRemoveAccessChange}
                        >
                          <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                          />
                        </RadioGroup>
                      </div>
                    </Col>
                    <Col md={3} lg={3} sm={3} xs={3}>
                      <div>
                        <RadioGroup
                          row
                          aria-label="removeaccess"
                          name="removeaccess"
                          value={removeaccess}
                          onChange={handleRemoveAccessChange}
                        >
                          <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </div>
                    </Col>
                  </Row>
                  <Row id="courseadmin9">
                    <Col md={12} lg={12} sm={12} xs={12}>
                      <div>
                        <Button
                          id="courseadmin10"
                          variant="contained"
                          onClick={() => updatePrivillage()}
                        >
                          {isloading ? (
                            <Loader
                              animation="border"
                              size="sm"
                              varient="light"
                            />
                          ) : (
                            "Update"
                          )}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Row>
          <Row>
            <Col md={12} lg={12} sm={12} xs={12}>
              <div id="courseadmin15">All Courses</div>
            </Col>
          </Row>
          <hr></hr>
          <Row>
            {courses && courses.length > 0 ? (
              courses.map((course) => (
                <Col
                  key={course?._id}
                  md={3}
                  lg={3}
                  sm={12}
                  xs={12}
                  id="courseadmin26"
                >
                  <CourseCard courseDetails={course} token={Token} />
                  {/* <div id="courseadmin16">
                    <img
                      src={course?.img}
                      style={{ width: "100%", height: "8rem" }}
                      alt={course?.name}
                    />
                    <Row id="courseadmin17">
                      <Col md={8} lg={8} sm={8} xs={8}>
                        <div id="courseadmin18">{course?.name}</div>
                      </Col>
                      <Col md={4} lg={4} sm={4} xs={4}></Col>
                    </Row>
                    <Row id="courseadmin23">
                      <Col md={6} lg={6} sm={6} xs={6}>
                        <div id="courseadmin21">{course?.price}/-</div>
                      </Col>
                      <Col md={6} lg={6} sm={6} xs={6}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: "0.6rem",
                          }}
                        >
                          <Rating
                            size={18}
                            fillColor="#02af4c"
                            initialValue={course?.ratings || 0}
                            readonly
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row id="courseadmin25">
                      <Col md={12} lg={12} sm={12} xs={12}>
                        <div>
                          <Button id="courseadmin24">View Details</Button>
                        </div>
                      </Col>
                    </Row>
                  </div> */}
                </Col>
              ))
            ) : (
              <Col md={12} lg={12} sm={12} xs={12}>
                <div id="courseadmin27">No course found !</div>
              </Col>
            )}
          </Row>
        </div>
      </Container>
    </>
  );
}
