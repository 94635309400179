import React, { useEffect, useState } from "react";
import "./Finance.css";
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Pagination,
  Spinner,
} from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import { Chart as chartjs, scales } from "chart.js/auto";
import SaleComp from "./Components/SaleComp";
import SaleStatistics from "./Components/SaleStatistics";
import { useDispatch, useSelector } from "react-redux";
import { apicaller } from "../../utils/api";
import clear from "../../Redux/Slice/UserSlice";
import Utility from "../../utils/Utility";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

// const SalesObj = [
//   {
//     date: "Jan 4, 2024",
//     saleId: "232vd21",
//     status: "Complete",
//     Purchaser: "Jane Doe",
//     Amount: "$100",
//     Paymentmethod: "Paytm",
//   },
//   {
//     date: "Jan 4, 2024",
//     saleId: "232vd22",
//     status: "Complete",
//     Purchaser: "Jane Doe",
//     Amount: "$100",
//     Paymentmethod: "Paytm",
//   },
//   {
//     date: "Jan 4, 2024",
//     saleId: "232vd23",
//     status: "Complete",
//     Purchaser: "Jane Doe",
//     Amount: "$100",
//     Paymentmethod: "Paytm",
//   },
//   {
//     date: "Jan 4, 2024",
//     saleId: "232vd27",
//     status: "Complete",
//     Purchaser: "Jane Doe",
//     Amount: "$100",
//     Paymentmethod: "Paytm",
//   },
//   {
//     date: "Jan 4, 2024",
//     saleId: "232vd271",
//     status: "Complete",
//     Purchaser: "Jane Doe",
//     Amount: "$100",
//     Paymentmethod: "Paytm",
//   },
//   {
//     date: "Jan 4, 2024",
//     saleId: "232vd271",
//     status: "Complete",
//     Purchaser: "Jane Doe",
//     Amount: "$100",
//     Paymentmethod: "Paytm",
//   },
//   {
//     date: "Jan 4, 2024",
//     saleId: "232vd24",
//     status: "Complete",
//     Purchaser: "Jane Doe",
//     Amount: "$100",
//     Paymentmethod: "Paytm",
//   },
// ];

const Sales = ({ salesData }) => {
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = salesData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(salesData.length / itemsPerPage);

  const handlePaginationClick = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <Table responsive bordered id="Financs-Table">
        <thead>
          <tr>
            <th>Slno</th>
            <th>Name</th>
            {/* <th>Sale ID</th> */}
            <th>Date</th>
            <th>Status</th>
            <th>Amount</th>
            {/* <th>Payment method</th> */}
          </tr>
        </thead>
        <tbody>
          {currentItems.map((obj, index) => (
            <tr key={index}>
              <td>{obj.slno}</td>
              <td>{obj.Purchaser}</td>
              <td>{obj.date}</td>
              <td>
                <span className="FinanceTableStatus">{obj.status}</span>
              </td>
              <td>{obj.Amount}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Pagination size="sm" id="Financepagination">
        {Array.from({ length: totalPages }, (_, i) => (
          <Pagination.Item
            key={i + 1}
            active={i + 1 === currentPage}
            onClick={() => handlePaginationClick(i + 1)}
          >
            {i + 1}
          </Pagination.Item>
        ))}
      </Pagination>
    </>
  );
};

const Refund = ({ salesData }) => {
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = salesData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(salesData.length / itemsPerPage);

  const handlePaginationClick = (page) => {
    setCurrentPage(page);
  };
  return (
    <>
      REFUND
      <Table responsive bordered id="Financs-Table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Sale ID</th>
            <th>Status</th>
            <th>Purchaser</th>
            <th>Amount</th>
            <th>Payment method</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((obj, index) => (
            <tr key={index}>
              <td>{obj.date}</td>
              <td>{obj.saleId}</td>
              <td>
                <span className="FinanceTableStatus">{obj.status}</span>
              </td>
              <td>{obj.Purchaser}</td>
              <td>{obj.Amount}</td>
              <td>{obj.Paymentmethod}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination>
        {Array.from({ length: totalPages }, (_, i) => (
          <Pagination.Item
            key={i + 1}
            active={i + 1 === currentPage}
            onClick={() => handlePaginationClick(i + 1)}
          >
            {i + 1}
          </Pagination.Item>
        ))}
      </Pagination>
    </>
  );
};

const Component2 = () => {
  const [pagetoshow, setPagetoshow] = useState(true);
  const [orderData, setOrderData] = useState([]);

  const choosepage = (e) => {
    if (e) {
      setPagetoshow(true);
    } else {
      setPagetoshow(false);
    }
  };

  useEffect(() => {
    getOrderData();
  }, []);

  const token = useSelector((state) => state?.user?.token);

  function formatDate(isoDateString) {
    const date = new Date(isoDateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDateString = `${day}-${month}-${year}`;
    return formattedDateString;
  }

  const getOrderData = async () => {
    await apicaller("getAllPaidOrders?status=paid", null, "GET", token, null)
      .then((res) => setOrderData(res?.data?.orders?.reverse()))
      .catch((err) => console.log(err));
  };

  const SalesObj = orderData?.map((item, i) => ({
    slno: i + 1,
    Purchaser: `${item?.student_id?.first_name} ${item?.student_id?.last_name}`,
    date: formatDate(item?.createdAt),
    status: item?.status,
    Amount: item?.totalPrice,
    // Paymentmethod: "Paytm",
  }));
  return (
    <>
      <Container fluid className="FinanceComponent2-1">
        <Button
          className={pagetoshow ? "active" : ""}
          onClick={() => {
            choosepage(true);
          }}
        >
          Orders
        </Button>
        {/* <Button
          className={!pagetoshow ? "active" : ""}
          onClick={() => {
            choosepage(false);
          }}
        >
          Refund
        </Button> */}
      </Container>
      <Container fluid className="FinanceComponent2-2">
        {pagetoshow ? (
          <Sales salesData={SalesObj} />
        ) : (
          <Refund salesData={SalesObj} />
        )}
      </Container>
    </>
  );
};

const Finance = () => {
  const [revenue, setRevenue] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [chartMonth, setChartMonth] = useState([]);
  const [year, setYear] = useState(2024);
  const [isLoading, setIsLoading] = useState(true);

  const handleChange = (event) => {
    setYear(event.target.value);
  };

  useEffect(() => {
    getRevenue();
  }, []);
  useEffect(() => {
    getChartData();
  }, [year]);

  const token = useSelector((state) => state?.user?.token);
  const dispatch = useDispatch();

  const getRevenue = async () => {
    await apicaller("order?status=paid", null, "GET", token, null)
      .then((res) => setRevenue(res?.data?.totalPriceSum))
      .catch((err) => {
        console.log(err);
        if (err?.status === 401) {
          dispatch(clear());
          Utility.eToast("Token expires, please login again to continue!");
        }
      });
  };

  const getChartData = async () => {
    await apicaller(
      `getAllPaidOrdersChartData?status=paid&year=${year}`,
      null,
      "GET",
      token,
      null
    )
      .then((res) => {
        setChartMonth(res?.data?.months);
        setChartData(res?.data?.data);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  };

  return (
    <Container fluid className="p-4">
      {isLoading ? (
        <div id="abt9">
          <Spinner animation="border" variant="warning" />
        </div>
      ) : (
        <>
          <Container fluid className="my-3">
            <Row className="g-3">
              <Col md="3" sm="12">
                <SaleComp amount={revenue} text={"Total Revenue"} />
              </Col>
              {/* <Col md="3" sm="12">
            <SaleComp amount={"1,00,000"} text={"Net Sale"} />
          </Col>
          <Col md="3" sm="12">
            <SaleComp amount={"1,00,000"} text={"Pending amount"} />
          </Col>
          <Col md="3" sm="12">
            <SaleComp amount={"1,00,000"} text={"Refund"} />
          </Col> */}
            </Row>
          </Container>
          <Container fluid id="FinanceBarchart">
            <SaleStatistics
              height={300}
              label="Sales"
              data={chartData}
              months={chartMonth}
            />
            <span style={{ position: "absolute", top: 0, right: 0 }}>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="demo-select-small-label">Year</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={year}
                  label="year"
                  onChange={handleChange}
                >
                  <MenuItem value={2024}>2024</MenuItem>
                  <MenuItem value={2025}>2025</MenuItem>
                  <MenuItem value={2026}>2026</MenuItem>
                  <MenuItem value={2027}>2027</MenuItem>
                  <MenuItem value={2028}>2028</MenuItem>
                  <MenuItem value={2029}>2029</MenuItem>
                  <MenuItem value={2030}>2030</MenuItem>
                </Select>
              </FormControl>
            </span>
          </Container>
          <Container fluid>
            <Component2 />
          </Container>
        </>
      )}
    </Container>
  );
};

export default Finance;
