import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  ListGroup,
  Spinner,
} from "react-bootstrap";
import { clear, getUserToken } from "../../Redux/Slice/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import { API, apicaller } from "../../utils/api";
import { IoMdAdd } from "react-icons/io";
import "./AddCourse.css";
import EditCategory from "./Components/EditCategory/EditCategory";
import axios from "axios";
import Utility from "../../utils/Utility";
import { useNavigate } from "react-router-dom";

function AddCourse() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [inputValue, setInputValue] = useState("");
  const [addedItems, setAddedItems] = useState([]);
  const [postLoader, setPostLoader] = useState(false);
  const [editCat, setEditCat] = useState(false);
  const [editId, setEditId] = useState("");
  const [loadingRemoveCategory, setLoadingRemoveCategory] = useState("");
  const Token = useSelector(getUserToken);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await apicaller("get-category", null, "GET", Token);
      console.log(response, "Fetched categories");
      // Assuming the API response is an array of categories
      setAddedItems(response?.data?.category?.reverse());
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleAddItem = () => {
    if (inputValue?.trim() !== "") {
      setPostLoader(true);
      const data = {
        category_name: inputValue,
      };
      try {
        apicaller("add-category", data, "POST", Token)
          .then((res) => {
            fetchCategories();
          })
          .catch((error) => {
            console.error("Error adding category:", error);
          });
      } catch (error) {
        console.error("Error calling API:", error);
      } finally {
        setPostLoader(false);
        setInputValue("");
      }
    }
  };

  const handleRemove = (item) => {
    if (
      window.confirm(
        `Are you sure to delete Category "${item?.category_name}" ? If you delete, it will impact the courses linked with this category.`
      )
    ) {
      setLoadingRemoveCategory(item?._id);
      var config = {
        method: "delete",
        url: `${API}delete-category/${item?._id}`,
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      };
      axios(config)
        .then(function (response) {
          fetchCategories();
          Utility.sToast("Category Deleted Sucessfully");
        })
        .catch(function (err) {
          if (err?.response?.data?.error === "Token Session expired") {
            Utility.eToast(
              "Your token has expired, Please Login again to continue!"
            );
            dispatch(clear());
            navigate("/login");
          } else {
            Utility.eToast("Unable to Delete Category !");
          }
        })
        .finally(() => {
          setLoadingRemoveCategory("");
        });
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col className="categoryContainer ">
          <Row>
            <Col xs={9} sm={9} md={9} lg={9} xl={9} xxl={9} className=" ms-3">
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label className="fromLabel">Course Category</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter category"
                    value={inputValue}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Form>
            </Col>
            <Col xs={2} sm={2} md={2} lg={2} xl={2} className="addcours101">
              <Button
                variant="primary"
                disabled={postLoader || inputValue?.trim() === ""}
                onClick={handleAddItem}
              >
                {postLoader ? (
                  <Spinner
                    animation="border"
                    variant="light"
                    style={{ height: "1rem", width: "1rem" }}
                  />
                ) : (
                  <IoMdAdd className="addBtn" />
                )}
              </Button>
            </Col>
          </Row>

          <div className="categoryHeaderRow">
            <p className="categoryHeader">Category</p>
            <p className="categoryHeader"> ( {addedItems.length} Categories)</p>
          </div>
          <ListGroup>
            {addedItems.map((item, index) => (
              <ListGroup.Item key={index} className="categoryRow">
                <span className="categoryName">{item?.category_name}</span>
                <div className="buttons">
                  {/* <p
                    className="editBtn"
                    onClick={() => {
                      setEditCat(true, item);
                      setEditId(item);
                    }}
                  >
                    Edit
                  </p>
                  <p className="removeBtn" onClick={() => handleRemove(item)}>
                    Remove
                  </p> */}
                  <Button
                    variant="danger"
                    className="editBtn"
                    onClick={() => {
                      setEditCat(true, item);
                      setEditId(item);
                    }}
                  >
                    Edit
                  </Button>{" "}
                  <Button
                    variant="danger"
                    className="removeBtn"
                    disabled={loadingRemoveCategory === item?._id}
                    onClick={() => handleRemove(item)}
                  >
                    Remove{" "}
                    {loadingRemoveCategory === item?._id && (
                      <Spinner animation="border" variant="light" size="sm" />
                    )}
                  </Button>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
          <EditCategory
            show={editCat}
            onHide={() => setEditCat(false)}
            item={editId}
            fetchcategories={fetchCategories}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default AddCourse;
