import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row, Table, Spinner } from "react-bootstrap";
import "./Dashboard.css";
import DashboardCard from "../../components/DashboardCard/DashboardCard";
import { Link, useNavigate } from "react-router-dom";
import teacherimage from "../../assets/professor.gif";
import subadminimage from "../../assets/add-user.gif";
import totaluser from "../../assets/overpopulation.gif";
import totalorder from "../../assets/notifications.gif";
import useDashboard from "./hooks/useDashboard";
import Dashcards from "../Dashcards/Dashcards";
import { FaBookOpen } from "react-icons/fa";
import { FaUserGraduate, FaUserLock, FaUserSecret } from "react-icons/fa6";
import { GiBookmark, GiPayMoney } from "react-icons/gi";
import { PiBuildingsBold } from "react-icons/pi";
import { MdAssignmentInd } from "react-icons/md";
import Mapcomp from "../../components/Mapcomp/Mapcomp";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { apicaller } from "../../utils/api";
import { useDispatch, useSelector } from "react-redux";
import { clear, getUserToken } from "../../Redux/Slice/UserSlice";
import TopPerformedCourse from "./Components/TopPerformedCourse";
import Utility from "../../utils/Utility";

export default function Dashboard() {
  const [chartData, setChartData] = useState([]);
  const [chartMonth, setChartMonth] = useState([]);
  const [year, setYear] = useState(2024);
  const handleChange = (event) => {
    setYear(event.target.value);
  };
  const {
    allOrder,
    allStudent,
    allTeacher,
    spin,
    allExam,
    courseAdmin,
    centers,
    allassignment,
  } = useDashboard();

  useEffect(() => {
    topPerformedCourses();
  }, [year]);

  const Token = useSelector(getUserToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const topPerformedCourses = async () => {
    await apicaller(`courseperformance?year=${year}`, null, "GET", Token, null)
      .then((res) => {
        setChartData(res?.data?.data[0]?.course);
        setChartMonth(res?.data?.data[1]?.month);
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.data?.error === "Token Session expired") {
          Utility.eToast(
            "Your token has expired, Please Login again to continue!"
          );
          dispatch(clear());
          navigate("/login");
        }
      });
  };

  return (
    <>
      <Container
        fluid
        style={{ marginBottom: "5rem" }}
        className="content-container"
      >
        {spin ? (
          <div id="abt9">
            <Spinner animation="border" variant="warning" />
          </div>
        ) : (
          <Container fluid>
            {/* <Row id="dash2">
              <Col xs={6} sm={6} md={3} lg={3} xl={3} xxl={3}>
                <Link to="/faculty" id="dash4">
                  <DashboardCard
                    title="Total Teacher"
                    quantity={allTeacher?.length}
                    gifimage={teacherimage}
                  />
                </Link>
              </Col>
              <Col xs={6} sm={6} md={3} lg={3} xl={3} xxl={3}>
                <Link to="/academic-admins" id="dash4">
                  <DashboardCard
                    title="Total Subadmin"
                    quantity={allSubadmin?.length}
                    gifimage={subadminimage}
                  />
                </Link>
              </Col>
              <Col xs={6} sm={6} md={3} lg={3} xl={3} xxl={3} id="dash3">
                <Link to="/alluser" id="dash4">
                  <DashboardCard
                    title="Total User"
                    quantity={allStudent?.length}
                    gifimage={totaluser}
                  />
                </Link>
              </Col>
              <Col xs={6} sm={6} md={3} lg={3} xl={3} xxl={3} id="dash3">
                <Link to="/orderdetail" id="dash4">
                  <DashboardCard
                    title="Total Orders"
                    quantity={allOrder.length}
                    gifimage={totalorder}
                  />
                </Link>
              </Col>
            </Row> */}
            <Row>
              <Col md={3} lg={3} sm={12} xs={12}>
                <Dashcards
                  icon={<FaBookOpen id="dash13" />}
                  title="Exam"
                  amount={allExam}
                  background="#F6FFE7"
                  borderRadius="20rem"
                />
              </Col>
              <Col md={3} lg={3} sm={12} xs={12}>
                <Dashcards
                  icon={<FaUserGraduate id="dash14" />}
                  title="Student"
                  amount={allStudent}
                  background="#FFE7F1"
                  borderRadius="20rem"
                  path="/students"
                />
              </Col>
              <Col md={3} lg={3} sm={12} xs={12}>
                <Dashcards
                  icon={<FaUserLock id="dash15" />}
                  title="Course Admin"
                  amount={courseAdmin}
                  background="#EDE7FF"
                  borderRadius="20rem"
                  path="/academic-admins"
                />
              </Col>
              <Col md={3} lg={3} sm={12} xs={12}>
                <Dashcards
                  icon={<FaUserSecret id="dash16" />}
                  title="Faculty"
                  amount={allTeacher}
                  background="#E7FBFF"
                  borderRadius="20rem"
                  path="/faculty"
                />
              </Col>
            </Row>
            <Row>
              <Col md={3} lg={3} sm={12} xs={12}>
                <Dashcards
                  icon={<GiPayMoney id="dash17" />}
                  title="Revenue"
                  amount={allOrder}
                  background="#E7EDFF"
                  borderRadius="20rem"
                  path="/finance"
                />
              </Col>
              <Col md={3} lg={3} sm={12} xs={12}>
                <Dashcards
                  icon={<PiBuildingsBold id="dash18" />}
                  title="Center"
                  amount={centers}
                  background="#E7F1FF"
                  borderRadius="20rem"
                  path="/center"
                />
              </Col>
              <Col md={3} lg={3} sm={12} xs={12}>
                <Dashcards
                  icon={<MdAssignmentInd id="dash19" />}
                  title="Assignment"
                  amount={allassignment}
                  background="
                  #FFE7E7"
                  borderRadius="20rem"
                />
              </Col>
              {/* <Col md={3} lg={3} sm={12} xs={12}>
                <Dashcards
                  icon={<GiBookmark id="dash20" />}
                  title="Faculty"
                  amount="23,564"
                  background="
                  #E7FFEC"
                  borderRadius="20rem"
                />
              </Col> */}
            </Row>
            <Row>
              {/* <Col md={6} lg={6} sm={12} xs={12}>
                <div id="dash23">
                  <Row id="dash22">Notifications</Row>
                </div>
              </Col> */}
              <Col md={12} lg={12} sm={12} xs={12}>
                <div id="dash21">
                  <h6>Top Performed Courses</h6>
                  {/* <Mapcomp /> */}
                  {chartData?.length > 0 && chartMonth?.length > 0 ? (
                    <TopPerformedCourse
                      course={chartData}
                      months={chartMonth}
                    />
                  ) : (
                    <div className="dash24">No Data to Show!</div>
                  )}
                  <span style={{ position: "absolute", top: 0, right: 0 }}>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <InputLabel id="demo-select-small-label">Year</InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={year}
                        label="year"
                        onChange={handleChange}
                      >
                        <MenuItem value={2024}>2024</MenuItem>
                        <MenuItem value={2025}>2025</MenuItem>
                        <MenuItem value={2026}>2026</MenuItem>
                        <MenuItem value={2027}>2027</MenuItem>
                        <MenuItem value={2028}>2028</MenuItem>
                        <MenuItem value={2029}>2029</MenuItem>
                        <MenuItem value={2030}>2030</MenuItem>
                      </Select>
                    </FormControl>
                  </span>
                </div>
              </Col>
            </Row>

            {/* <div id="dash12">
              <Row style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                <Col id="dash10">Recent Orders</Col>
              </Row>

              <Row>
                <Col>
                  {" "}
                  <Table striped bordered hover id="dash11">
                    <thead>
                      <tr>
                        <th>Sl No.</th>
                        <th>Course name</th>
                        <th>Order Date</th>
                        <th>Student name</th>
                        <th> Email</th>
                        <th style={{ textAlign: "center" }}>Order status</th>
                        <th>Amount </th>
                      </tr>
                    </thead>
                    <tbody>
                      {allOrder?.slice(0, 15).map((item, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{item?.course_id?.name}</td>
                          <td>{item?.createdAt.slice(0, 10)}</td>
                          <td>
                            {item?.student_id?.first_name}{" "}
                            {item?.student_id?.last_name}
                          </td>
                          <td>{item?.student_id?.email}</td>
                          <td
                            style={{
                              textTransform: "uppercase",
                              fontSize: "1rem",
                              textAlign: "center",
                            }}
                          >
                            {item?.status}
                          </td>
                          <td>{item?.totalPrice}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </div> */}
          </Container>
        )}
      </Container>
    </>
  );
}
