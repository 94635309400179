import React from "react";
import { Row, Col } from "react-bootstrap";
import profilePicture from "../../assets/Profile Picture.png";
function PersonalDetails({ studentDetails }) {
  return (
    <>
      <Row>
        <Col>
          <div className="d-flex mb-2">
            <Col xs={4} className="personalDetailsText">
              Name:
            </Col>
            <Col className="personalDetailsText personalDetailsTextColor">
              {studentDetails?.first_name} {studentDetails?.last_name}
            </Col>
          </div>
          <div className="d-flex mb-2">
            <Col xs={4} className="personalDetailsText">
              Center:
            </Col>
            <Col className="personalDetailsText personalDetailsTextColor">
              {studentDetails?.centerId?.center_name &&
              studentDetails?.centerId?.district_id
                ? `${studentDetails?.centerId?.center_name}, ${studentDetails?.centerId?.district_id?.district_name}, ${studentDetails?.centerId?.district_id?.states}`
                : "No Center Chosen"}
            </Col>
          </div>
          <div className="d-flex mb-2">
            <Col xs={4} className="personalDetailsText">
              Email:
            </Col>
            <Col className="personalDetailsText personalDetailsTextColor">
              {studentDetails?.email}
            </Col>
          </div>
          <div className="d-flex mb-2">
            <Col xs={4} className="personalDetailsText">
              Phone number:
            </Col>
            <Col className="personalDetailsText personalDetailsTextColor">
              {studentDetails?.phone_number}
            </Col>
          </div>
          <div className="d-flex mb-2">
            <Col xs={4} className="personalDetailsText">
              Address:
            </Col>
            <Col className="personalDetailsText personalDetailsTextColor">
              {studentDetails?.address}
            </Col>
          </div>
          <div className="d-flex mb-2">
            <Col xs={4} className="personalDetailsText">
              College:
            </Col>
            <Col className="personalDetailsText personalDetailsTextColor">
              {studentDetails?.graduated_from}
            </Col>
          </div>
        </Col>
        <Col className="d-flex justify-content-end">
          <div id="profilePic">
            <img src={studentDetails?.profile_image} />
          </div>
        </Col>
      </Row>
    </>
  );
}

export default PersonalDetails;
