import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

function SaleStatistics({ label, title, data, height }) {
  // Define the months array
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Generate chart data based on the provided data and months
  const chartData = months.map((month, index) => ({
    month,
    [label]: data[index] || 0, // Ensure there's a default value if data[index] is undefined
  }));

  return (
    <Container fluid>
      <p id="FinanceBarcharHeader">{title}</p>
      <Row>
        <Col>
          <ResponsiveContainer width="100%" height={height || 150}>
            {" "}
            {/* Adjust height as needed */}
            <BarChart data={chartData}>
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Bar
                dataKey={label} // Use the label prop for the bar's data key
                fill="#0D41A9" // Blue background color
                radius={[10, 10, 0, 0]} // Top corners radius
              />
            </BarChart>
          </ResponsiveContainer>
        </Col>
      </Row>
    </Container>
  );
}

export default SaleStatistics;
