import React, { useEffect } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import "./Services.css";
import { useState } from "react";
import { API, apicaller } from "../../utils/api";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Utility from "../../utils/Utility";
import { useDispatch, useSelector } from "react-redux";
import { clear, getUserToken } from "../../Redux/Slice/UserSlice";
import GlobalButton from "../../components/GlobalButton/GlobalButton";
import SettingsGlobalHeader from "../Settings/components/SettingsGlobalHeader/SettingsGlobalHeader";
import AddServiceModal from "./components/AddServiceModal/AddServiceModal";

export default function Services() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector(getUserToken);
  const [modal, setModal] = useState(false);
  const [servicesData, setServicesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deletingData, setDeletingData] = useState(null);

  useEffect(() => {
    getServicesData();
  }, []);

  const getServicesData = () => {
    apicaller("service", null, "get", token, null)
      .then((res) => {
        setServicesData(res?.data);
      })
      .catch((err) => {
        if (err?.response?.data?.error === "Token Session expired") {
          Utility.eToast(
            "Your token has expired, Please Login again to continue!"
          );
          dispatch(clear());
          navigate("/login");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deletedata = (id) => {
    if (window.confirm("Are you sure to delete your data")) {
      setDeletingData(id);
      var config = {
        method: "delete",
        url: `${API}/service/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      axios(config)
        .then(function (response) {
          getServicesData();
          Utility.sToast("Data Deleted Sucessfully");
          getServicesData();
        })
        .catch(function (err) {
          if (err?.response?.data?.error === "Token Session expired") {
            Utility.eToast(
              "Your token has expired, Please Login again to continue!"
            );
            dispatch(clear());
            navigate("/login");
          } else {
            Utility.eToast("Unable to delete! Please try again. !");
          }
        })
        .finally(() => setDeletingData(null));
    }
  };

  const handleClose = () => {
    setModal(false);
  };

  return (
    <>
      <Container>
        {loading ? (
          <div id="services9">
            <Spinner animation="border" style={{ color: "#1814F3" }} />
          </div>
        ) : (
          <>
            <SettingsGlobalHeader
              headingText="Services"
              showButton={true}
              onClick={() => setModal(true)}
            />
            <AddServiceModal
              show={modal}
              onHide={handleClose}
              getservicesdata={getServicesData}
            />
            {servicesData?.length > 0 &&
              servicesData?.map((list, index) => (
                <Row key={index} id="services4">
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <div>{list?.title}</div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: list?.description,
                      }}
                      id="row20"
                    />
                  </Col>

                  <Row>
                    <Col>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          gap: 10,
                        }}
                      >
                        <GlobalButton
                          title="Edit"
                          width={130}
                          height={46}
                          onclick={() => {
                            navigate("/settings/services-edit", {
                              state: { id: list },
                            });
                          }}
                        />
                        <GlobalButton
                          title={
                            deletingData === list?._id ? "Removing" : "Remove"
                          }
                          width={130}
                          height={46}
                          backgroundColor="transparent"
                          borderStyle="dashed"
                          borderColor="#EF0940"
                          textColor="#EF0940"
                          fontWeight={400}
                          loading={deletingData === list?._id}
                          loadingColor="#EF0940"
                          onclick={() => {
                            deletedata(list?._id);
                            getServicesData();
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Row>
              ))}
          </>
        )}{" "}
      </Container>
    </>
  );
}
