import React, { useEffect, useState } from "react";
import "./Help.css";
import { Col, Container, Row } from "react-bootstrap";
import profile from "../../assets/Profile Picture.png";
import { apicaller } from "../../utils/api";
import { useSelector } from "react-redux";
import { getUserToken } from "../../Redux/Slice/UserSlice";

export default function Help() {
  const [help, setHelp] = useState([]);
  const Token = useSelector(getUserToken);

  useEffect(() => {
    getAllHelpMessage();
  }, []);

  const getAllHelpMessage = () => {
    apicaller(`teacher-message`, null, "get", Token).then((res) => {
      setHelp(res?.data);
      console.log("Help Data is......", res?.data);
    });
  };

  return (
    <>
      <Container>
        <div id="help1">
          <Row>
            <Col md={12} lg={12} sm={12} xs={12}>
              <div id="help2">Faculty Help Center</div>
            </Col>
          </Row>
        </div>
        {help.map((message, index) => (
          <div id="help3">
            <Row>
              <Col key={index} md={4} lg={4} sm={12} xs={12}>
                <div id="help4">
                  {/* <img src={profile} alt="Profile" /> */}
                  {message?.sender?.profile_image ? (
                    <img
                      src={message?.sender?.profile_image}
                      alt="image"
                      id="teacherviewdetails1"
                    />
                  ) : (
                    <div id="teacherveiwdetails7">
                      {message?.sender?.first_name
                        ? message?.sender?.first_name.slice(0, 1)?.toUpperCase()
                        : ""}
                    </div>
                  )}
                </div>
                <div id="help5">{message?.sender?.first_name || "N/A"}</div>
                <div id="help6">{message?.sender?.email || "N/A"}</div>
                <div id="help6">{message?.sender?.phone_number || "N/A"}</div>
              </Col>
              <Col md={8} lg={8} sm={12} xs={12}>
                <div id="help7">Query Type</div>
                <hr></hr>
                <div id="help8">{message?.subject}</div>
                <div id="help9">Description</div>
                <hr></hr>
                <div id="help8">{message?.message}</div>
              </Col>
            </Row>
          </div>
        ))}
      </Container>
    </>
  );
}
