import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import { clear, getUserToken } from "../../Redux/Slice/UserSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { apicaller } from "../../utils/api";
import Utility from "../../utils/Utility";
import GlobalButton from "../../components/GlobalButton/GlobalButton";
import RoundLargeButton from "../../components/RoundLargeButton/RoundLargeButton";
import "./CourseDetails.css";
import ManageBatch from "../../components/ManageBatch/ManageBatch";
import CourseSectionContent from "../../components/CourseSectionContent/CourseSectionContent";
import AssignFaculty from "../../components/AssignFaculty/AssignFaculty";
import CourseRatings from "../../components/CourseRatings/CourseRatings";
import BackButton from "../../components/BackButton";
function CourseDetails() {
  const [spin, setSpin] = useState(true);
  const [isOn, setIsOn] = useState();
  const [isTrending, setIsTrending] = useState();
  const [courseDetails, setCourseDetails] = useState(null);
  const [teacherList, setTeacherList] = useState([]);
  const [classLink, setClassLink] = useState("");
  const [isUpdatingClassLink, setIsUpdatingClassLink] = useState(false);
  const [isteacherListLoading, setIsTeacherListLoading] = useState(true);

  // get all params from url
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  // const course_type = searchParams
  //   .get("category")
  //   ?.replace(/-/g, " ")
  //   ?.toUpperCase();
  // const course_name = searchParams
  //   .get("coursename")
  //   ?.replace(/-/g, " ")
  //   ?.toUpperCase();
  const id = searchParams.get("id");
  const courseId = id;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector(getUserToken);

  useEffect(() => {
    getcourseDetails();
    getAllTeacherList();
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Add smooth scrolling behavior
    });
  }, []);

  const getcourseDetails = async () => {
    try {
      const res = await apicaller(`course/${id}`, null, "get", token);
      console.log(res?.data?.course, "getCourseDetails");
      setIsOn(res?.data?.course?.visiblility === "active" ? true : false);
      setIsTrending(
        res?.data?.course?.trending_status === "active" ? true : false
      );
      setClassLink(res?.data?.course?.class_link);
      setCourseDetails(res?.data?.course);
    } catch (err) {
      console.log(err, "getCourseDetails");
      if (err?.response?.data?.error === "Token Session expired") {
        Utility.eToast(
          "Your token has expired, Please Login again to continue!"
        );
        dispatch(clear());
        navigate("/login");
      }
    }
  };

  const getAllTeacherList = async () => {
    try {
      const res = await apicaller(`admin/teacher-list`, null, "get", token);
      console.log(res?.data?.teacher, "getAllTecherList");
      setTeacherList(res?.data?.teacher);
      setIsTeacherListLoading(false);
      setSpin(false);
    } catch (err) {
      console.log(err, "getCourseDetails");
      if (err?.response?.data?.error === "Token Session expired") {
        Utility.eToast(
          "Your token has expired, Please Login again to continue!"
        );
        dispatch(clear());
        navigate("/login");
      }
    }
  };
  console.log(courseDetails, "courseDetails");
  const handleUpdateTrendingStatus = async (trending) => {
    const data = {
      trending_status: trending,
      _id: courseId,
    };
    console.log("trending", trending);
    await apicaller("course", data, "PUT", token)
      .then(() => {
        Utility.sToast(
          `Course is  ${
            trending === "active" ? "Added to" : "Removed from"
          } Featured Courses`
        );
      })
      .catch((err) => {
        Utility.eToast(
          err?.response?.data?.error || "Oops Something went wrong"
        );
      });
  };
  const handleUpdateCourseVisibility = async (visible) => {
    const data = {
      visiblility: visible,
      _id: courseId,
    };
    console.log("visible", visible);
    await apicaller("course", data, "PUT", token)
      .then(() => {
        Utility.sToast(
          `Course is  ${
            visible === "active" ? "Active" : "Inactive"
          } For All User`
        );
      })
      .catch((err) => {
        Utility.eToast(
          err?.response?.data?.error || "Oops Something went wrong"
        );
      });
  };
  function isValidLink() {
    if (classLink?.length > 0) {
      return true;
    } else {
      Utility.eToast("Please Enter Your Class Link");
      return false;
    }
  }
  const handleUpdateClassLink = async (visible) => {
    if (isValidLink()) {
      setIsUpdatingClassLink(true);
      const data = {
        _id: courseDetails?._id,
        class_link: classLink,
      };
      await apicaller("course", data, "PUT", token)
        .then((res) => {
          Utility.sToast(res?.data?.message);
          setIsUpdatingClassLink(false);
        })
        .catch((err) => {
          Utility.eToast(
            err?.response?.data?.error || "Oops Something went wrong"
          );
          setIsUpdatingClassLink(false);
        });
    }
  };
  return (
    <Container fluid>
      {spin ? (
        <div id="abt9">
          <Spinner animation="border" variant="warning" />
        </div>
      ) : (
        <Container>
          <BackButton />
          <Row>
            <Col>
              {/* HEADER ROW  */}
              <Row id="courseDetHeaderRow">
                <Col>
                  <div id="courseDetHeaderText">Course Details</div>
                </Col>
                <Col id="courseDetHeaderBtnDiv">
                  <div id="courseDetHeaderBtnDiv" style={{ marginRight: 30 }}>
                    <span
                      id="courseDetHeaderText"
                      style={{ fontSize: "medium", marginRight: 10 }}
                    >
                      {/* {isTrending ? "Remove From Featured" : "Add to Featured" } */}
                      Trending Status :{" "}
                    </span>
                    <Toggle
                      checked={isTrending}
                      onChange={() => {
                        setIsTrending(!isTrending);
                        handleUpdateTrendingStatus(
                          isTrending ? "inactive" : "active"
                        );
                      }}
                      icons={false}
                      className="custom-toggle"
                    />
                  </div>
                  <div id="courseDetHeaderBtnDiv">
                    <span
                      id="courseDetHeaderText"
                      style={{ fontSize: "medium", marginRight: 10 }}
                    >
                      Visibility :{" "}
                    </span>
                    <Toggle
                      checked={isOn}
                      onChange={() => {
                        setIsOn(!isOn);
                        handleUpdateCourseVisibility(
                          isOn ? "inactive" : "active"
                        );
                      }}
                      icons={false}
                      className="custom-toggle"
                    />
                  </div>
                </Col>
              </Row>
              <div id="courseBottomRow">
                <Row>
                  <Col md={8} xs={8}>
                    <div id="courseNameText">{courseDetails?.name || "NA"}</div>
                    <div id="courseNameText" style={{ fontSize: "medium", marginBottom: 10 }}>
                      Center : {courseDetails?.centerId?.center_name &&
                      courseDetails?.centerId?.district_id
                        ? `${courseDetails?.centerId?.center_name}, ${courseDetails?.centerId?.district_id?.district_name}, ${courseDetails?.centerId?.district_id?.states}`
                        : "No Center Assigned"}
                    </div>
                    <div id="courseDescriptionText">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: courseDetails?.description || "",
                        }}
                      />
                    </div>
                  </Col>
                  <Col id="courseImgCol" xs={4} md={4}>
                    <img
                      src={courseDetails?.img}
                      alt="courseImage"
                      id="courseImg"
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Form.Label id="classLinkLabel">Edit class link</Form.Label>
                  <Col md={5} xs={8}>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Enter Your Class Link"
                        id="classLinkForm"
                        value={classLink}
                        onChange={(e) => {
                          setClassLink(e.target.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={4} md={7}>
                    <GlobalButton
                      title="Save"
                      width={130}
                      height="2.3rem"
                      loading={isUpdatingClassLink}
                      onclick={() => {
                        handleUpdateClassLink();
                      }}
                    />
                  </Col>
                </Row>

                {/* Manage Batch meet link  */}
                {courseDetails?.slot?.length > 0 && (
                  <>
                    <div id="batchLabel">Course Batches</div>
                    <div id="showCourseBatchRow">
                      {courseDetails?.slot?.map((item) => (
                        <ManageBatch item={item} />
                      ))}
                    </div>
                  </>
                )}

                {/* Assign Faculty & Add Batch Row */}
                <Row className="mt-4">
                  <AssignFaculty
                    courseDetails={courseDetails}
                    token={token}
                    teacherList={teacherList}
                    fetchSingleCourseApi={() => {
                      getcourseDetails();
                    }}
                  />
                  <Col md={3}>
                    <div id="addSlotDiv">
                      <div id="addSlotText">Add a batch</div>
                      <RoundLargeButton
                        icon="+"
                        name="Create"
                        backgroundColor="transparent"
                        event={() => {
                          navigate("/addslotpage", {
                            state: {
                              item: courseDetails?.slot,
                              res: courseDetails,
                            },
                          });
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </div>

              {/* Course Section Component  */}
              <Row id="borderLine" />
              <div id="courseBottomRow">
                <CourseSectionContent
                  courseDetails={courseDetails}
                  fetchSingleCourseApi={() => {
                    getcourseDetails();
                  }}
                />
              </div>
            </Col>
          </Row>

          {/* Ratings & Review Section  */}
          <div id="ratingContainer">
            {courseDetails?.reviews?.length > 0 && (
              <Row id="ratingRow">
                <Col>
                  <CourseRatings courseDetails={courseDetails} />
                </Col>
              </Row>
            )}
          </div>
        </Container>
      )}
    </Container>
  );
}

export default CourseDetails;
