import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { IoDocumentText } from "react-icons/io5";
import "./ClassNotes.css";

export default function ClassNotes({ selectedChapter }) {
  return (
    <Col id="classNotesContainer">
      <div id="labelText">Class Notes</div>
      <Row id="underLine" />
      <Row style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
        {selectedChapter?.document_name ? (
          <>
            <Col>
              <IoDocumentText color="#0d41a9" />
              {selectedChapter?.document_name}
            </Col>
            <Col id="downloadCol">
              <div id="downloadText">
                <a href={selectedChapter?.document}>Download</a>
              </div>
            </Col>
          </>
        ) : (
          <div id="noMaterialAvailable">No Materials Available</div>
        )}
      </Row>
    </Col>
  );
}
