import React, { useEffect, useState } from "react";
import "./SettingsBanner.css";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import DummyBanner from "../../../../assets/dummyBanner.png";
import GlobalButton from "../../../../components/GlobalButton/GlobalButton";
import SettingsGlobalHeader from "../SettingsGlobalHeader/SettingsGlobalHeader";
import { clear, getUserToken } from "../../../../Redux/Slice/UserSlice";
import { useNavigate } from "react-router-dom";
import Utility from "../../../../utils/Utility";
import { useDispatch, useSelector } from "react-redux";
import { apicaller } from "../../../../utils/api";
import AddBannerModal from "../../../../components/AddBannerModal/AddBannerModal";

export default function SettingsBanner() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [banner, setBanner] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deletingBanner, setDeletingBanner] = useState(null);
  const [modalShow, setModalShow] = React.useState(false);
  const token = useSelector(getUserToken);

  useEffect(() => {
    getBanner();
  }, []);

  const getBanner = () => {
    apicaller("get-advertisement", null, "get", null)
      .then((res) => {
        console.log("banner res", res?.data);
        setBanner(res?.data?.ads);
        setLoading(false);
      })
      .catch((e) => {
        if (e?.response?.data?.error === "Token Session expired") {
          Utility.eToast(
            "Your token has expired, Please Login again to continue!"
          );
          setLoading(false);
          dispatch(clear());
          navigate("/login");
        }
      });
  };

  // REMOVE BANNER FUNTION
  const handleRemoveBanner = (id) => {
    if (window.confirm("Are you sure, you want to delete ?")) {
      setDeletingBanner(id);
      apicaller(`delete-advertisement/${id}`, null, "delete", token, null)
        .then((res) => {
          Utility.sToast("Banner deleted Successfully");
          getBanner();
        })
        .catch((e) => {
          if (e?.response?.data?.error === "Token Session expired") {
            Utility.eToast(
              "Your token has expired, Please Login again to continue!"
            );
            setDeletingBanner(null);
            dispatch(clear());
            navigate("/login");
          }
        })
        .finally(() => {
          setDeletingBanner(null);
        });
    }
  };

  return (
    <Container fluid>
      {/* Common Header Component  */}
      <SettingsGlobalHeader
        headingText="Banners"
        dataLength={banner?.length || 0}
        showButton={true}
        onClick={() => setModalShow(true)}
      />
      <AddBannerModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        getbanner={getBanner}
      />

      {loading ? (
        <div id="loadingBanner">
          <Spinner animation="border" style={{ color: "#1814F3" }} />
        </div>
      ) : (
        banner?.length > 0 &&
        banner?.map((item, i) => (
          <div id="cardMainView" key={i}>
            <Row>
              <Col md={3}>
                <img src={item?.url} alt="bannerImage" id="bannerImg" />
              </Col>
              <Col>
                <div>
                  <div id="bannerHeaderText">{item?.title}</div>
                  <div id="bannerDescpText">{item?.description}</div>
                </div>
              </Col>
              <Col id="buttonMainDiv">
                <GlobalButton
                  title={deletingBanner === item?._id ? "Removing" : "Remove"}
                  width={130}
                  height={46}
                  backgroundColor="transparent"
                  borderStyle="dashed"
                  borderColor="#EF0940"
                  textColor="#EF0940"
                  fontWeight={400}
                  loading={deletingBanner === item?._id}
                  loadingColor="#EF0940"
                  onclick={() => {
                    handleRemoveBanner(item?._id);
                  }}
                />

                {/* <div id="dotted-border" onClick={() => handleRemoveBanner(item?._id)}>
            Remove
          </div> */}
              </Col>
            </Row>
          </div>
        ))
      )}
    </Container>
  );
}
