import React from "react";
import "./BatchCard.css";
import { Col, Container, Row } from "react-bootstrap";
function BatchCard(props) {
  return (
    <>
      <Container fluid id="bk1">
        <Row>
          <Col id="bk4">
            <Row id="bk2"> Course Start Date : {props.startdate} </Row>
            <Row id="bk2"> Course End Date : {props.enddate} </Row>
            <Row style={{ gap: "5px" }}>
              {" "}
              <Col id="bk3">
                {props.starttime} - {props.endtime}{" "}
              </Col>
              <Col id="bk3">
                {props.starttime} - {props.endtime}{" "}
              </Col>{" "}
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default BatchCard;
