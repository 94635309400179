import React, { useState } from "react";
import "./AddSlotPage.css";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";

import axios from "axios";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { API, apicaller } from "../../utils/api";
import Utility from "../../utils/Utility";
import Loader from "../../components/Loader/Loader";

function AddSlotPage(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { userid, token } = useSelector((state) => state?.user);

  const item = location.state.item;
  const res = location.state.res;
  const img = res?.img;
  const courseid = res?._id;
  const courseBatchSlot = item;

  const [addSlotArray, setAddSlotArray] = useState(
    courseBatchSlot?.map((item) => item?._id)
  );

  const [startHour, setStartHour] = useState("");
  const [startMinute, setStartMinute] = useState("");
  const [startAm, setStartAm] = useState("AM");
  const [endHour, setEndHour] = useState("");
  const [endMinute, setEndMinute] = useState("");
  const [endAm, setEndAm] = useState("AM");
  const [slotArray, setSlotArray] = useState([]);
  const [courseStartDate, setCourseStartDate] = useState(
    `${new Date().getFullYear()}-${
      parseInt(new Date().getMonth()) + 1 <= 9
        ? `0${parseInt(new Date().getMonth()) + 1}`
        : parseInt(new Date().getMonth()) + 1
    }-${new Date().getDate()}`
  );
  const [courseEndDate, setCourseEndDate] = useState(
    `${new Date().getFullYear()}-${
      parseInt(new Date().getMonth()) + 1 <= 9
        ? `0${parseInt(new Date().getMonth()) + 1}`
        : parseInt(new Date().getMonth()) + 1
    }-${new Date().getDate()}`
  );
  const [addBtnLoader, setAddBtnLoader] = useState(false);

  function validation() {
    if (slotArray?.length === 0) {
      Utility.eToast("Please AddSlot Timing");
      return false;
    } else {
      return true;
    }
  }

  const addSlotFunction = async () => {
    console.log(
      "getting validation result",
      startHour === endHour && startMinute === endMinute && startAm === endAm
    );
    if (startHour === "") {
      Utility.eToast("Please Fill Start Hours");
      return false;
    } else if (startMinute === "") {
      Utility.eToast("Please Fill Start Minutes");
      return false;
    } else if (endHour === "") {
      Utility.eToast("Please Fill End Hours");
      return false;
    } else if (endMinute === "") {
      Utility.eToast("Please Fill End Minutes");
      return false;
    } else if (
      parseInt(startHour) <= 0 ||
      parseInt(startHour) > 12 ||
      parseInt(endHour) <= 0 ||
      parseInt(endHour) > 12 ||
      parseInt(startMinute) < 0 ||
      parseInt(startMinute) > 59 ||
      parseInt(endMinute) < 0 ||
      parseInt(endMinute) > 59
    ) {
      Utility.eToast("Please Check The Added Slot");
      return false;
    } else if (
      startHour === endHour &&
      startMinute === endMinute &&
      startAm === endAm
    ) {
      Utility.eToast("Please Enter a valid slot");
      return false;
    } else {
      const startSlot = `${startHour}:${startMinute}:${startAm}`;
      const endSlot = `${endHour}:${endMinute}:${endAm}`;
      const final = `${startSlot}-${endSlot}`;
      setSlotArray([...slotArray, final]);
      setStartHour("");
      setEndHour("");
      setStartMinute("");
      setEndMinute("");
    }
  };

  const removeSlotFunction = (i) => {
    const updatedSlotArray = [...slotArray];
    updatedSlotArray.splice(i, 1);
    setSlotArray(updatedSlotArray);
  };

  // console.log('slot array iss..', slotArray);

  const addSlot = () => {
    if (validation()) {
      //   setPostLoader(true);
      setAddBtnLoader(true);
      const data = {
        start_date: `${courseStartDate.slice(0, 4)}-${courseStartDate.slice(
          5,
          7
        )}-${courseStartDate.slice(8, 10)}`,
        end_date: `${courseEndDate.slice(0, 4)}-${courseEndDate.slice(
          5,
          7
        )}-${courseEndDate.slice(8, 10)}`,
        time: slotArray,
      };
      console.log("add slot data going..", data);

      apicaller("slot", data, "post", token)
        .then((res) => {
          addSlotArray?.splice(0, 0, res?.data?.module?._id);

          const finalArray = addSlotArray?.map((item) => item);

          console.log("final array after add new slot...", finalArray);
          setTimeout(() => {
            setAddSlotArray(finalArray);
          }, 1000);
          setTimeout(() => {
            AddNewCourseFunction(finalArray);
            setAddBtnLoader(false);
          }, 2000);
        })
        .catch((error) => {
          setAddBtnLoader(false);
          if (error?.response?.data?.error === "token Session expired") {
            Utility.eToast("Session Expired !");
          } else {
            Utility.eToast(error?.response?.data?.error || error?.message);
          }
        });
    }
  };

  const AddNewCourseFunction = (id) => {
    // console.log("slot id.......", id);
    var data = {
      img: img,
      createdBy: userid,
      slot: id,
      _id: courseid,
    };
    // c
    console.log("data going of new array..", data);
    var config = {
      method: "put",
      url: `${API}course`,
      headers: {
        authorization: "Bearer " + token,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (
          response.status === 200 ||
          response.status === 204 ||
          response.status === 201
        ) {
          Utility.sToast("New Batch Added Successfully");
          setSlotArray([]);
          setStartHour("");
          setStartMinute();
          setStartAm("AM");
          setEndHour();
          setEndMinute();
          setEndAm("AM");
          navigate(-1);
        }
      })
      .catch(function (error) {
        Utility.eToast("Sorry Could Not Added Due to Internal Error");
      })
      .finally(() => {
        // setPostLoader(false);
        setAddBtnLoader(false);
      });
  };

  return (
    <>
      <Container>
        <Row id="addslot1">
          <Col>
            <Row>
              <Col id="addslot2"> Add Slot</Col>
            </Row>

            <Row style={{ marginTop: "1rem" }}>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Select Course Start Date</Form.Label>
                  <Form.Control
                    // disabled={slotArray?.length > 0 ? true : false}
                    type="date"
                    value={courseStartDate}
                    onChange={(e) => setCourseStartDate(e.target.value)}
                    id="addcoursemodal1"
                    min={new Date().toISOString().split("T")[0]}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Enter Course End Date</Form.Label>
                  <Form.Control
                    type="date"
                    // disabled={slotArray?.length > 0 ? true : false}
                    value={courseEndDate}
                    onChange={(e) => setCourseEndDate(e.target.value)}
                    id="addcoursemodal1"
                    min={new Date().toISOString().split("T")[0]}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              {" "}
              <Col id="addslot4"> Course start and end timing </Col>
            </Row>
            <Row>
              <Col md={2} lg={2} sm={4} xs={4} id="addslot9">
                <Form.Control
                  type="text"
                  placeholder="Start Hours"
                  id="addslotmodal7"
                  value={startHour}
                  onChange={(e) => {
                    const input = e.target.value;
                    if (!isNaN(input) && input.length <= 2) {
                      setStartHour(input);
                    }
                  }}
                />
              </Col>
              <Col md={2} lg={2} sm={4} xs={4} id="addslot9">
                <Form.Control
                  type="text"
                  placeholder="Minutes"
                  value={startMinute}
                  onChange={(e) => {
                    const input = e.target.value;
                    if (!isNaN(input) && input.length <= 2) {
                      // Check if input is a number and has at most 2 characters
                      setStartMinute(input);
                    }
                  }}
                  id="addslotmodal7"
                />
              </Col>
              <Col md={2} lg={2} sm={4} xs={4} id="addslot9">
                <Form.Select
                  id="addslotmodal7"
                  value={startAm}
                  onChange={(e) => setStartAm(e.target.value)}
                >
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </Form.Select>
              </Col>
              <Col md={2} lg={2} sm={4} xs={4} id="addslot9">
                {" "}
                <Form.Control
                  type="text"
                  placeholder="End Hours"
                  value={endHour}
                  onChange={(e) => {
                    const input = e.target.value;
                    if (!isNaN(input) && input.length <= 2) {
                      // Check if input is a number and has at most 2 characters
                      setEndHour(input);
                    }
                  }}
                  id="addslotmodal7"
                />
              </Col>
              <Col md={2} lg={2} sm={4} xs={4} id="addslot9">
                <Form.Control
                  type="text"
                  placeholder="Minutes"
                  value={endMinute}
                  onChange={(e) => {
                    const input = e.target.value;
                    if (!isNaN(input) && input.length <= 2) {
                      // Check if input is a number and has at most 2 characters
                      setEndMinute(input);
                    }
                  }}
                  id="addslotmodal7"
                />
              </Col>

              <Col md={2} lg={2} sm={4} xs={4} id="addslot9">
                <Form.Select
                  id="addslotmodal7"
                  value={endAm}
                  onChange={(e) => setEndAm(e.target.value)}
                >
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </Form.Select>
              </Col>
            </Row>
            <Row>
              <Col md={10}></Col>
              <Col md={2}>
                {" "}
                <Button onClick={() => addSlotFunction()} id="addslot5">
                  Add Slot
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row id="addslot1">
          <div id="addslot11"> Slots added : </div>
          {slotArray?.map((item, i) => (
            <Col md={3} className="mt-2 mb-2">
              <div id="addslot10">
                <span id="editslot10"> {item} </span>
                <div id="addslot6">
                  <RxCross2 onClick={() => removeSlotFunction(i)} size={23} />{" "}
                </div>
              </div>
            </Col>
          ))}
        </Row>

        <Row></Row>
      </Container>
      <Container>
        <Row>
          <Col md={8}></Col>
          <Col md={2}>
            {" "}
            <Button
              id="addslot7"
              className="mt-5"
              onClick={() => navigate(-1)}
              variant="danger"
              style={{ width: "100%" }}
            >
              Cancel
            </Button>
          </Col>
          <Col md={2}>
            <Button
              id="addslot8"
              className="mt-5"
              onClick={addSlot}
              variant="dark"
              style={{ width: "100%" }}
            >
              {addBtnLoader ? <Loader animation="border" size="sm" /> : "Add"}
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default React.memo(AddSlotPage);
