import React, { useEffect, useState } from "react";
import Dashboard from "./pages/Dashboard/Dashboard";
import Login from "./pages/Login/Login";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { getUserToken } from "./Redux/Slice/UserSlice";
import PageNotFound from "./components/PageNotFound/PageNotFound";
import sadEmoji from "./assets/mobileDevice/animation_lk9d4w9a_small.gif";

// all routes
import AuthRoute from "./components/AuthRoute";
import ProtectedRoute from "./components/ProtectedRoute";
import ManageSubadmin from "./pages/ManageSubadmin/ManageSubadmin";
import PrivacyPolicyEdit from "./pages/Privacy/components/PrivacyPolicyEdit/PrivacyPolicyEdit";
import CourseDetails from "./pages/CourseDetails/CourseDetails";
import TeacherDetails from "./pages/TeacherDetails/TeacherDetails";
import TeacherViewDetails from "./pages/TeacherViewDetails/TeacherViewDetails";
import CourseDetailTeacher from "./pages/CourseDetailTeacher/CourseDetailTeacher";
import AllUser from "./pages/AllUser/AllUser";
import StudentCourses from "./pages/StudentCourses/StudentCourses";
import TermsEdit from "./pages/TermsCondition/components/TermsEdit/TermsEdit";
import RefundPolicyEdit from "./pages/RefundPolicy/components/RefundPolicyEdit/RefundPolicyEdit";
import "./App.css";
import AddSlotPage from "./pages/AddSlotPage/AddSlotPage";
import AboutEdit from "./pages/Aboutus/components/AboutUsEdit/AboutEdit";
import Sidebar from "./pages/Sidebar/Sidebar";
import { Col, Container, Row } from "react-bootstrap";
import Settings from "./pages/Settings/Settings";
import NewCourse from "./pages/NewCourse/NewCourse";
import Finance from "./pages/Finance/Finance";
import ServiceEdit from "./pages/Services/components/ServiceEdit/ServiceEdit";
import Courseadmindetails from "./pages/Courseadmindetails/Courseadmindetails";
import Center from "./pages/Center/Center";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import EditSlotPage from "./components/EditSlotPage/EditSlotPage";
import Help from "./pages/Help/Help";
import Centerdetails from "./pages/Centerdetails/Centerdetails";

export default function App() {
  const token = useSelector(getUserToken);
  const location = useLocation();

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {!isMobile && (
        <Container fluid>
          <Row>
            {/* Render the Sidebar if the user is logged in */}
            {token && (
              <Col md={2} style={{ padding: "0px" }}>
                <Sidebar />
              </Col>
            )}
            <Col
              md={token ? 10 : 12}
              style={{
                maxHeight: "100vh",
                overflowY: "auto",
                padding:
                  location.pathname === "/login" ||
                  location.pathname === "/reset-password"
                    ? "0"
                    : "1rem",
              }}
            >
              <ToastContainer />
              <Routes>
                {/* routes before login */}
                <Route
                  path="/login"
                  element={
                    <AuthRoute>
                      <Login />
                    </AuthRoute>
                  }
                />
                <Route
                  path="/reset-password"
                  element={
                    <AuthRoute>
                      <ResetPassword />
                    </AuthRoute>
                  }
                />

                {/* routes after login */}
                <Route element={<ProtectedRoute />}>
                  {/* dashboard */}
                  <Route path="/" element={<Dashboard />} />

                  {/* student links */}
                  <Route path="/students" element={<AllUser />} />
                  <Route
                    path="/students/details"
                    element={<StudentCourses />}
                  />

                  {/* faculty links */}
                  <Route path="/faculty" element={<TeacherDetails />} />
                  <Route
                    path="/faculty/faculty-details"
                    element={<TeacherViewDetails />}
                  />
                  {/* center links */}
                  <Route path="/center" element={<Center />} />

                  {/* course admin links */}
                  <Route path="/academic-admins" element={<ManageSubadmin />} />
                  <Route
                    path="/academic-admins/academic-admins-details"
                    element={<Courseadmindetails />}
                  />

                  {/* finance */}
                  <Route path="/finance" element={<Finance />} />

                  {/* courses */}
                  <Route path="/courses" element={<NewCourse />} />
                  <Route
                    path="/courses/coursedetails"
                    element={<CourseDetails />}
                  />
                  <Route path="/addslotpage" element={<AddSlotPage />} />
                  <Route path="/editSlotPage" element={<EditSlotPage />} />

                  {/* settings */}
                  <Route path="/settings" element={<Settings />} />
                  <Route
                    path="/settings/privacy-policy-edit"
                    element={<PrivacyPolicyEdit />}
                  />
                  <Route
                    path="/settings/services-edit"
                    element={<ServiceEdit />}
                  />
                  <Route
                    path="/settings/edit-refund-policy"
                    element={<RefundPolicyEdit />}
                  />
                  <Route path="/settings/terms-Edit" element={<TermsEdit />} />
                  <Route
                    path="/settings/aboutus-edit"
                    element={<AboutEdit />}
                  />

                  <Route
                    path="/coursedetailteacher"
                    element={<CourseDetailTeacher />}
                  />
                  <Route
                    path="/centerdetails/:id"
                    element={<Centerdetails />}
                  />
                  <Route
                    path="/help"
                    element={<Help />}
                  />
                  {/* <Route path="/orderdetail" element={<OrderDetail />} /> */}
                </Route>
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </Col>
          </Row>
        </Container>
      )}

      {/* if device is mobile */}
      {isMobile && (
        <div
          style={{ textAlign: "center", padding: "20px", marginTop: "12rem" }}
        >
          <img src={sadEmoji} alt="img" />
          <h3 style={{ marginTop: "1rem", fontWeight: "400" }}>
            Please Access Our Website on Laptop/Desktop.
          </h3>
        </div>
      )}
    </>
  );
}
