import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Utility from "../../../utils/Utility";
import GlobalButton from "../../GlobalButton/GlobalButton";
import { clear } from "@testing-library/user-event/dist/clear";
import { apicaller } from "../../../utils/api";

function EditSection(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sectionnamesectionid = {}, getcoursesectionlist } = props;
  const { token } = useSelector((state) => state?.user);

  const sectionId = sectionnamesectionid?.sectionId || "";
  const previousSectionName = sectionnamesectionid?.sectionName || "";

  const [isUpdatingSection, setIsUpdatingSection] = useState(false);
  const [sectionName, setSectionName] = useState(previousSectionName);

  function isValidData() {
    if (sectionName === "") {
      Utility.eToast("Please Make Changes To Update");
      return false;
    }
    return true;
  }

  const handleUpdateSection = async () => {
    if (isValidData()) {
      setIsUpdatingSection(true);
      const data = {
        _id: sectionId,
        title: sectionName || previousSectionName,
      };
       await apicaller(`update-section/${sectionId}`, data, "PUT", token)
        .then((res) => {
          console.log("update section modal res", res);
          getcoursesectionlist();
          setSectionName("");
          props.HideModal();
          setIsUpdatingSection(false);
        })
        .catch((err) => {
          if (err?.response?.data?.error === "Token Session expired") {
            Utility.eToast("Token expired, Please login again ! ");
            props.HideModal();
            dispatch(clear());
            navigate("/");
          } else {
            Utility.eToast(
              err?.response?.data?.error || "Oops Something went wrong"
            );
          }
          setIsUpdatingSection(false);
        });
    }
  };
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Update Section
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col md={12}>
              <Form.Group className="mb-3">
                <Form.Label id="addfaculty2">Enter Section Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Section Name"
                  defaultValue={previousSectionName}
                  onChange={(e) => setSectionName(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <GlobalButton
          title="Close"
          width={130}
          height="2.3rem"
          backgroundColor="red"
          marginRight="1rem"
          disabled={isUpdatingSection}
          onclick={() => {
            setSectionName("");
            props.HideModal();
          }}
        />
        <GlobalButton
          title="Update"
          width={130}
          height="2.3rem"
          loading={isUpdatingSection}
          onclick={() => {
            handleUpdateSection();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default EditSection;
