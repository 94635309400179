import React, { useState } from "react";
import "./CourseSectionContent.css";
import { Col, Container, Form, Row } from "react-bootstrap";
import { FaPlus } from "react-icons/fa6";
import { MdRemoveRedEye } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { IoEyeOffSharp } from "react-icons/io5";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import StudentEnrolled from "./components/StudentEnrolled/StudentEnrolled";
import ExamData from "./components/ExamData/ExamData";
import Assignment from "./components/Assignment/Assignment";
import { Collapse } from "react-collapse";
import useCourseSection from "./hooks/useCourseSection";
import { COLUMNS } from "../../constants/tablecolumn";
import GlobalButton from "../GlobalButton/GlobalButton";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import VideoPlayerComp from "../VideoPlayerComp/VideoPlayerComp";
import AddChapter from "./modals/AddChapter";
import EditSection from "./modals/EditSection";
import EditChapter from "./modals/EditChapter";
import ClassNotes from "./components/ClassNotes/ClassNotes";
import { RiDeleteBinFill } from "react-icons/ri";

export default function CourseSectionContent({ courseDetails = {} }) {
  const {
    value,
    isApproving,
    sectionName,
    courseSectionList,
    showAddChapterModal,
    courseAndSectionId,
    selectedSectionId,
    sectionChapter,
    showEditSectionModal,
    sectionDataToModal,
    allAssignmentList = [],
    allExamList = [],
    studentEnrolledList = [],
    showEditChapterModal,
    showConfirmationModal,
    selectedChapter,
    setValue,
    setSectionName,
    setEditChapterData,
    setSelectedSectionId,
    setShowAddChapterModal,
    setShowEditSectionModal,
    setShowEditChapterModal,
    setShowConfirmationModal,
    setSelectedChapter,
    handleAddCourseSection,
    getCourseSectionList,
    getCourseChapterBySection,
    isCourseSectionLoading,
    handleAddCourseChapter,
    handleEditSectionName,
    handleApproveExam,
    handleUpdateChapterActive,
    handleUpdateChapterInactive,
    handleDeleteChapter,
    setIsConfirmedDeleteChapter,
    isConfirmedDeleteChapter,
    isDeletingChapter,
    setSelectedChapterId,
    selectedChapterId,
  } = useCourseSection();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  console.log("selected chapter", selectedChapter);
  console.log(sectionChapter, "sectionChapter");

  const RenderCourseSection = ({ item, i }) => {
    return (
      <div id="courseSectionsContainer" key={i}>
        <Row>
          <Col
            onClick={() => {
              if (item?._id === selectedSectionId) {
                setSelectedSectionId(null);
              } else {
                getCourseChapterBySection(item?._id);
              }
            }}
            md={8}
          >
            {item?.title}
          </Col>
          <Col id="sectionIconsCol">
            <div
              id="sectionIcons"
              onClick={() => {
                handleAddCourseChapter(item?._id);
              }}
            >
              <FaPlus size={21} />
            </div>{" "}
            <div id="sectionIcons">{/* <MdRemoveRedEye size={21} /> */}</div>
            <div
              id="sectionIcons"
              onClick={() => handleEditSectionName(item?._id, item?.title)}
            >
              <FaRegEdit size={21} />
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  const RenderCourseChapter = ({ item, i }) => {
    return (
      <div id="courseChapterContainer" key={i}>
        <Row>
          <Col md={8} id="chapterText" onClick={() => setSelectedChapter(item)}>
            {i + 1}. {item?.title}
          </Col>
          <Col id="sectionIconsCol">
            <div id="sectionIcons">
              {item?.visiblility === "active" ? (
                <MdRemoveRedEye
                  size={21}
                  onClick={() => handleUpdateChapterInactive(item?._id)}
                />
              ) : (
                <IoEyeOffSharp
                  size={21}
                  onClick={() => handleUpdateChapterActive(item?._id)}
                />
              )}
            </div>{" "}
            <div
              id="sectionIcons"
              onClick={() => {
                setSelectedChapter(item);
                setShowEditChapterModal(true);
              }}
            >
              <FaRegEdit size={21} />
            </div>
            <div
              id="sectionIcons"
              onClick={() => {
                setSelectedChapterId(item?._id);
                setIsConfirmedDeleteChapter(true);
              }}
            >
              <RiDeleteBinFill size={21} color="red" />
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  const EmptyDataMessage = ({ message, height }) => {
    return (
      <Row>
        <Col></Col>
        <Col
          id="noSectionAddedText"
          style={
            height && {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: height,
            }
          }
        >
          <div>{message || "NA"}</div>
        </Col>
        <Col></Col>
      </Row>
    );
  };

  return (
    <>
      <Row className="mt-4">
        <Form.Label id="sectionNameLabel">Add section name</Form.Label>
        <Col md={10} xs={8}>
          <Form.Group className="mb-3">
            <Form.Control
              type="text"
              placeholder="Enter Course Section Name"
              id="sectionNameInput"
              value={sectionName}
              onChange={(e) => setSectionName(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col md={2} xs={4}>
          <GlobalButton
            title="Add"
            height="2.3rem"
            loading={isCourseSectionLoading}
            onclick={() => handleAddCourseSection()}
          />
        </Col>
      </Row>
      <Container>
        <Row className="mt-3">
          <Col id="courseContentContainer" md={8}>
            <div>
              <Row id="courseContentLabel">Course Content</Row>
              {courseSectionList.length === 0 ? (
                <EmptyDataMessage message="No Content Added" height="200px" />
              ) : (
                <>
                  {courseSectionList?.map((item, i) => (
                    <div style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                      <RenderCourseSection item={item} i={i} />
                      <Collapse isOpened={selectedSectionId === item._id}>
                        {sectionChapter.length === 0 ? (
                          <EmptyDataMessage message="No Chapter Added" />
                        ) : (
                          <>
                            {sectionChapter?.map((item, i) => (
                              <RenderCourseChapter item={item} i={i} />
                            ))}
                          </>
                        )}
                      </Collapse>
                    </div>
                  ))}
                </>
              )}
            </div>
          </Col>
          <Col>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {selectedChapter ? (
                <VideoPlayerComp url={selectedChapter?.video_path} />
              ) : (
                <img
                  src={courseDetails?.img}
                  alt="courseImage"
                  id="thumbnail"
                />
              )}
            </div>
            <Row>
              <ClassNotes selectedChapter={selectedChapter} />
            </Row>
          </Col>
        </Row>
        <ConfirmationModal
          onHide={() => setIsConfirmedDeleteChapter(false)}
          show={isConfirmedDeleteChapter}
          onYes={() => handleDeleteChapter(selectedChapterId)}
          header="Delete Chapter"
          title="Are you sure you want to delete the chapter?"
          isApproving={isDeletingChapter}
        />
        <AddChapter
          show={showAddChapterModal}
          courseAndSectionid={courseAndSectionId}
          getcoursesectionlist={() =>
            getCourseChapterBySection(selectedSectionId)
          }
          onHide={() => {
            setShowAddChapterModal(false);
          }}
        />
        <EditSection
          show={showEditSectionModal}
          sectionnamesectionid={sectionDataToModal}
          getcoursesectionlist={() => getCourseSectionList()}
          HideModal={() => {
            setShowEditSectionModal(false);
          }}
        />
        <EditChapter
          show={showEditChapterModal}
          HideModal={() => setShowEditChapterModal(false)}
          getcoursesectionlist={() =>
            getCourseChapterBySection(selectedSectionId)
          }
          selectedChapter={selectedChapter}
        />
      </Container>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              variant="scrollable"
              scrollButtons="auto"
              TabScrollButtonProps={{
                sx: {
                  color: "#1814F3",
                },
              }}
              TabIndicatorProps={{
                sx: {
                  backgroundColor: "#1814F3",
                  height: 4,
                  borderRadius: "20px 20px 0px 0px",
                },
              }}
            >
              <Tab
                label="Student enrolled"
                value="studentEnrolled"
                style={{
                  color: "#718EBF",
                  fontFamily: "IBM Plex Sans",
                  fontSize: 14,
                }}
              />
              <Tab
                label="Assignment"
                value="assignment"
                style={{
                  color: "#718EBF",
                  fontFamily: "IBM Plex Sans",
                  fontSize: 14,
                }}
              />
              <Tab
                label="Exam"
                value="examdata"
                style={{
                  color: "#718EBF",
                  fontFamily: "IBM Plex Sans",
                  fontSize: 14,
                }}
              />
            </TabList>
          </Box>
          {/* body */}
          <TabPanel value="studentEnrolled">
            <StudentEnrolled
              columns={COLUMNS.entrolledStudent}
              data={studentEnrolledList}
            />
          </TabPanel>
          <TabPanel value="assignment">
            <Assignment
              columns={COLUMNS.allAssignment}
              data={allAssignmentList}
            />
          </TabPanel>
          <TabPanel value="examdata">
            <ExamData columns={COLUMNS.allExams} data={allExamList} />
            <ConfirmationModal
              onHide={() => setShowConfirmationModal(false)}
              show={showConfirmationModal}
              onYes={() => handleApproveExam()}
              header="Exam Approval"
              title="Are you sure you want to approve this exam?"
              isLoading={isApproving}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
}
