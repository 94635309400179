import React from "react";
import TopratedCourseCard from "../TopratedCourseCard/TopratedCourseCard";
import { Col, Container, Row, Spinner } from "react-bootstrap";

function StudentLearnings({ studentDetails }) {
  // Dummy data
  const dummyData = [
    {
      title: "Course 1",
      author: "Author 1",
      courseImage: "image1.jpg",
      price: "$20",
      visibility: "public",
    },
    {
      title: "Course 2",
      author: "Author 2",
      courseImage: "image2.jpg",
      price: "$25",
      visibility: "private",
    },
    {
      title: "Course 3",
      author: "Author 3",
      courseImage: "image3.jpg",
      price: "$30",
      visibility: "public",
    },
    {
      title: "Course 4",
      author: "Author 4",
      courseImage: "image4.jpg",
      price: "$35",
      visibility: "private",
    },
  ];
  const data = studentDetails?.subscriptions;

  return (
    <Container>
      <Row>
        {data.map((item, index) => (
          <Col key={index} xs={12} sm={12} md={3} lg={3} xl={3} xxl={3}>
            <TopratedCourseCard
              item={item}
              // title={item?.course?.name}
              // author={item?.course?.name}
              // courseImage={item?.course?.img}
              // price={item?.course?.price}
              // visibility={item?.course?.visiblility}
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default StudentLearnings;
