const entrolledStudent = [
  {
    accessorKey: "studentid",
    header: "Student ID",
  },
  {
    accessorKey: "name",
    header: "Name",
  },
  {
    accessorKey: "email",
    header: "Email",
  },
  {
    accessorKey: "contact",
    header: "Contact",
  },
];

const allAssignment = [
  {
    accessorKey: "slno",
    header: "Sl No",
  },
  {
    accessorKey: "topic",
    header: "Topic",
  },
  {
    accessorKey: "date",
    header: "Date",
  },
  {
    accessorKey: "student",
    header: "Assigned Students",
  },
  {
    accessorKey: "assignmentdoc",
    header: "Assignment Document",
  },
];

const allExams = [
  {
    accessorKey: "slno",
    header: "Sl No",
  },
  {
    accessorKey: "examtopic",
    header: "Exam Topic",
  },
  {
    accessorKey: "date",
    header: "Date",
  },
  {
    accessorKey: "startime",
    header: "Start Time",
  },
  {
    accessorKey: "endtime",
    header: "End Time",
  },
  {
    accessorKey: "slotcapacity",
    header: "Slot Capacity",
  },
  {
    accessorKey: "examlink",
    header: "Exam Link",
  },
  {
    accessorKey: "examstatus",
    header: "Exam Status",
  },
  {
    accessorKey: "action",
    header: "Action",
  },
];

const allFaculty = [
  {
    accessorKey: "slno",
    header: "Sl No",
  },
  {
    accessorKey: "firstname",
    header: "First Name",
  },
  {
    accessorKey: "email",
    header: "Email",
  },
  {
    accessorKey: "action",
    header: "Action",
  },
];

export const COLUMNS = {
  entrolledStudent,
  allAssignment,
  allExams,
  allFaculty,
};
