import React, { useEffect, useState } from "react";
import "./TeacherDetails.css";
import { Col, Container, Row, Spinner, Table } from "react-bootstrap";
import { BsArrowRight } from "react-icons/bs";
import ReactPaginate from "react-paginate";
import { GiTeacher } from "react-icons/gi";
import useTeacher from "./hooks/useTeacher";
import Courseadmincards from "../../components/Courseadmincards/Courseadmincards";
import { FaUserGraduate } from "react-icons/fa";
import { Button } from "@mui/material";

export default function TeacherDetails() {
  const {
    changePage,
    pageCount,
    pagesVisited,
    spin,
    teacherList,
    usersPerPage,
    navigate,
    todayJoinedTeacherCount,
  } = useTeacher();

  return (
    <>
      {/* <Header2 /> */}

      <Container fluid>
        {spin ? (
          <div id="abt9">
            <Spinner animation="border" variant="warning" />
          </div>
        ) : (
          <>
            <Row>
              <Col md={3} lg={3} sm={12} xs={12}>
                <div>
                  <Courseadmincards
                    title="Total Faculty"
                    icon={<FaUserGraduate id="dash14" />}
                    count={teacherList?.length || 0}
                  />
                </div>
              </Col>
              <Col md={3} lg={3} sm={12} xs={12}>
                <div>
                  <Courseadmincards
                    title="Today Joined"
                    icon={<FaUserGraduate id="dash14" />}
                    count={todayJoinedTeacherCount() || 0}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div id="teacher1">
                  {" "}
                  <GiTeacher /> All Teacher Details
                </div>
              </Col>
            </Row>

            <Container fluid>
              <Row id="teacher11">
                <Col>
                  <Table>
                    <thead id="teacher4">
                      <tr>
                        <th>Sl No.</th>
                        <th>Teacher Name</th>
                        <th>Assigned Center</th>
                        <th>Email</th>
                        <th>Contact</th>
                        {/* <th>Total Studetnt Enrolled</th>
                    <th>Total Course Created</th> */}

                        <th>Qualification</th>
                        {/* <th>Amount</th> */}
                        <th> Approval </th>
                        {/* <th></th> */}
                        <th>Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {teacherList
                        ?.slice(pagesVisited, pagesVisited + usersPerPage)
                        .map((item, i) => (
                          <tr id="teacher7" key={i}>
                            <td>{pagesVisited + i + 1}</td>
                            <td>{item?.first_name}</td>
                            <td>
                              {item?.centerId?.center_name &&
                              item?.centerId?.district_id
                                ? `${item?.centerId?.center_name}, ${item?.centerId?.district_id?.district_name}, ${item?.centerId?.district_id?.states}`
                                : "No Center Assigned"}
                            </td>
                            <td>{item?.email}</td>
                            <td>{item?.phone_number}</td>
                            <td>{item?.highest_qualification}</td>
                            {/* {item?.admin_approval === "pending" && (
                            <td id="teacher8">
                              {" "}
                              <Button
                                id="teacher10"
                                onClick={() => approveTeacher(item._id)}
                              >
                                APPROVE{" "}
                              </Button>
                            </td>
                          )} */}
                            {/* {item?.admin_approval === "pending" && (
                            <td id="teacher8">
                              <Button
                                id="teacher9"
                                onClick={() => rejectTeacher(item?._id)}
                              >
                                REJECT{" "}
                              </Button>
                            </td>
                          )} */}
                            {item?.admin_approval === "reject" && (
                              <td id="teacher13">Rejected</td>
                            )}
                            {item?.admin_approval === "pending" && (
                              <td id="teacher12">Pending</td>
                            )}
                            {item?.admin_approval === "approved" && (
                              <td id="teacher8">APPROVED</td>
                            )}
                            {item?.admin_approval === "rejected" && (
                              <td id="teacher8" style={{ color: "red" }}>
                                REJECTED
                              </td>
                            )}
                            {item?.admin_approval !== "pending" && ""}
                            <td
                              id="teacher5"
                              onClick={() =>
                                navigate(
                                  `/faculty/faculty-details?name=${encodeURIComponent(
                                    item?.first_name?.replace(/\s+/g, "-")
                                  )?.toLowerCase()}&id=${item?._id}`,
                                  {
                                    state: { res: item },
                                  }
                                )
                              }
                            >
                              <Button id="teachers12">View</Button>
                              {/* View */}
                              {/* <BsArrowRight id="teacher6" /> */}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  {!teacherList?.length == 10 ||
                    (teacherList?.length > 10 && (
                      <ReactPaginate
                        previousLabel={"<Previous"}
                        nextLabel={"Next>"}
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"paginationBttns"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"paginationDisabled"}
                        activeClassName={"paginationActive"}
                      />
                    ))}
                </Col>
              </Row>
            </Container>
          </>
        )}
      </Container>
    </>
  );
}
