import { useState } from "react";
import React from "react";
import { Form, Modal } from "react-bootstrap";
import "./Privacymodal.css";
import { apicaller } from "../../../../utils/api";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Utility from "../../../../utils/Utility";
import { useDispatch, useSelector } from "react-redux";
import { clear, getUserToken } from "../../../../Redux/Slice/UserSlice";
import GlobalButton from "../../../../components/GlobalButton/GlobalButton";
import { useNavigate } from "react-router-dom";

export default function RefundPolicyModal(props) {
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  const token = useSelector(getUserToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validation = () => {
    if (description.length === 0) {
      Utility.eToast("Enter Your Description");
      return false;
    } else {
      return true;
    }
  };

  const Save = async () => {
    setLoading(true);
    if (validation()) {
      const data = {
        description: description,
      };
      await apicaller(
        "add-refundpolicy",
        data,
        "POST",
        token,
        "application/json"
      )
        .then((res) => {
          if (res?.status === 201 || res?.status === 200) {
            Utility.sToast("Data Saved Successfully");
            setDescription("");
            props.getRefundPolicy();
            props.onHide();
          }
        })
        .catch((err) => {
          if (err?.response?.data?.error === "Token Session expired") {
            Utility.eToast(
              "Your token has expired, Please Login again to continue!"
            );
            dispatch(clear());
            navigate("/login");
          } else {
            Utility.eToast("Unable to create! Please try again.");
          }
          setLoading(false);
        })
        .finally(() => setLoading(false));
    }
  };
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton id="mdl1" />
        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Description</Form.Label>
            <CKEditor
              editor={ClassicEditor}
              data={description}
              onChange={(event, editor) => {
                const data = editor.getData();
                setDescription(data);
              }}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer id="mdl2">

        <GlobalButton
            title="Cancel"
            width={150}
            height={46}
            backgroundColor="transparent"
            borderColor="lightslategray"
            textColor="lightslategray"
            fontWeight={400}
            onclick={() => {
              props.onHide();
            }}
          />
          <GlobalButton
            title={loading ? "Adding" : "Add"}
            loading={loading}
            width={150}
            height={46}
            onclick={Save}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
}
