import React from "react";
import "./SettingsGlobalHeader.css";
import { Col, Row } from "react-bootstrap";
import { FaSquarePlus } from "react-icons/fa6";

export default function SettingsGlobalHeader({
  headingText = "NA",
  dataLength,
  showButton = false,
  onClick = () => {},
}) {
  return (
    <Row>
      <Col>
        <div id="headerText">
          {headingText} {dataLength && `(${dataLength})`}
        </div>
      </Col>
      {showButton && (
        <Col>
          <div id="headerButtonView" onClick={onClick}>
            <FaSquarePlus size={30} color="#1814F3" id="headerButtonIcon" />
          </div>
        </Col>
      )}
    </Row>
  );
}
