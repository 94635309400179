import React from "react";
import "./ManageBatch.css";
import { Col, Row } from "react-bootstrap";
import { Button } from "@mui/material";
import { MdModeEdit, MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";

function ManageBatch({ item }) {
  const navigate = useNavigate();

  return (
    <Col id="CardContainer" md={4}>
      <Row>
        <Col>
          <div id="BatchStartEndDiv">
            Batch Start Date
            <div>
              {item?.start_date.slice(8, 10)}/{item?.start_date.slice(5, 7)}/
              {item?.start_date.slice(0, 4)}
            </div>
          </div>
        </Col>{" "}
        <Col>
          <div id="BatchStartEndDiv">
            Batch End Date
            <div>
              {item?.end_date.slice(8, 10)}/{item?.end_date.slice(5, 7)}/
              {item?.end_date.slice(0, 4)}
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div id="BatchTimingLabel">Batches & Timing</div>
        </Col>
      </Row>
      <Row>
        {item?.time?.map((item) => (
          <Col md={6} lg={6} sm={4} xs={4} className="mb-1 mt-1">
            <div id="BatchSoltDiv">{item}</div>
          </Col>
        ))}
      </Row>
      <Row>
        <Col md={8} lg={8} sm={12} xs={12}></Col>
        <Col md={2} lg={2} sm={12} xs={12} id="faq7">
          {/* <div> */}
          {/* <Button
            id="faq9"
            onClick={() =>
              navigate("/addslotcourse", {
                state: {
                  res: item,
                },
              })
            }
          >
            <MdModeEdit id="faq8" />
          </Button> */}
          {/* </div> */}
        </Col>
        <Col md={2} lg={2} sm={12} xs={12} id="faq7">
          {/* <Button id="faq11">
            <MdDelete id="faq10" />
          </Button> */}
          <Button
            id="faq9"
            onClick={() =>
              navigate("/editSlotPage", {
                state: {
                  res: item,
                },
              })
            }
          >
            <MdModeEdit id="faq8" />
          </Button>
        </Col>
      </Row>
    </Col>
  );
}
export default React.memo(ManageBatch);
