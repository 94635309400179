import React, { useEffect, useState } from "react";
import "./AssignFaculty.css";
import { Col, Form, Row } from "react-bootstrap";
import { IoMdClose } from "react-icons/io";
import { useSelector } from "react-redux";
import GlobalButton from "../GlobalButton/GlobalButton";
import { apicaller } from "../../utils/api";
import Utility from "../../utils/Utility";
import { getUserToken } from "../../Redux/Slice/UserSlice";

export default function AssignFaculty({
  teacherList = [],
  fetchSingleCourseApi,
  courseDetails,
}) {
  const token = useSelector(getUserToken);

  //   const { singleCourse } = useSelector((state) => state.user);
  // filter Teacher Result That Not In My Array
  const [filteredTeachers, setfilteredTeachers] = useState([]);
  const filterTeachersNotInMyList = () => {
    // Get array of teacher IDs that I have previously
    const teacherIdsIHave = courseDetails?.teachers?.map(
      (teacher) => teacher?._id
    );
    // Filtered Result that will not show teacher That i have
    const filteredTeachers = teacherList?.filter(
      (teacher) => !teacherIdsIHave?.includes(teacher._id)
    );
    setAllTeacherList(filteredTeachers);
    setfilteredTeachers(filteredTeachers);
    // return filteredTeachers;
  };

  useEffect(() => {
    filterTeachersNotInMyList();
  }, []);
  const [allTeacherList, setAllTeacherList] = useState();

  const [cousefacultyList, setCourseFacultyList] = useState(
    courseDetails?.teachers || []
  );
  const [selectedFaculty, setSelectedFaculty] = useState("");
  const [changesLoading, setChangesLoading] = useState(false);

  // Add Course Faculty Function
  const handleAddCourseFaculty = () => {
    const result = allTeacherList?.find(
      (item) => item?._id === selectedFaculty
    );
    const index = allTeacherList?.findIndex(
      (item) => item?._id === selectedFaculty
    );
    const newArray = [
      ...allTeacherList?.slice(0, index),
      ...allTeacherList?.slice(index + 1),
    ];
    setCourseFacultyList([...cousefacultyList, result]);
    setAllTeacherList(newArray);
    setSelectedFaculty("");
  };

  // Remove Course Faculty Function
  const handleRemoveFaculty = (index, selectedFaculty) => {
    if (window.confirm("Are you sure you want to remove this faculty?")) {
      setAllTeacherList([...allTeacherList, selectedFaculty]);
      const newArray = [
        ...cousefacultyList?.slice(0, index),
        ...cousefacultyList?.slice(index + 1),
      ];
      setCourseFacultyList(newArray);
    }
  };

  // Save Validation
  function isValidChange() {
    if (cousefacultyList.length === 0) {
      Utility.eToast("Please Add a Faculty");
      return false;
    } else {
      return true;
    }
  }

  // Update Changes Function
  const handleSaveChanges = async () => {
    if (isValidChange()) {
      setChangesLoading(true);
      const data = {
        _id: courseDetails?._id,
        teachers: cousefacultyList,
      };
      await apicaller("course", data, "PUT", token)
        .then((res) => {
          fetchSingleCourseApi();
          Utility.sToast(res?.data?.message);
          setChangesLoading(false);
        })
        .catch((err) => {
          Utility.eToast(
            err?.response?.data?.error || "Oops Something went wrong"
          );
          setChangesLoading(false);
        });
    }
  };

  const RenderFacultyCard = ({ item, i }) => {
    return (
      <div id="facultyCard" key={i}>
        {item?.first_name?.slice(0, 15) || "NA"}
        {item?.first_name.length > 15 && "..."}
        <div id="crossIcon" onClick={() => handleRemoveFaculty(i, item)}>
          <IoMdClose size={20} />
        </div>
      </div>
    );
  };

  return (
    <Col md={9}>
      <div id="assignFacultyCard">
        <div id="assignFacultyLabel">Assign Faculty</div>
        <Row>
          <Form.Label id="searchFacultyLabel" className="mt-3">
            Select a faculty from list
          </Form.Label>
          <Col md={9}>
            <Form.Select
              className="mb-3"
              aria-label="Default select example"
              id="searchFacultyForm"
              value={selectedFaculty}
              onChange={(e) => {
                setSelectedFaculty(e.target.value);
              }}
            >
              <option>Select a faculty</option>
              {allTeacherList?.map((item) => (
                <option value={item?._id}>{item?.first_name || "NA"}</option>
              ))}
            </Form.Select>
          </Col>
          <Col md={3} id="saveChangesButtondiv">
            <GlobalButton
              title="Add"
              width={160}
              fontWeight="400"
              height="2.3rem"
              backgroundColor="blue"
              onclick={() => {
                handleAddCourseFaculty();
              }}
              disabled={allTeacherList?.length > 0 ? false : true}
            />
          </Col>
        </Row>
        <div id="assignFacultyLabel">Assigned faculty</div>
        <div id="facultyCardRow">
          {cousefacultyList.length === 0 ? (
            <div id="zerofacultyText">Add a faculty to assign</div>
          ) : (
            <>
              {cousefacultyList?.map((item, i) => (
                <RenderFacultyCard item={item} i={i} />
              ))}
            </>
          )}
        </div>
        <Row>
          <Col md={9}></Col>
          <Col md={3} id="saveChangesButtondiv">
            <GlobalButton
              title="Save Changes"
              width={160}
              height="2.3rem"
              onclick={() => handleSaveChanges()}
              marginTop="1rem"
              loading={changesLoading}
            />
          </Col>
        </Row>
      </div>
    </Col>
  );
}
