import React from "react";
import "./Courseadmincards.css";
import { Col, Row } from "react-bootstrap";

export default function Courseadmincards(props) {
  return (
    <>
      <div id="courseadmincards1">
        <Row>
          <Col md={10} lg={10} sm={10} xs={10}>
            <div id="courseadmincards3">{props.title}</div>
            <div id="dashcard5">{props.count}</div>
          </Col>
          <Col md={2} lg={2} sm={2} xs={2} >
            <div id="courseadmincards2">
              {props.icon}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
