import React, { useEffect, useState } from "react";
import "./StudentCourses.css";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import Header2 from "../../components/Header2/Header2";
import CourseCard from "../../components/CourseCard/CourseCard";
import { useLocation, useNavigate } from "react-router-dom";
import { apicaller } from "../../utils/api";
import { useDispatch, useSelector } from "react-redux";
import { clear, getUserToken } from "../../Redux/Slice/UserSlice";
import Utility from "../../utils/Utility";
import TopratedCourseCard from "../../components/TopratedCourseCard/TopratedCourseCard";
import StudentTab from "../../components/StudentTab/StudentTab";
import BackButton from "../../components/BackButton";
function StudentCourses() {
  const location = useLocation();
  const response = location.state.res;
  const studentid = response?._id;
  const Token = useSelector(getUserToken);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [studentCourse, setStudentCourse] = useState([]);
  const [spin, setSpin] = useState(true);
  const [studentDetails, setStudentDetails] = useState([]);
  useEffect(() => {
    totalCourseOfStudent();
  }, []);
  const totalCourseOfStudent = async () => {
    await apicaller(`profile/${studentid}`, null, "get", Token)
      .then((res) => {
        console.log("***STUDENT COURSES****", res?.data?.data);
        setStudentCourse(res?.data?.data?.subscriptions);
        setStudentDetails(res?.data?.data);
        // return Promise.resolve();
      })
      .catch((err) => {
        if (err?.response?.data?.error === "Token Session expired") {
          Utility.eToast(
            "Your token has expired, Please Login again to continue!"
          );
          dispatch(clear());
          navigate("/login");
        }
      })
      .finally(() => {
        setSpin(false);
      });
  };

  console.log(studentDetails, "Student Details");

  return (
    <>
      <Container>
        {spin ? (
          <div id="abt9">
            <Spinner animation="border" variant="warning" />
          </div>
        ) : (
            <Container>
              <BackButton/>
            <div className="studentDetailsCard">
              <Row className="fs-4 ms-2 fw-bold studentText">
                {studentDetails?.first_name}’s Details
              </Row>
              <StudentTab studentDetails={studentDetails} />
            </div>
          </Container>
        )}
      </Container>
    </>
  );
}

export default StudentCourses;
