import React, { useEffect, useState } from "react";
import "./MonthwiseCourseComp.css";
import { apicaller } from "../../utils/api";
import { useSelector } from "react-redux";
import { LineChart } from "@mui/x-charts/LineChart";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export default function MonthwiseCourseComp() {
  const [chartLabel, setChartLabel] = useState([]);
  const [chartData, setChartData] = useState([]);

  const token = useSelector((state) => state?.user?.token);
  //   console.log("GGGGGGGGGGGGG", token);

  const [year, setYear] = useState(2024);

  const handleChange = (event) => {
    setYear(event.target.value);
  };

  useEffect(() => {
    finalData();
  }, [year]);

  const finalData = async () => {
    await apicaller(`monthwisecourse?year=${year}`, null, "GET", token, null)
      .then((res) => {
        // console.log("==========>", res?.data?.result);
        setChartLabel(res?.data?.result?.month);
        setChartData(res?.data?.result?.data);
      })
      .catch((err) => console.log(err));
  };
  //   console.log("first", chartLabel);
  //   console.log("second", chartData);

  return (
    <div>
      <div>
        <LineChart
          width={500}
          height={300}
          series={[{ data: chartData, label: "courses" }]}
          xAxis={[{ scaleType: "point", data: chartLabel }]}
        />
      </div>
      <span style={{ position: "absolute", top: 0, right: 0 }}>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="demo-select-small-label">Year</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={year}
            label="year"
            onChange={handleChange}
          >
            <MenuItem value={2024}>2024</MenuItem>
            <MenuItem value={2025}>2025</MenuItem>
            <MenuItem value={2026}>2026</MenuItem>
            <MenuItem value={2027}>2027</MenuItem>
            <MenuItem value={2028}>2028</MenuItem>
            <MenuItem value={2029}>2029</MenuItem>
            <MenuItem value={2030}>2030</MenuItem>
          </Select>
        </FormControl>
      </span>
    </div>
  );
}
