import React from "react";
import "./CourseDetailTeacher.css";
import { Col, Container, Row } from "react-bootstrap";
import Header2 from "../../components/Header2/Header2";
import BatchCard from "../../components/BatchCard/BatchCard";
import { useNavigate } from "react-router-dom";
function CourseDetailTeacher() {
  const navigate = useNavigate();

  return (
    <>
      {/* <Header2 /> */}
      <Container>
        <Row id="cdt1">
          <Col>Course name : Python</Col>
        </Row>
        <Row>
          <Col id="cdt5">All Batches</Col>
          <Col></Col>
        </Row>

        <Row id="cdt2">
          <Col id="cdt3">
            {" "}
            <BatchCard
              startdate="28-04-2023"
              enddate="28-05-2023"
              starttime="12.23pm"
              endtime="9.16pm"
            />{" "}
          </Col>
          <Col>
            {" "}
            <BatchCard
              startdate="28-04-2023"
              enddate="28-05-2023"
              starttime="12.23pm"
              endtime="9.16pm"
            />{" "}
          </Col>
          <Col>
            {" "}
            <BatchCard
              startdate="28-04-2023"
              enddate="28-05-2023"
              starttime="12.23pm"
              endtime="9.16pm"
            />{" "}
          </Col>
          <Col id="cdt4">
            {" "}
            <BatchCard
              startdate="28-04-2023"
              enddate="28-05-2023"
              starttime="12.23pm"
              endtime="9.16pm"
            />{" "}
          </Col>
        </Row>

        <Row>
          <Col id="cdt6"> Course content</Col>
        </Row>
        <Row>
          <Col style={{ padding: "0px" }}>
            <table class="table table-striped table-dark" id="cdt7">
              <thead>
                <tr>
                  <th scope="col">No</th>
                  <th scope="col">Section 1</th>
                  <th scope="col"> </th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>Chapter -1 react js props</td>
                  <td> </td>
                  <td> </td>
                </tr>
                <tr>
                  <th scope="row">1</th>
                  <td>Chapter -1 react js props</td>
                  <td> </td>
                  <td> </td>
                </tr>
                <tr>
                  <th scope="row">1</th>
                  <td>Chapter -1 react js props</td>
                  <td> </td>
                  <td> </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>

        <Row>
          <Col id="cdt6"> Student Enrolled </Col>
        </Row>
        <Row>
          <Col style={{ padding: "0px" }}>
            <table class="table table-striped table-dark" id="cdt7">
              <thead>
                <tr>
                  <th scope="col">No</th>
                  <th scope="col">Name</th>
                  <th scope="col"> Student ID </th>
                  <th scope="col"> Phone no</th>
                  <th scope="col"> Email</th>
                  <th scope="col"> Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>Dinesh Panda</td>
                  <td> efsddf</td>
                  <td> +91 312423234</td>
                  <td> rd4305r7@gmail.com </td>
                  <td id="cdt8" onClick={() => navigate("/studentdetail")}>
                    {" "}
                    View Details{" "}
                  </td>
                </tr>

                <tr>
                  <th scope="row">1</th>
                  <td>Dinesh Panda</td>
                  <td> efsddf</td>
                  <td> +91 312423234</td>
                  <td> rd4305r7@gmail.com </td>
                  <td id="cdt8" onClick={() => navigate("/studentdetail")}>
                    {" "}
                    View Details{" "}
                  </td>
                </tr>

                <tr>
                  <th scope="row">1</th>
                  <td>Dinesh Panda</td>
                  <td> efsddf</td>
                  <td> +91 312423234</td>
                  <td> rd4305r7@gmail.com </td>
                  <td id="cdt8" onClick={() => navigate("/studentdetail")}>
                    {" "}
                    View Details{" "}
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CourseDetailTeacher;
