import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "./StudentTab.css";
import Purchases from "./Purchases";
import StudentLearnings from "./StudentLearnings";
import PersonalDetails from "./PersonalDetails";
import StudentAssignment from "./StudentAssignment";
import StudentExam from "./StudentExam";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clear, getUserToken } from "../../Redux/Slice/UserSlice";
import { apicaller } from "../../utils/api";
import Utility from "../../utils/Utility";
import Dashcards from "../../pages/Dashcards/Dashcards";
import { FaBookOpen } from "react-icons/fa";
import { Col, Row } from "react-bootstrap";
import { MdAssignmentInd } from "react-icons/md";
import StudentResults from "./StudentResults";

function StudentTab({ studentDetails }) {
  const selectedTab = localStorage.getItem("selectedTab");
  const Token = useSelector(getUserToken);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [assignmentDetails, setAssignmentDetails] = useState([]);
  const [value, setValue] = useState(selectedTab ? selectedTab : "learnings");
  const [examDetails, setExamDetails] = useState([]);
  const [examResultDetails, setExamResultDetails] = useState([]);

  useEffect(() => {
    getAllAssignment();
    getStudentExam();
    getStudentExamResults();
  }, []);

  useEffect(() => {
    localStorage.setItem("selectedTab", value);
  }, [value]);

  const getAllAssignment = async () => {
    try {
      const res = await apicaller(
        `task-student/${studentDetails._id}`,
        null,
        "get",
        Token
      );

      setAssignmentDetails(res?.data);
    } catch (err) {
      console.log("error in alluser", err);
      if (err?.response?.data?.error === "Token Session expired") {
        Utility.eToast(
          "Your token has expired, Please Login again to continue!"
        );
        dispatch(clear());
        navigate("/login");
      }
    }
  };

  const getStudentExam = async () => {
    try {
      const res = await apicaller(
        `exam-all-student/${studentDetails._id}`,
        null,
        "get",
        Token
      );
      console.log(res?.data?.examData, "response of student exam");
      setExamDetails(res?.data?.examData);
    } catch (err) {
      console.log("error in alluser", err);
      if (err?.response?.data?.error === "Token Session expired") {
        Utility.eToast(
          "Your token has expired, Please Login again to continue!"
        );
        dispatch(clear());
        navigate("/login");
      }
    }
  };

  const getStudentExamResults = async () => {
    try {
      const res = await apicaller(
        `results?studentId=${studentDetails?._id}`,
        null,
        "get",
        Token
      );
      // console.log(res?.data?.results, "response of student exam results");
      if(res?.data?.results?.length > 0) {
        setExamResultDetails(res?.data?.results?.reverse());
      }
    } catch (err) {
      console.log("error in alluser", err);
      if (err?.response?.data?.error === "Token Session expired") {
        Utility.eToast(
          "Your token has expired, Please Login again to continue!"
        );
        dispatch(clear());
        navigate("/login");
      }
    }
  };
  console.log(assignmentDetails.length, "assignmentDetails");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Row className="mb-5">
        <Col md={4}>
          <Dashcards
            icon={<MdAssignmentInd id="dash13" />}
            title="Total Assignments"
            amount={assignmentDetails.length}
            background="#F6FFE7"
            borderRadius="20rem"
          />
        </Col>
        <Col md={4}>
          <Dashcards
            icon={<FaBookOpen id="dash13" />}
            title="Total Exams"
            amount={examResultDetails.length}
            background="#F6FFE7"
            borderRadius="20rem"
          />
        </Col>
      </Row>
      <div className="tabContainer">
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                variant="scrollable"
                scrollButtons="auto"
                TabScrollButtonProps={{
                  sx: {
                    color: "#1814F3",
                  },
                }}
                TabIndicatorProps={{
                  sx: {
                    backgroundColor: "#1814F3",
                    height: 4,
                    borderRadius: "20px 20px 0px 0px",
                  },
                }}
              >
                <Tab
                  label="Learnings"
                  value="learnings"
                  style={{
                    color: "#718EBF",
                    fontFamily: "IBM Plex Sans",
                    fontSize: 14,
                  }}
                />
                <Tab
                  label="Personal Details"
                  value="personalDetails"
                  style={{
                    color: "#718EBF",
                    fontFamily: "IBM Plex Sans",
                    fontSize: 14,
                  }}
                />
                <Tab
                  label="Assignment"
                  value="assignment"
                  style={{
                    color: "#718EBF",
                    fontFamily: "IBM Plex Sans",
                    fontSize: 14,
                  }}
                />
                <Tab
                  label="Exam"
                  value="exam"
                  style={{
                    color: "#718EBF",
                    fontFamily: "IBM Plex Sans",
                    fontSize: 14,
                  }}
                />
                <Tab
                  label="Attended Exam's Results"
                  value="results"
                  style={{
                    color: "#718EBF",
                    fontFamily: "IBM Plex Sans",
                    fontSize: 14,
                  }}
                />
              </TabList>
            </Box>
            <TabPanel value="learnings">
              <StudentLearnings studentDetails={studentDetails} />
            </TabPanel>
            <TabPanel value="personalDetails">
              <PersonalDetails studentDetails={studentDetails} />
            </TabPanel>
            <TabPanel value="assignment">
              <StudentAssignment
                studentDetails={studentDetails}
                assignmentDetails={assignmentDetails}
              />
            </TabPanel>
            <TabPanel value="exam">
              <StudentExam
                studentDetails={studentDetails}
                examDetails={examDetails}
              />
            </TabPanel>
            <TabPanel value="results">
              <StudentResults
                studentDetails={studentDetails}
                examResultDetails={examResultDetails}
              />
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </div>
  );
}

export default StudentTab;
