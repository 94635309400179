import React, { useState } from "react";
import "./EditCategory.css";
import { Button, Modal, Spinner } from "react-bootstrap";
import { TextField } from "@mui/material";
import { apicaller } from "../../../../utils/api";
import { useDispatch, useSelector } from "react-redux";
import Utility from "../../../../utils/Utility";
import { clear } from "../../../../Redux/Slice/UserSlice";
import { useNavigate } from "react-router-dom";

export default function EditCategory(props) {
  let name = props?.item?.category_name;
  const navigate = useNavigate();

  const [cat, setCat] = useState(name);
  const token = useSelector((state) => state?.user?.token);
  const [spin, setSpin] = useState(false);

  const dispatch = useDispatch();

  //   const fetchCategories = () => {
  //     apicaller("get-category", null, "GET", token);
  //   };

  const valiation = () => {
    if (cat?.trim() === "") {
      Utility.eToast("Enter Category");
      return false;
    }
    return true;
  };

  const editCat = async () => {
    if (valiation()) {
      setSpin(true);
      const data = {
        category_name: cat,
      };
      await apicaller(
        `update-category/${props?.item?._id}`,
        data,
        "PUT",
        token,
        "application/json"
      )
        .then((res) => {
          setCat("");
          props.fetchcategories();
          Utility.sToast("Category updated successfully");
        })
        .catch((err) => {
          if (err?.response?.data?.error === "Token Session expired") {
            dispatch(clear());
            navigate("/login");
            Utility.eToast("Token Session Expired! Login to Continue !");
          } else {
            Utility.eToast("Unable to Update !");
          }
        })
        .finally(() => {
          setSpin(false);
          // fetchCategories();
          props.onHide();
        });
    }
  };

  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="ecc1">
          <Modal.Title id="contained-modal-title-vcenter" className="ecc2">
            Edit Category
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextField
            fullWidth
            label="Category"
            id="fullWidth"
            defaultValue={name}
            onChange={(e) => setCat(e.target.value)}
          />
          <Button className="ecc3" disabled={cat?.trim() === ""} onClick={editCat}>
            {spin ? (
              <Spinner
                animation="border"
                variant="warning"
                style={{ height: "1rem", width: "1rem" }}
              />
            ) : (
              "Save"
            )}
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
}
