import React, { useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { clear, getUserToken } from "../../Redux/Slice/UserSlice";
import { apicaller } from "../../utils/api";
import Utility from "../../utils/Utility";
import "./AddBannerModal.css";
import GlobalButton from "../GlobalButton/GlobalButton";
import { useNavigate } from "react-router-dom";

export default function AddBannerModal(props) {
  const [selectedFile, setSelectedFile] = useState("");
  const [filePath, setFilePath] = useState("");
  const [loadingUploadImage, setLoadingUploadImage] = useState(false);
  const [loadingSaveBanner, setLoadingSaveBanner] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const token = useSelector(getUserToken);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const selectBanner = (e) => {
  //   setSelectedFile(e.target.files[0]);
  // };

  // const uploadBanner = () => {
  //   if (selectedFile?.length === 0 || selectedFile === undefined || selectedFile === null) {
  //     Utility.eToast("Please choose a file !");
  //     return false;
  //   } else {
  //     setLoadingUploadImage(true);
  //     var FormData = require("form-data");
  //     var data = new FormData();
  //     data.append("img", selectedFile);
  //     apicaller("single-upload", data, "POST", token, null)
  //       .then((res) => {
  //         if (res.status === 201 || res.status === 200) {
  //           const path = res.data.img;
  //           setFilePath(path);
  //           console.log("image uploaded successfully", res.data);
  //         }
  //       })
  //       .catch((err) => {
  //         if (err?.response?.data?.error === "Token Session expired") {
  //           Utility.eToast(
  //             "Your token has expired, Please Login again to continue!"
  //           );
  //           dispatch(clear());
  //           navigate("/login");
  //         } else {
  //           Utility.eToast("Unable to upload! Please try again.");
  //         }
  //       })
  //       .finally(() => setLoadingUploadImage(false));
  //   }
  // };

  const selectBanner = (e) => {
    const file = e.target.files[0];
    
    if (file && file.size > 1048576) {
      Utility.eToast("File size should not exceed 1 MB!");
      setSelectedFile(null);
    } else {
      setSelectedFile(file);
    }
  };
  
  const uploadBanner = () => {
    if (!selectedFile) {
      Utility.eToast("Please choose a valid file & choose an image under 1MB !");
      return false;
    }
    
    setLoadingUploadImage(true);
    var FormData = require("form-data");
    var data = new FormData();
    data.append("img", selectedFile);
    apicaller("single-upload", data, "POST", token, null)
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          const path = res.data.img;
          setFilePath(path);
          console.log("image uploaded successfully", res.data);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.error === "Token Session expired") {
          Utility.eToast("Your token has expired, Please Login again to continue!");
          dispatch(clear());
          navigate("/login");
        } else {
          Utility.eToast("Unable to upload! Please try again.");
        }
      })
      .finally(() => setLoadingUploadImage(false));
  };
  

  const validation = () => {
    if (filePath?.length === 0 || filePath === undefined || filePath === null) {
      Utility.eToast("Please Choose a File!");
      return false;
    } else if (title?.trim() === "") {
      Utility.eToast("Please Add Title!");
      return false;
    } else if (description?.trim() === "") {
      Utility.eToast("Please Add Description!");
      return false;
    } else {
      return true;
    }
  };

  const saveBanner = () => {
    if (validation()) {
      setLoadingSaveBanner(true);
      const data = {
        url: filePath,
        title: title,
        description: description,
      };
      apicaller("add-advertisement", data, "POST", token, "application/json")
        .then((res) => {
          if (res.status === 201 || res.status === 200) {
            console.log("data added successfully", res.data);
            Utility.sToast("Data added successfully");
            setSelectedFile(null);
            setFilePath("");
            setTitle("");
            setDescription("");
            props.onHide();
            props.getbanner();
          }
        })
        .catch((err) => {
          if (err?.response?.data?.error === "Token Session expired") {
            Utility.eToast(
              "Your token has expired, Please Login again to continue!"
            );
            dispatch(clear());
            navigate("/login");
          } else {
            Utility.eToast("Unable to create! Please try again.");
          }
        })
        .finally(() => setLoadingSaveBanner(false));
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton id="abm1">
          Add Banner
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formFileSm" className="mb-1">
            <Form.Label>Choose a file</Form.Label>
            <Row>
              <Col xs={7} lg={9}>
                <Form.Control
                  type="file"
                  size="md"
                  // value={selectedFile}
                  onChange={selectBanner}
                />
              </Col>
              <Col md={5} lg={3}>
                <GlobalButton
                  title={loadingUploadImage ? "Uploading" : "Upload"}
                  loading={loadingUploadImage}
                  onclick={uploadBanner}
                />
              </Col>
            </Row>
            {filePath && (
              <img src={filePath} alt="img" id="selectedImgAddBanner" />
            )}
          </Form.Group>
          <Form.Group className="mt-3 mb-3" controlId="formBasicEmail">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer id="abm2">
          <GlobalButton
            title="Cancel"
            width={150}
            height={46}
            backgroundColor="transparent"
            borderColor="lightslategray"
            textColor="lightslategray"
            fontWeight={400}
            onclick={() => {
              props.onHide();
            }}
          />
          <GlobalButton
            title={loadingSaveBanner ? "Saving" : "Save"}
            loading={loadingSaveBanner}
            width={150}
            height={46}
            onclick={saveBanner}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
}
