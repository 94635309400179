import { useState } from "react";
import React from "react";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";
import "./AddCouponModal.css";
import GlobalButton from "../../GlobalButton/GlobalButton";
import Utility from "../../../utils/Utility";
import { useDispatch, useSelector } from "react-redux";
import { AiFillCloseCircle } from "react-icons/ai";
import { clear } from "../../../Redux/Slice/UserSlice";
import { useNavigate } from "react-router-dom";
import { apicaller } from "../../../utils/api";

export default function AddCouponModal(props) {
  const [loadingSaveCoupon, setLoadingSaveCoupon] = useState(false);
  const [code, setCode] = useState("");
  const [percentage, setPercentage] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [status, setStatus] = useState("");
  const [course, setCourse] = useState();
  const [selectedCoursesArr, setSelectedCoursesArr] = useState([]);

  const { courses, token } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onChangeSelectCourse = (e) => {
    const objInString = e.target.value;
    const objInJson = JSON.parse(objInString);
    const FindIndexOfSelectedCourse = selectedCoursesArr?.findIndex(
      (item) => item?._id === objInJson?._id
    );
    if (FindIndexOfSelectedCourse === -1) {
      setCourse(objInString);
      setSelectedCoursesArr((prev) => [...prev, objInJson]);
    } else {
      Utility.eToast("Course Already Selected");
      return;
    }
  };

  const RemoveFromSelectedArr = (ind) => {
    selectedCoursesArr.splice(ind, 1);
    setSelectedCoursesArr([...selectedCoursesArr]);
  };

  function isValid() {
    if (selectedCoursesArr?.length === 0) {
      Utility.eToast("Please Select Course");
      return false;
    } else if (code.length === 0) {
      Utility.eToast("Please Enter Coupon Code");
      return false;
    } else if (percentage <= 0 || percentage > 100) {
      Utility.eToast("Please Enter Percentage");
      return false;
    } else if (expiryDate.length === 0) {
      Utility.eToast("Please Enter Expiry Date");
      return false;
    } else if (status.length === 0) {
      Utility.eToast("Please Enter Status");
      return false;
    } else {
      return true;
    }
  }

  const handleSaveCoupon = async () => {
    if (isValid()) {
      const courseIdArr = selectedCoursesArr?.map((item) => item?._id);
      setLoadingSaveCoupon(true);
      const data = {
        code: code,
        discountPercent: Math.round(percentage),
        courseId: courseIdArr,
        expiryDate: expiryDate,
        isActive: status === "active" ? true : false,
      };
      console.log("data of add coupon", data);

      await apicaller("coupons", data, "post", token)
        .then((res) => {
          console.log(res, "results");
          Utility.sToast("Coupon Added Successfully");
          setCode("");
          setPercentage("");
          setExpiryDate("");
          setStatus("");
          setSelectedCoursesArr([]);
          setCourse();
          setLoadingSaveCoupon(false);
          props.onHide();
        })
        .catch((err) => {
          if (err?.response?.data?.error === "Token Session expired") {
            Utility.eToast(
              "Your token has expired, Please Login again to continue!"
            );
            dispatch(clear());
            navigate("/login");
          }
        });
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton id="acm1">
          Add Coupon
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <Row>
              <Col>
                <Form.Group className="mt-3 mb-3" controlId="formBasicEmail">
                  <Form.Label>Select Courses</Form.Label>
                  <Form.Select value={course} onChange={onChangeSelectCourse}>
                    <option value="">You can Choose Single / Multiple Courses</option>
                    {courses?.map((item, i) => (
                      <option value={JSON.stringify(item)} key={i}>
                        {item?.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              {selectedCoursesArr?.map((item, i) => (
                <Col xs={12} md={12} lg={6} key={i}>
                  <div id="selectedCourseDiv">
                    <div style={{ width: "90%" }}>{item?.name}</div>{" "}
                    <AiFillCloseCircle
                      style={{ cursor: "pointer" }}
                      size={25}
                      onClick={() => {
                        RemoveFromSelectedArr(i);
                      }}
                    />
                  </div>
                </Col>
              ))}
            </Row>
            <Row>
              <Col md={6} xs={6}>
                <Form.Group className="mt-3 mb-3" controlId="formBasicEmail">
                  <Form.Label>Coupon Code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Your Coupon Code"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={6} xs={6}>
                <Form.Group className="mt-3 mb-3" controlId="formBasicEmail">
                  <Form.Label>Percentage</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Your Percentage"
                    value={percentage}
                    onChange={(e) => setPercentage(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={6} xs={6}>
                <Form.Group className="mt-3 mb-3" controlId="formBasicEmail">
                  <Form.Label>Expiry Date</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Enter Coupon Expiry Date"
                    value={expiryDate}
                    onChange={(e) => setExpiryDate(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={6} xs={6}>
                <Form.Group className="mt-3 mb-3" controlId="formBasicEmail">
                  <Form.Label>Coupon Status</Form.Label>
                  <Form.Select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value="">Select a Coupon Status</option>
                    <option value="active">Active</option>
                    <option value="deactive">Deactive</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer id="acm2">
          <GlobalButton
            title="Cancel"
            width={150}
            height={46}
            backgroundColor="transparent"
            borderColor="lightslategray"
            textColor="lightslategray"
            fontWeight={400}
            onclick={() => {
              props.onHide();
            }}
          />
          <GlobalButton
            title={loadingSaveCoupon ? "Saving" : "Save"}
            loading={loadingSaveCoupon}
            width={150}
            height={46}
            onclick={() => {
              handleSaveCoupon();
            }}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
}
