import React, { useEffect, useState } from "react";
import "./Centerdetails.css";
import { Col, Container, Row } from "react-bootstrap";
import Dashcards from "../Dashcards/Dashcards";
import { MdAssignmentInd } from "react-icons/md";
import { FaBookOpen } from "react-icons/fa";
import { Tabs, Tab, Box } from "@mui/material";
import DataTable from "react-data-table-component";
import { apicaller } from "../../utils/api";
import { useSelector } from "react-redux";
import { getUserToken } from "../../Redux/Slice/UserSlice";
import { useLocation, useParams } from "react-router-dom";

function Centerdetails() {
  const { id } = useParams();
  console.log("Id is......", id);
  const location = useLocation();
  const centerName = location.state?.centerName;

  const [value, setValue] = useState(0);
  const [student, setStudent] = useState([]);
  const [course, setCourse] = useState([]);
  const Token = useSelector(getUserToken);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const columns = [
    {
      name: "Uid",
      selector: (row) => row?.uid,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row?.first_name,
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row?.phone_number,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
      sortable: true,
    },
    {
      name: "User Type",
      selector: (row) => row?.user_type,
      sortable: true,
    },
  ];

  const columns2 = [
    {
      name: "Name",
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: "Price",
      selector: (row) => row?.price,
      sortable: true,
    },
    {
      name: "Total Students",
      selector: (row) => row?.students?.length || 0,
      sortable: true,
      width: "200px",
    },
    {
      name: "Total Teachers",
      selector: (row) => row?.teachers?.length || 0,
      sortable: true,
      width: "200px",
    },
  ];

  useEffect(() => {
    getAllstudentCenter();
    getAllcoursesCenter();
  }, []);

  const getAllstudentCenter = async () => {
    try {
      const response = await apicaller(
        `center-users/${id}`,
        null,
        "get",
        Token
      );
      setStudent(response?.data?.data || []);
      console.log("Students are......", response?.data?.data);
    } catch (error) {
      console.error("Error fetching students for the center:", error);
    }
  };

  const getAllcoursesCenter = async () => {
    try {
      const response = await apicaller(
        `center-courses/${id}`,
        null,
        "get",
        Token
      );
      setCourse(response?.data?.data || []);
    } catch (error) {
      console.error("Error fetching courses for the center:", error);
    }
  };

  return (
    <>
      <Container>
        <Row>
          <div id="centerdetails1">
            {centerName ? `${centerName} 'Details` : "Center Details"}
          </div>
        </Row>
        <Row id="centerdetails3">
          <Col md={4}>
            <Dashcards
              icon={<MdAssignmentInd id="dash13" />}
              title="Total Users"
              amount={student?.length}
              background="#F6FFE7"
              borderRadius="20rem"
            />
          </Col>
          <Col md={4}>
            <Dashcards
              icon={<FaBookOpen id="dash13" />}
              title="Total Course"
              amount={course?.length}
              background="#F6FFE7"
              borderRadius="20rem"
            />
          </Col>
        </Row>
        <Row id="centerdetails2">
          <Col md={12}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="center details tabs"
              >
                <Tab label="User" />
                <Tab label="Course" />
              </Tabs>
            </Box>
            <Box sx={{ p: 3 }}>
              {value === 0 && (
                <div>
                  <DataTable columns={columns} data={student} pagination />
                </div>
              )}
              {value === 1 && (
                <div>
                  <DataTable columns={columns2} data={course} pagination />
                </div>
              )}
            </Box>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default React.memo(Centerdetails);
