import React, { useEffect } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import "./RefundPolicy.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import RefundPolicyModal from "./components/RefundPolicyModal/RefundPolicyModal";
import { useDispatch, useSelector } from "react-redux";
import Utility from "../../utils/Utility";
import GlobalButton from "../../components/GlobalButton/GlobalButton";
import { clear, getUserToken } from "../../Redux/Slice/UserSlice";
import SettingsGlobalHeader from "../Settings/components/SettingsGlobalHeader/SettingsGlobalHeader";
import { apicaller } from "../../utils/api";

export default function RefundPolicy() {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [policyData, setPolicyData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deletingData, setDeletingData] = useState(null);

  useEffect(() => {
    getPolicyData();
  }, []);

  const token = useSelector(getUserToken);
  const dispatch = useDispatch();

  const getPolicyData = () => {
    apicaller("get-refundpolicy", null, "get", token, null)
      .then((res) => {
        setPolicyData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.error === "Token Session expired") {
          Utility.eToast(
            "Your token has expired, Please Login again to continue!"
          );
          dispatch(clear());
          navigate("/login");
        }
      });
  };

  const deleteData = async (id) => {
    if (window.confirm("Are you sure, you want to remove ?")) {
      setDeletingData(id);
      await apicaller(`delete-refundpolicy/${id}`, null, "delete", token, null)
        .then((res) => {
          getPolicyData();
          Utility.sToast("Data deleted successfully");
        })
        .catch((err) => {
          if (err?.response?.data?.error === "Token Session expired") {
            Utility.eToast(
              "Your token has expired, Please Login again to continue!"
            );
            dispatch(clear());
            navigate("/login");
          }
          setDeletingData(null);
        })
        .finally(() => setDeletingData(null));
    }
  };
  const handleClose = () => {
    setModal(false);
  };

  return (
    <>
      {/* <Header2 /> */}
      <Container>
        {loading ? (
          <div id="rp9">
            <Spinner animation="border" style={{ color: "#1814F3" }} />
          </div>
        ) : (
          <>
            <SettingsGlobalHeader
              headingText="Refund Policy"
              showButton={true}
              onClick={() => setModal(true)}
            />
            <RefundPolicyModal show={modal} onHide={handleClose} getRefundPolicy={getPolicyData} />
            {policyData?.map((list, index) => (
              <Row key={index} id="rp5">
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: list?.description,
                    }}
                  />
                  <Row>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: 10,
                      }}
                    >
                      <GlobalButton
                        title="Edit"
                        width={130}
                        height={46}
                        // LeftIcon={MdEdit}
                        onclick={() => {
                          navigate("/settings/edit-refund-policy", {
                            state: { id: list },
                          });
                        }}
                      />

                      <GlobalButton
                        title={
                          deletingData === list?._id ? "Removing" : "Remove"
                        }
                        width={130}
                        height={46}
                        backgroundColor="transparent"
                        borderStyle="dashed"
                        borderColor="#EF0940"
                        textColor="#EF0940"
                        fontWeight={400}
                        loading={deletingData === list?._id}
                        loadingColor="#EF0940"
                        onclick={() => {
                          deleteData(list?._id);
                        }}
                      />
                    </div>
                    {/* <Col id="rp2">
                  <Button
                    id="rp3"
                    onClick={() => {
                      deletedata(list._id);
                      getPolicyData();
                    }}
                  >
                    <IoTrashBin /> DELETE
                  </Button>
                  <Button
                    id="rp4"
                    onClick={() => {
                      navigate("/settings/edit-refund-policy", {
                        state: { id: list },
                      });
                    }}
                  >
                    <MdEdit /> EDIT
                  </Button>
                </Col> */}
                  </Row>
                </Col>
              </Row>
            ))}
          </>
        )}
      </Container>
    </>
  );
}
