import React, {useState, useEffect } from "react";
import "./SettingsManageCourseAdmin.css";
import { Col, Container, Row } from "react-bootstrap";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import TestProfile from "../../../../assets/dummyProfile.png";
import SettingsGlobalHeader from "../SettingsGlobalHeader/SettingsGlobalHeader";
import { apicaller } from "../../../../utils/api";
import Utility from "../../../../utils/Utility";
import { useDispatch, useSelector } from "react-redux";
import { clear, getUserToken } from "../../../../Redux/Slice/UserSlice";
import { useNavigate } from "react-router-dom";

export default function SettingsManageCourseAdmin() {
  const data = [
    {
      name: "Livia Bator Course admin",
    },
    {
      name: "Livia Bator Course admin",
    },
    {
      name: "Livia Bator Course admin",
    },
    {
      name: "Livia Bator Course admin",
    },
    {
      name: "Livia Bator Course admin",
    },
    {
      name: "Livia Bator Course admin",
    },
  ];

  const accessData = [
    {
      id: "123",
      option: "Create Access",
    },
    {
      id: "1234",
      option: "Editing Access",
    },
    {
      id: "12345",
      option: "Remove Access",
    },
    {
      id: "123456",
      option: "Read Access",
    },
  ];

  const [isOn, setIsOn] = useState(false);
  const [index, setIndex] = useState({ index: "", toggleValue: null });
const Token = useSelector(getUserToken);
const navigate = useNavigate();
const dispatch = useDispatch();
    useEffect(() => {
      getStudentExam();
    }, []);
    const getStudentExam = async () => {
      try {
        const res = await apicaller(
          "course-admin-get",
          null,
          "get",
          Token
        );
        console.log(res.data, "responese");
        // setExamDetails(res?.data?.examData);
      } catch (err) {
        console.log("error in alluser", err);
        if (err?.response?.data?.error === "Token Session expired") {
          Utility.eToast(
            "Your token has expired, Please Login again to continue!"
          );
          dispatch(clear());
          navigate("/login");
        }
      }
    };
  const handleToggle = (i, id) => {
    setIndex({ i: i, id: id });
    setIsOn(!isOn);
  };

  return (
    <Container fluid>
      {/* Common Header Component  */}
      <SettingsGlobalHeader headingText="Permissions" dataLength={6} />
      <Row>
        {data.map((item, firstIndex) => (
          <Col key={firstIndex} md={4}>
            <div id="CardMainView">
              <Row>
                <Col id="profileNameView" md={8}>
                  <img src={TestProfile} alt="profileImage" id="profileImg" />
                  <div id="courseMangeNameDiv">
                    <div id="nameText">Livia Bator</div>
                    <div id="courseManageByView">Course admin</div>
                  </div>
                </Col>
                <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                  <div>
                    <div></div>
                    <div id="courseCreatedDateText">2days ago</div>
                  </div>
                </Col>
              </Row>
              {/* EDIT ACCESS CARD BUTTONS  */}
              <Row>
                {accessData.map((item, i) => (
                  <Col md={6} key={i}>
                    <div id="accessOptionView">
                      <Toggle
                        checked={
                          index.i === firstIndex && index.id == item.id && isOn
                            ? true
                            : false
                        }
                        onChange={() => handleToggle(firstIndex, item.id)}
                        icons={false}
                        className="custom-toggle"
                      />
                      <div id="accessOptionText">{item.option}</div>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
