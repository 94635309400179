import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apicaller } from "../../utils/api";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Utility from "../../utils/Utility";
import { clear } from "../../Redux/Slice/UserSlice";

const size = {
  width: 350,
  height: 150,
};

export default function Courseadminpiechart() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedRow } = location?.state;
  const [year, setYear] = useState(2024);
  const [courseWiseData, setCourseWiseData] = useState([]);

  console.log(year);

  const handleChange = (event) => {
    setYear(event.target.value);
  };

  const token = useSelector((state) => state?.user?.token);

  useEffect(() => {
    adminCourseWiseData();
  }, [year]);

  const adminCourseWiseData = async () => {
    await apicaller(
      `courseadmin-coursewise-student?id=${selectedRow?._id}&year=${year}`,
      null,
      "GET",
      token,
      null
    )
      .then((res) => {
        console.log("rtrtrtrtr", res);
        setCourseWiseData(res?.data);
      })
      .catch((err) => {
        Utility.eToast("Oops Something Went Wrong");
        if (err?.response?.data?.error === "Token Session expired") {
          Utility.eToast(
            "Your token has expired, Please Login again to continue!"
          );
          dispatch(clear());
          navigate("/login");
        }
      });
  };

  const data = courseWiseData;

  return (
    <>
      <div>
        <Row>
          <Col md={8} lg={8} sm={12} xs={12}>
            <div>
              <PieChart
                series={[
                  {
                    arcLabel: (item) => `${item.label} (${item.value})`,
                    arcLabelMinAngle: 45,
                    data,
                    highlightScope: { faded: "global", highlighted: "item" },
                    faded: {
                      innerRadius: 30,
                      additionalRadius: -30,
                      color: "gray",
                    },
                    cx: 150,
                  },
                ]}
                height={200}
                slotProps={{ legend: { hidden: true } }}
                sx={{
                  [`& .${pieArcLabelClasses.root}`]: {
                    fill: "white",
                    fontWeight: "bold",
                    fontSize: "0.7rem",
                  },
                }}
                {...size}
              />
            </div>
          </Col>
          <Col md={4} lg={4} sm={12} xs={12}>
            <div>
              <FormControl>
                <InputLabel id="demo-select-small-label">Year</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={year}
                  label="year"
                  onChange={handleChange}
                >
                  <MenuItem value={2024}>2024</MenuItem>
                  <MenuItem value={2025}>2025</MenuItem>
                  <MenuItem value={2026}>2026</MenuItem>
                  <MenuItem value={2027}>2027</MenuItem>
                  <MenuItem value={2028}>2028</MenuItem>
                  <MenuItem value={2029}>2029</MenuItem>
                  <MenuItem value={2030}>2030</MenuItem>
                </Select>
              </FormControl>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
