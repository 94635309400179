import React, { useEffect, useState, useCallback, memo } from "react";
import { Col, Container, Form, Row, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import "./EditCourseModal.css";
import { MdClose } from "react-icons/md";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { getUserToken, getUser_Id } from "../../Redux/Slice/UserSlice";
import Utility from "../../utils/Utility";
import { apicaller } from "../../utils/api";
import GlobalButton from "../GlobalButton/GlobalButton";
import { Dropdown, DropdownButton } from "react-bootstrap";

const EditNewCourseModal = (props) => {
  var res = props.response;
  console.log("item in course modal", res);
  const Token = useSelector(getUserToken);
  const userId = useSelector(getUser_Id);
  const TeacherId = userId;
  const [empty, setEmpty] = useState("");
  const [courseName, setCourseName] = useState(res?.name);
  const [courseDescription, setCourseDescription] = useState(res?.description);
  const [courseThumbnail, setCourseThumbnail] = useState([]);
  const [price, setPrice] = useState(res?.price);
  const [postLoader, setPostLoader] = useState(false);
  const [imgLink, setImgLink] = useState("");
  const [uploadBtnLoader, setUploadBtnLoader] = useState(false);
  const [imagePreview, setImagePreview] = useState(res?.img);
  const [categoryData, setCategoryData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(res?.category);
  console.log(selectedCategory, "selectedCategory");
  const fetchCategories = async () => {
    try {
      const response = await apicaller("get-category", null, "GET", Token);
      // Assuming the API response is an array of categories
      setCategoryData(response?.data?.category);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);
  const handleSelect = (category) => {
    setSelectedCategory(category);
  };
  const attachImage = (e) => {
    const file = e.target.files[0];

    if (file) {
      const fileSizeInMB = file.size / (1024 * 1024);
      if (fileSizeInMB > 1) {
        Utility.eToast("Image size should not exceed 1 MB");
        setEmpty("");
        return;
      }

      setCourseThumbnail(file);
      setImagePreview(URL.createObjectURL(file));
      setEmpty(e.target.value);
    }
  };

  function validation() {
    console.log(1);
    if (empty?.length !== 0) {
      console.log(2);

      if (imgLink?.length == 0) {
        Utility.eToast("Please Upload The Course Thumbnail");
        return false;
      } else {
        if (courseName?.length === 0) {
          Utility.eToast("Please Enter Course Name");
          return false;
        } else if (price?.length === 0) {
          Utility.eToast("Please Enter Course Price");
          return false;
        } else if (courseDescription?.length === 0) {
          Utility.eToast("Please Enter Course Description");
          return false;
        } else if (selectedCategory == null) {
          Utility.eToast("Please select a category");
        } else if (courseDescription?.length === 0) {
          Utility.eToast("Please Enter Course Description");
          return false;
        } else {
          return true;
        }
      }
    } else {
      console.log(3);

      if (courseName?.length === 0) {
        Utility.eToast("Please Enter Course Name");
        return false;
      } else if (price?.length === 0) {
        Utility.eToast("Please Enter Course Price");
        return false;
      } else if (selectedCategory == null) {
        Utility.eToast("Please select a category");
      } else if (courseDescription?.length === 0) {
        Utility.eToast("Please Enter Course Description");
        return false;
      } else {
        return true;
      }
    }
  }

  async function UploadEditThumbNail() {
    if (empty !== "") {
      setUploadBtnLoader(true);
      const data = new FormData();
      data.append("img", courseThumbnail);
      await apicaller(
        "single-upload",
        data,
        "POST",
        null,
        "multipart/form-data"
      )
        .then((res) => {
          if (res?.status === 201 || res?.status === 200) {
            setImgLink(res?.data?.img);
            Utility.sToast("Thumbnail Uploaded Successfully");
          }
        })
        .catch((err) => {
          Utility.eToast("Error occurred while uploading the image", err);
        })
        .finally(() => {
          setUploadBtnLoader(false);
        });
    } else {
      Utility.eToast(
        "Please select a course thumbnail to upload & Image size should not exceed 1 MB !"
      );
    }
  }

  const AddNewCourseFunction = async () => {
    if (validation()) {
      setPostLoader(true);
      var data = {
        img: imgLink === "" ? res?.img : imgLink,
        name: courseName,
        price: price,
        category: selectedCategory._id,
        description: courseDescription,
        createdBy: res?.createdBy,
        _id: res?._id,
      };
      await apicaller("course", data, "put", Token)
        .then((res) => {
          props.getAllCourseList();
          Utility.sToast("Data Updated Successfully");
        })
        .catch((error) => {
          console.log("Error: ", error);
        })
        .finally(() => {
          setPostLoader(false);
          props.onHide();
          setCourseThumbnail([]);
          setEmpty("");
          setImgLink("");
        });
    }
  };

  function Hide() {
    setEmpty("");
    setImgLink("");
    props.onHide();
  }

  return (
    <div>
      <Modal
        show={props.show}
        size="lg"
        style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header id="editnewcourse7">
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Course
          </Modal.Title>
          <div onClick={Hide} id="editnewcourse4">
            <MdClose />
          </div>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <Row>
                  <Form.Label id="editnewcourse8">
                    Select Course Thumbnail
                  </Form.Label>
                  <Col md={9} lg={10}>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="file"
                        id="editnewcourse1"
                        value={empty}
                        onChange={attachImage}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={2} md={2}>
                    <Button id="editnewcourse3" onClick={UploadEditThumbNail}>
                      {uploadBtnLoader ? (
                        <Spinner
                          animation="border"
                          size="sm"
                          variant="warning"
                        />
                      ) : (
                        "Upload"
                      )}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-3 mb-3">
              <Col id="editnewcourse6">
                <img
                  id="editnewcourse5"
                  src={imgLink === "" ? imagePreview : imgLink}
                  alt="Course Thumbnail"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label id="editnewcourse8">Enter Course Name</Form.Label>
                <Form.Control
                  id="editnewcourse1"
                  className="mb-3"
                  type="text"
                  placeholder="Course Name"
                  value={courseName}
                  onChange={(e) => setCourseName(e.target.value)}
                />
              </Col>
            </Row>
            {selectedCategory && (
              <div className="d-flex">
                <h6>Selected Category: {selectedCategory.category_name}</h6>
              </div>
            )}
            <Row>
              <div>
                <DropdownButton
                  id="dropdown-basic-button"
                  title={
                    selectedCategory
                      ? selectedCategory.category_name
                      : "Select Course Category"
                  }
                  onSelect={(eventKey) =>
                    handleSelect(
                      categoryData.find((cat) => cat._id === eventKey)
                    )
                  }
                >
                  {categoryData.map((category) => (
                    <Dropdown.Item
                      key={category._id}
                      eventKey={category._id}
                      id="dropdown-basic-item"
                    >
                      {category?.category_name}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>

                {/*  */}
              </div>
            </Row>
            <Row>
              <Col>
                <Form.Label id="editnewcourse8">Enter Course Price</Form.Label>
                <Form.Control
                  id="editnewcourse1"
                  className="mb-3"
                  type="text"
                  placeholder="Course Price"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label id="editnewcourse8">
                    Enter Course Description
                  </Form.Label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={courseDescription}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setCourseDescription(data);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row>
              <Col>
                <GlobalButton
                  title="Update"
                  loading={postLoader}
                  onclick={() => AddNewCourseFunction()}
                />
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default memo(EditNewCourseModal);
