import React, { useEffect, useState } from "react";
import "./TeacherViewDetails.css";
import { Col, Row, Container } from "react-bootstrap";
import profile from "../../assets/profile.png";
import CourseCard from "../../components/CourseCard/CourseCard";
import Header2 from "../../components/Header2/Header2";
import { useLocation, useNavigate } from "react-router-dom";
import Utility from "../../utils/Utility";
import { apicaller } from "../../utils/api";
import { useDispatch, useSelector } from "react-redux";
import { clear, getUserToken } from "../../Redux/Slice/UserSlice";
import TopratedCourseCard from "../../components/TopratedCourseCard/TopratedCourseCard";
import courseimg from "../../assets/image 5.png";
import { Button } from "@mui/material";
import { Rating } from "react-simple-star-rating";
import Courseadmincards from "../../components/Courseadmincards/Courseadmincards";
import { GiBookmark } from "react-icons/gi";
import BackButton from "../../components/BackButton";

export default function TeacherViewDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const Token = useSelector(getUserToken);
  const teacherid = location?.state?.res?._id;
  const teacherDetails = location?.state?.res;
  console.log("Teacher id is......", teacherid);
  const [courseDetails, setCourseDetails] = useState([]);

  console.log("teacher id in teacher view details", teacherDetails);

  useEffect(() => {
    getSlotById();
  }, []);

  const getSlotById = async () => {
    await apicaller(
      `all-course-assign-to-teacher/${teacherid}`,
      null,
      "get",
      Token
    )
      .then((res) => {
        setCourseDetails(res?.data?.course);
        console.log("Teacher details is......", res?.data?.course);
      })
      .catch((err) => {
        if (err?.response?.data?.error === "Token Session expired") {
          Utility.eToast(
            "Your token has expired, Please Login again to continue!"
          );
          dispatch(clear());
          navigate("/login");
        } else {
          Utility.eToast("Unable to fetch Slots !");
        }
      });
  };

  console.log("teacherview details", courseDetails);
  return (
    <>
      {/* <Header2 /> */}
      <Container>
        <BackButton />
        <div id="teacherveiwdetails12" className="mt-4">
          <Row>
            <Col md={1} lg={1} sm={12} xs={12}>
              <div>
                {teacherDetails?.profile_image ? (
                  <img
                    src={teacherDetails?.profile_image}
                    alt="image"
                    id="teacherviewdetails1"
                  />
                ) : (
                  <div id="teacherveiwdetails7">
                    {teacherDetails?.first_name
                      ? teacherDetails?.first_name.slice(0, 1)?.toUpperCase()
                      : ""}
                  </div>
                )}
              </div>
            </Col>
            <Col md={7} lg={7} sm={12} xs={12} id="teacherveiwdetails8">
              <Row id="teacherveiwdetails11">
                <Col md={2} lg={2} sm={12} xs={12}>
                  <div id="teacherveiwdetails9">Name</div>
                </Col>
                <Col md={10} lg={10} sm={12} xs={12}>
                  <div id="teacherveiwdetails10">
                    {" "}
                    : {teacherDetails?.first_name || "N/A"}
                  </div>
                </Col>
              </Row>
              <Row id="teacherveiwdetails11">
                <Col md={2} lg={2} sm={12} xs={12}>
                  <div id="teacherveiwdetails9">Center</div>
                </Col>
                <Col md={10} lg={10} sm={12} xs={12}>
                  <div id="teacherveiwdetails10">
                    {" "}
                    :{" "}
                    {teacherDetails?.centerId?.center_name &&
                    teacherDetails?.centerId?.district_id
                      ? `${teacherDetails?.centerId?.center_name}, ${teacherDetails?.centerId?.district_id?.district_name}, ${teacherDetails?.centerId?.district_id?.states}`
                      : "No Center Assigned"}
                  </div>
                </Col>
              </Row>
              <Row id="teacherveiwdetails11">
                <Col md={2} lg={2} sm={12} xs={12}>
                  <div id="teacherveiwdetails9">Email</div>
                </Col>
                <Col md={10} lg={10} sm={12} xs={12}>
                  <div id="teacherveiwdetails10">
                    {" "}
                    : {teacherDetails?.email || "N/A"}
                  </div>
                </Col>
              </Row>
              <Row id="teacherveiwdetails11">
                <Col md={2} lg={2} sm={12} xs={12}>
                  <div id="teacherveiwdetails9">Phone</div>
                </Col>
                <Col md={10} lg={10} sm={12} xs={12}>
                  <div id="teacherveiwdetails10">
                    {" "}
                    : {teacherDetails?.phone_number || "N/A"}
                  </div>
                </Col>
              </Row>
              <Row id="teacherveiwdetails11">
                <Col md={2} lg={2} sm={12} xs={12}>
                  <div id="teacherveiwdetails9">Qualification</div>
                </Col>
                <Col md={10} lg={10} sm={12} xs={12}>
                  <div id="teacherveiwdetails10">
                    {" "}
                    : {teacherDetails?.highest_qualification || "N/A"}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={3} lg={3} sm={12} xs={12}>
              <div id="teacherveiwdetails14">
                <Courseadmincards
                  title="Total Course Added"
                  count={courseDetails?.length || 0}
                  icon={<GiBookmark id="dash20" />}
                />
              </div>
            </Col>
          </Row>
        </div>
        <Row>
          <Col md={12} lg={12} sm={12} xs={12}>
            <div id="teacherveiwdetails15">Assigned Courses</div>
          </Col>
        </Row>
        <hr></hr>
        <div>
          <Row>
            <Row>
              {courseDetails && courseDetails.length > 0 ? (
                courseDetails.map((course) => (
                  <Col
                    key={course?._id}
                    md={3}
                    lg={3}
                    sm={12}
                    xs={12}
                    id="courseadmin26"
                  >
                    <CourseCard courseDetails={course} token={Token} />
                    {/* <div id="teacherveiwdetails13">
                      <img
                        src={course?.img}
                        style={{ width: "100%", height: "8rem" }}
                        alt="image"
                      />
                      <Row id="courseadmin17">
                        <Col md={8} lg={8} sm={8} xs={8}>
                          <div id="courseadmin18">{course?.name || "N/A"}</div>
                        </Col>
                        <Col md={4} lg={4} sm={4} xs={4}></Col>
                      </Row>
                      <Row id="courseadmin23">
                        <Col md={6} lg={6} sm={6} xs={6}>
                          <div id="courseadmin21">{course?.price}/-</div>
                        </Col>
                        <Col md={6} lg={6} sm={6} xs={6}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              marginTop: "0.6rem",
                            }}
                          >
                            <Rating
                              size={18}
                              fillColor="#02af4c"
                              initialValue={course?.ratings || 0}
                              readonly
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row id="courseadmin25">
                        <Col md={12} lg={12} sm={12} xs={12}>
                          <div>
                            <Button id="courseadmin24">View Details</Button>
                          </div>
                        </Col>
                      </Row>
                    </div> */}
                  </Col>
                ))
              ) : (
                <Col md={12} lg={12} sm={12} xs={12}>
                  <div id="courseadmin27">No course found !</div>
                </Col>
              )}
            </Row>
          </Row>
        </div>
      </Container>
    </>
  );
}
