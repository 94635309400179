import React, { useState } from "react";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Utility from "../../../utils/Utility";
import GlobalButton from "../../GlobalButton/GlobalButton";
import { clear, getUserToken } from "../../../Redux/Slice/UserSlice";
import { apicaller } from "../../../utils/api";

export default function EditChapter(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = useSelector(getUserToken);

  const { selectedChapter = {} } = props;

  const previousChapterName = selectedChapter?.title;
  const chapterId = selectedChapter?._id;

  const [chapterName, setChapterName] = useState(previousChapterName);
  const [isLoading, setIsLoading] = useState(false);

  function isValidData() {
    if (chapterName === "") {
      Utility.eToast("Please Make Changes To Update");
      return false;
    }
    return true;
  }
  const handleUpdateChapter = async () => {
    if (isValidData()) {
      setIsLoading(true);
      const data = {
        title: chapterName || previousChapterName,
        _id: chapterId,
      };
      await apicaller("course-module", data, "PUT", token)
        .then((res) => {
          Utility.sToast("Chapter Updated Successfully");
          props.getcoursesectionlist();
          setIsLoading(false);
          props.HideModal();
        })
        .catch((err) => {
          if (err?.response?.data?.error === "Token Session expired") {
            Utility.eToast("Token expired, Please login again ! ");
            dispatch(clear());
            navigate("/");
            setIsLoading(false);
          } else {
            setIsLoading(false);

            Utility.eToast(
              err?.response?.data?.error || "Oops Something went wrong"
            );
          }
        });
    }
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Edit Chapter
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col md={12}>
              <Form.Group className="mb-3">
                <Form.Label>Enter Chapter Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Chapter name"
                  defaultValue={previousChapterName}
                  onChange={(e) => setChapterName(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <GlobalButton
          title="Close"
          width={130}
          height="2.3rem"
          backgroundColor="red"
          marginRight="1rem"
          disabled={isLoading}
          onclick={() => {
            setChapterName("");
            props.HideModal();
          }}
        />
        <GlobalButton
          title="Update"
          width={130}
          height="2.3rem"
          loading={isLoading}
          onclick={() => {
            handleUpdateChapter();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
