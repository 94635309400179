import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Rating } from "react-simple-star-rating";
import "./CourseRatings.css";

function CourseRatings({ courseDetails }) {
  console.log(courseDetails, "CourseRatings 45678");
  const courseReviews = courseDetails?.reviews;

  const FiveStar = courseReviews?.filter((item) => item?.rating == 5);
  const FourStar = courseReviews?.filter((item) => item?.rating == 4);
  const ThreeStar = courseReviews?.filter((item) => item?.rating == 3);
  const TwoStar = courseReviews?.filter((item) => item?.rating == 2);
  const OneStar = courseReviews?.filter((item) => item?.rating == 1);

  return (
    <Container className="ratingContainer p-4">
      <Row className="ratingWrapper">
        <div id="courseContentCol2">
          <div id="courseContentCol2GreenBar" />
          <div id="courseContentCol3">Ratings & Comments</div>
        </div>
      </Row>
      <Row className="ratingWrapper">
        <Col md={4}>
          <div className="ratingRowContainer mb-3 d-flex">
            <div className="reviewAvg">{courseDetails?.ratings}</div>
            <div>
              <Rating
                stop={5}
                initialValue={courseDetails?.ratings}
                allowFraction
                size={20}
                readonly
              />
              <div className="reviewCount">
                Total ratings {courseReviews ? courseReviews.length : 0}
              </div>
            </div>
          </div>
        </Col>

        <Col md={6}>
          <div className="mb-3"></div>{" "}
          <div id="commentsTxt">
            <div className="reviewCount">
              <Rating
                initialValue={5}
                ratingValue={1}
                stop={1}
                allowFraction
                size={16}
                readonly
              />
              <div style={{ marginTop: 5, marginLeft: 5 }}>
                {FiveStar?.length} Ratings
              </div>
            </div>
            <div className="reviewCount">
              <Rating
                initialValue={4}
                ratingValue={1}
                stop={1}
                allowFraction
                size={16}
                readonly
              />
              <div style={{ marginTop: 5, marginLeft: 5 }}>
                {FourStar?.length} Ratings
              </div>
            </div>
            <div className="reviewCount">
              <Rating
                initialValue={3}
                ratingValue={1}
                stop={1}
                allowFraction
                size={16}
                readonly
              />
              <div style={{ marginTop: 5, marginLeft: 5 }}>
                {ThreeStar?.length} Ratings
              </div>
            </div>
            <div className="reviewCount">
              <Rating
                initialValue={2}
                ratingValue={1}
                stop={1}
                allowFraction
                size={16}
                readonly
              />
              <div style={{ marginTop: 5, marginLeft: 5 }}>
                {TwoStar?.length} Ratings
              </div>
            </div>
            <div className="reviewCount">
              <Rating
                initialValue={1}
                ratingValue={1}
                stop={1}
                allowFraction
                size={16}
                readonly
              />
              <div style={{ marginTop: 5, marginLeft: 5 }}>
                {OneStar?.length} Ratings
              </div>
            </div>
          </div>
          {/* {courseDetails?.reviews?.map((item) => (
            <div
              className="mb-2"
              style={{
                background: "#d3d3d3",
                borderRadius: 10,
                padding: 10,
              }}
            >
              <div id="givenCommentTxt">
                <span
                  style={{
                    fontWeight: "500",
                    fontSize: 18,
                    marginRight: 20,
                    backgroundColor: "green",
                    color: "white",
                    paddingLeft: 5,
                    paddingRight: 5,
                    display: "inline-flex",
                    alignItems: "center",
                    borderRadius: 5,
                  }}
                >
                  {item.rating}{" "}
                  <BsStarFill
                    color="orange"
                    size={15}
                    style={{ marginLeft: 3 }}
                  />
                </span>
                {item?.comment}
              </div>
              <div id="commentGivenBy">By {item?.name}</div>
            </div>
          ))} */}
        </Col>
      </Row>

      {courseDetails?.reviews?.map((item) => (
        <Row className="ratingWrapper">
          <Col md={4} className="d-flex flex-column align-items-center">
            <div className="profileImage">
              {item?.name?.slice(0, 1)?.toUpperCase()}
            </div>
            <div className="profileName my-2">{item?.name}</div>
            {/* <div className="text-muted reviewCount">1 week ago</div> */}
          </Col>
          <Col>
            <Rating
              stop={5}
              initialValue={item?.rating}
              allowFraction
              size={20}
              readonly
            />
            <p className=" mt-2 commentText">{item?.comment}</p>
          </Col>
        </Row>
      ))}
    </Container>
  );
}

export default CourseRatings;
