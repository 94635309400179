import React from "react";
import "./VideoPlayerComp.css";
import ReactPlayer from "react-player";

export default function VideoPlayerComp({ url }) {
  return (
    <ReactPlayer
      url={url}
      controls={true}
      width="100%"
      height="225px"
      config={{
        file: {
          attributes: {
            controlsList: "nodownload",
          },
        },
        attributes: {
          disablePictureInPicture: true,
        },
      }}
    />
  );
}
