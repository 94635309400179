import React from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import "./TableComponent.css";

// const columns = [
//   {
//     accessorKey: "slno",
//     header: "Sl No",
//   },
//   {
//     accessorKey: "name",
//     header: "Name",
//   },
//   {
//     accessorKey: "email",
//     header: "Email",
//   },
//   {
//     accessorKey: "phone",
//     header: "Phone",
//   },
//   {
//     accessorKey: "institute",
//     header: "Institute",
//   },
// ];

// const data = [
//   {
//     slno: 1,
//     name: "Bhabani",
//     email: "bhabani@gmail.com",
//     phone: 9090909090,
//     institute: "Utkal University",
//   },
//   {
//     slno: 2,
//     name: "Bhabani",
//     email: "bhabani@gmail.com",
//     phone: 9090909090,
//     institute: "Utkal University",
//   },
// ];

export default function TableComponent({
  columns = [],
  data = [],
  customStyle = false,
}) {
  const table = useMaterialReactTable({
    columns,
    data,
  });

  return (
    <div className={customStyle && "custom-table-container"}>
      <MaterialReactTable table={table} />
    </div>
  );
}
