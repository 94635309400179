import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./AssignmentDetail.css";
import { Col, Container, Row } from "react-bootstrap";
// import DocIcon from "../../../../Assets/Icons/docIcon.svg";
import DocIcon from "../../assets/Icons/docIcon.svg";
import moment from "moment";

export default function AssignmentDetail(props) {
  const { item } = props;

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Assignment Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Row id="AlabelRow">
            <Col id="AModalCardlabel">
              <div>Topic: {item?.assignment?.topic}</div>
            </Col>
            <Col md={4} id="AModalCardStatusCol">
              <div
                style={
                  item?.status === "assigned"
                    ? { backgroundColor: "#F5A201" }
                    : item?.status === "submitted"
                    ? { backgroundColor: "blue" }
                    : { backgroundColor: "green" }
                }
                id="AModalCardStatusdiv"
              >
                {item?.status?.toUpperCase()}
              </div>
            </Col>
          </Row>
          <div style={{ padding: "0.9rem" }}>
            <Row>
              <Col id="AModalFileText">
                <img src={DocIcon} id="AModaldocIcon" />{" "}
                {item?.assignment?.document_name}
              </Col>
              <Col md={4} id="AModalCardStatusCol">
                <div id="AModaldownloadText">
                  {" "}
                  <a
                    style={{ textDecoration: "none" }}
                    href={item?.assignment?.document}
                    target="_blank"
                  >
                    Download
                  </a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div id="AModalDescrip">{item?.assignment?.description}</div>
              </Col>
            </Row>
            <Row>
              <Col>
                {props.deadlinePassed ? (
                  <div id="lastSubmissionText">
                    The submission deadline has passed.
                  </div>
                ) : (
                  <Row id="lastSubmissionText">
                    <Col>
                      <div>Last date of submission</div>
                    </Col>
                    <Col md={5} id="assingmentCardStatusCol">
                      <div>
                        {moment(item?.assignment?.last_date).format(
                          "Do MMM YYYY"
                        )}
                      </div>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </div>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button id="AModalsubmitButton" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
