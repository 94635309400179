import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apicaller } from "../../../utils/api";
import { clear, getUserToken } from "../../../Redux/Slice/UserSlice";
import { useNavigate } from "react-router-dom";
import Utility from "../../../utils/Utility";

export default function useDashboard() {
  const [spin, setSpin] = useState(true);
  const [allTeacher, setAllTeacher] = useState([]);
  const [allStudent, setAllStudent] = useState([]);
  const [allSubadmin, setAllSubadmin] = useState([]);
  const [allOrder, setAllOrder] = useState([]);
  const [allExam, setAllExam] = useState([]);
  const [courseAdmin, setCourseAdmin] = useState([]);
  const [centers, setCenters] = useState([]);
  const [allassignment, setAllAssignment] = useState([]);

  useEffect(() => {
    getTeacherList();
    getStudentList();
    getSubadminList();
    getAllOrder();
    getAllExam();
    getAllCourseAdmin();
    getCenters();
    getAllAssignments();
  }, []);

  const Token = useSelector(getUserToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getTeacherList = () => {
    apicaller(`admin/teacher-list`, null, "GET", Token, null)
      .then((res) => {
        setAllTeacher(res?.data?.teacher?.length);
      })
      .catch((err) => {
        if (err?.response?.data?.error === "Token Session expired") {
          Utility.eToast(
            "Your token has expired, Please Login again to continue!"
          );
          dispatch(clear());
          navigate("/login");
        }
      });
  };

  const getStudentList = () => {
    apicaller(`student`, null, "get", Token).then((res) => {
      setAllStudent(res?.data?.data?.length);
    });
  };

  const getSubadminList = () => {
    apicaller(`admin/subadmin-list`, null, "get", Token).then((res) => {
      setAllSubadmin(res?.data.user);
    });
  };

  const getAllOrder = async () => {
    await apicaller("order?status=paid", null, "get", Token, null)
      .then((res) => {
        setAllOrder(res?.data?.totalPriceSum);
        setSpin(false);
      })
      .catch(() => {
        console.log("error in alluser");
      });
  };

  const getAllExam = async () => {
    await apicaller("allexam", null, "GET", Token, null)
      .then((res) => {
        setAllExam(res?.data?.examData?.length);
      })
      .catch((err) => console.log(err));
  };

  const getAllCourseAdmin = async () => {
    await apicaller("course-admin-get", null, "GET", Token, null)
      .then((res) => {
        setCourseAdmin(res?.data?.data?.length);
      })
      .catch((err) => console.log(err));
  };

  const getCenters = async () => {
    await apicaller("get-center", null, "GET", Token, null)
      .then((res) => {
        setCenters(res?.data?.center?.length);
      })
      .catch((err) => console.log(err));
  };

  const getAllAssignments = async () => {
    await apicaller("allassignment", null, "GET", Token, null)
      .then((res) => {
        setAllAssignment(res?.data?.length);
      })
      .catch((err) => console.log(err));
  };

  return {
    spin,
    allSubadmin,
    allOrder,
    allExam,
    allStudent,
    courseAdmin,
    allTeacher,
    centers,
    allassignment,
  };
}
