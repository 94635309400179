import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "./SettingsTopBar.css";
import Privacy from "../../pages/Privacy/Privacy";
import RefundPolicy from "../../pages/RefundPolicy/RefundPolicy";
import Terms from "../../pages/TermsCondition/Terms";
import Aboutus from "../../pages/Aboutus/Aboutus";
import SettingsManageCourseAdmin from "../../pages/Settings/components/SettingsManageCourseAdmin/SettingsManageCourseAdmin";
import SettingsBanner from "../../pages/Settings/components/SettingsBanner/SettingsBanner";
import SettingsCoupon from "../../pages/Settings/components/SettingsCoupon/SettingsCoupon";
import ContactUs from "../../pages/ContactUs/ContactUs";
import { useDispatch, useSelector } from "react-redux";
import {
  getChosenSettingTab,
  setChosenSettingsTab,
} from "../../Redux/Slice/UserSlice";
import Services from "../../pages/Services/Services";
import Coupons from "../../pages/Coupons/Coupons";

export default function SettingsTopBar() {
  const chosenSettingsTab = useSelector(getChosenSettingTab);
  const dispatch = useDispatch();

  const [value, setValue] = React.useState(chosenSettingsTab || "aboutUs");

  const handleChange = (event, newValue) => {
    dispatch(setChosenSettingsTab(newValue));
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            variant="scrollable"
            scrollButtons="auto"
            TabScrollButtonProps={{
              sx: {
                color: "#1814F3",
              },
            }}
            TabIndicatorProps={{
              sx: {
                backgroundColor: "#1814F3",
                height: 4,
                borderRadius: "20px 20px 0px 0px",
              },
            }}
          >
            {/* <Tab
              label="Edit Profile"
              value="editProfile"
              style={{
                color: "#718EBF",
                fontFamily: "IBM Plex Sans",
                fontSize: 14,
              }}
            /> */}
            {/* <Tab
              label="Manage Course Admin"
              value="manageCourseAdmin"
              style={{
                color: "#718EBF",
                fontFamily: "IBM Plex Sans",
                fontSize: 14,
              }}
            /> */}
            <Tab
              label="About Us"
              value="aboutUs"
              style={{
                color: "#718EBF",
                fontFamily: "IBM Plex Sans",
                fontSize: 14,
              }}
            />
            <Tab
              label="Banner"
              value="banner"
              style={{
                color: "#718EBF",
                fontFamily: "IBM Plex Sans",
                fontSize: 14,
              }}
            />
            {/* <Tab
              label="Carousel"
              value="carousel"
              style={{
                color: "#718EBF",
                fontFamily: "IBM Plex Sans",
                fontSize: 14,
              }}
            /> */}
            <Tab
              label="Coupon"
              value="coupon"
              style={{
                color: "#718EBF",
                fontFamily: "IBM Plex Sans",
                fontSize: 14,
              }}
            />
            <Tab
              label="Contact Us"
              value="contactUs"
              style={{
                color: "#718EBF",
                fontFamily: "IBM Plex Sans",
                fontSize: 14,
              }}
            />
            <Tab
              label="Services"
              value="services"
              style={{
                color: "#718EBF",
                fontFamily: "IBM Plex Sans",
                fontSize: 14,
              }}
            />
            <Tab
              label="Privacy Policy"
              value="privacyPolicy"
              style={{
                color: "#718EBF",
                fontFamily: "IBM Plex Sans",
                fontSize: 14,
              }}
            />
            <Tab
              label="Refund Policy"
              value="refundPolicy"
              style={{
                color: "#718EBF",
                fontFamily: "IBM Plex Sans",
                fontSize: 14,
              }}
            />
            <Tab
              label="Terms & Conditions"
              value="terms&Conditions"
              style={{
                color: "#718EBF",
                fontFamily: "IBM Plex Sans",
                fontSize: 14,
              }}
            />
          </TabList>
        </Box>
        {/* body */}
        {/* <TabPanel value="editProfile">
          <Editprofile />
        </TabPanel> */}
        <TabPanel value="manageCourseAdmin">
          <SettingsManageCourseAdmin />
        </TabPanel>
        <TabPanel value="aboutUs">
          <Aboutus />
        </TabPanel>
        <TabPanel value="banner">
          <SettingsBanner />
        </TabPanel>
        <TabPanel value="coupon">
          <SettingsCoupon />
          {/* <Coupons /> */}
        </TabPanel>
        <TabPanel value="contactUs">
          <ContactUs />
        </TabPanel>
        <TabPanel value="services">
          <Services />
        </TabPanel>
        <TabPanel value="privacyPolicy">
          <Privacy />
        </TabPanel>
        <TabPanel value="refundPolicy">
          <RefundPolicy />
        </TabPanel>
        <TabPanel value="terms&Conditions">
          <Terms />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
