import React from 'react';
import "./Logoutmodal.css";
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clear } from '../../Redux/Slice/UserSlice';
import Utility from '../../utils/Utility';

export default function Logoutmodal(props) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    function Logout() {
      dispatch(clear());
      navigate("/login");
      Utility.sToast("LogOut Successfully ! ");
    }

  return (
    <>
   <Modal
        id="logm4"
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {" "}
          <div style={{ fontSize: "1.1rem", fontWeight: "500" }}>
            Logging Out
          </div>
        </Modal.Header>
        <Modal.Body
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{ color: "#565756", fontSize: "1rem", fontWeight: "400" }}
          >
            Are You Sure You Want To logOut ?
          </div>
        </Modal.Body>
        <Row id="logm1">
          <Col md={4} lg={4} sm={12} xs={12}></Col>
          <Col md={2} lg={2} sm={12} xs={12}>
            <Button size="sm" id="suspend4" onClick={props.onHide}>
              No
            </Button>
          </Col>
          <Col md={2} lg={2} sm={12} xs={12}>
            {" "}
            <Button size="sm" onClick={() => Logout()} id="suspend5">
              Yes
            </Button>
          </Col>
          <Col md={4} lg={4} sm={12} xs={12}></Col>
        </Row>
      </Modal>
    </>
  )
}
