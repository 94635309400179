import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import DocIcon from "../../assets/Icons/docIcon.svg";
import moment from "moment";
import { FaInfo } from "react-icons/fa6";
import { apicaller } from "../../utils/api";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clear, getUserToken } from "../../Redux/Slice/UserSlice";
import Utility from "../../utils/Utility";
import AssignmentDetail from "../AssignmentDetails/AssignmentDetail";

function StudentAssignment({ studentDetails, assignmentDetails }) {
  const [assignmentStatus, setAssignmentStatus] = useState("Assigned");
  const [assignDetailModal, setAssignDetailModal] = useState(false);
  const [submitAssignModal, setSubmitAssignModal] = useState(false);
  const [selectedAssignmentDetails, setSelectedAssignmentDetails] =
    useState(null);
  const returnStatusColor = (status) => {
    switch (status) {
      case "assigned":
        return "#F5A201";
      case "submitted":
        return "#22C55E";
      // case "Pending":
      //   return "#F5A201";
      default:
        return "#F5A201";
    }
  };

  const ShowButtonAsStatus = ({ status, item }) => {
    const dateToCompare = moment(item?.assignment?.last_date); // Use your date here
    // Get today's date
    const today = moment();
    if (status === "assigned") {
      return (
        <Row>
          <Col>
            <Button
              id="detailButton"
              onClick={() => {
                setAssignDetailModal(true);
                setSelectedAssignmentDetails(item);
              }}
            >
              Details
            </Button>
          </Col>
          <Col>
            <Button
              id="submitButton"
              onClick={() => setSubmitAssignModal(true)}
            >
              Submit
            </Button>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row>
          <Col md={10}>
            <Button id="submittedButton">Submitted on 28th july 2024</Button>
          </Col>
          <Col md={2}>
            <Button
              id="infoButton"
              onClick={() => {
                setAssignDetailModal(true);
                setSelectedAssignmentDetails(item);
              }}
            >
              <FaInfo />
            </Button>
          </Col>
        </Row>
      );
    }
  };
  return (
    <div className="d-flex">
      {assignmentDetails.map((item) => (
        <Col lg={4} md={6} xs={12} key={item.id}>
          <div id="assignmentCardContainer" className="me-4">
            <div style={{ borderBottom: "1px solid gray" }}>
              <Row id="labelRow">
                <Col id="assignementCardlabel">
                  <div>Topic: {item?.assignment?.topic}</div>
                </Col>
                <Col md={5} id="assingmentCardStatusCol">
                  <div
                    style={{
                      backgroundColor: returnStatusColor(item?.status),
                    }}
                    id="assignementCardStatusdiv"
                  >
                    {item?.status}
                  </div>
                </Col>
              </Row>
            </div>
            <div style={{ padding: "0.9rem" }}>
              <Row>
                <Col id="assignmentFileText">
                  <img src={DocIcon} id="docIcon" alt="Document Icon" />{" "}
                  {item?.assignment?.document_name}
                </Col>
                <Col md={4} id="assingmentCardStatusCol">
                  <div id="downloadText">
                    <a
                      style={{ textDecoration: "none" }}
                      href={item?.assignment?.document}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download
                    </a>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div id="assignmentDescription">
                    {item?.assignment?.description?.slice(0, 80)}....
                  </div>
                </Col>
              </Row>
              <Row id="lastSubmissionText">
                <Col>
                  <div>
                    Last date of submission:{" "}
                    {moment(item?.assignment?.last_date).format("Do MMM YYYY")}
                  </div>
                </Col>
              </Row>
              <ShowButtonAsStatus status={item?.status} item={item} />
            </div>
          </div>
        </Col>
      ))}
      <AssignmentDetail
        show={assignDetailModal}
        onHide={() => setAssignDetailModal(false)}
        item={selectedAssignmentDetails}
      />
    </div>
  );
}

export default StudentAssignment;
