import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import moment from "moment";

function StudentResults({ studentDetails, examResultDetails }) {
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
      },
    },
  };

  const columns = [
    {
      name: "NO",
      cell: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Course",
      cell: (row) => row?.course_id?.name,
      sortable: true,
    },
    {
      name: "Exam Topic",
      cell: (row) => row?.exam_id?.topic,
      sortable: true,
    },
    {
      name: "Date & time",
      cell: (row) => {
        const findMySlot = row?.exam_id?.slotByStudent?.find((item) => item?.student_id === studentDetails?._id);
        const startTime = findMySlot?.startTime;
        const endTime = findMySlot?.endTime;
        const formattedDate = moment(findMySlot?.date).format(
          "Do MMM YYYY"
        );
        return `${formattedDate}, [${startTime} - ${endTime}]`;
      },

      sortable: true,
    },
    {
      name: "Total Marks",
      cell: (row) => row?.total_marks || "N/A",
      sortable: true,
    },
    {
      name: "Score",
      cell: (row) => row?.score || "N/A",
      sortable: true,
    },
    {
        name: "Percentage",
        cell: (row) => row?.percentage || "N/A",
        sortable: true,
      },
    {
      name: "Feedback",
      cell: (row) => row?.remark || "N/A",
      sortable: true,
    },
  ];

  return (
    <DataTable
      columns={columns}
      data={examResultDetails}
      pagination
      paginationPerPage={10}
      customStyles={customStyles}
    />
  );
}

export default StudentResults;
