import React from "react";
import { Container } from "react-bootstrap";
function SaleComp({ text, amount }) {
  return (
    <Container fluid className="FinanceComponent">
      <span className="Financetext-2">{text}</span> <br />
      <span className="Financetext-1">₹ {amount}</span>
    </Container>
  );
}

export default SaleComp;
