import React, { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import GlobalButton from "../../GlobalButton/GlobalButton";
import { clear, getUserToken } from "../../../Redux/Slice/UserSlice";
import Utility from "../../../utils/Utility";
import { apicaller } from "../../../utils/api";
// import { clear } from "../../../Redux/Slice/userSlice";

function AddChapter(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { courseAndSectionid = {}, getcoursesectionlist } = props;
  const token = useSelector(getUserToken);

  const courseId = courseAndSectionid?.courseId || "";
  const sectionId = courseAndSectionid?.sectionId || "";

  console.log("getting data in add sec modal", courseAndSectionid);

  const [chapterTitle, setChapterTitle] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [isAddChapterLoading, setIsAddChapterLoading] = useState("");
  const [studyMaterialUrl, setStudyMaterialUrl] = useState("");
  const [selectedStudyMaterialDoc, setSelectedStudyMaterialDoc] =
    useState(null);
  const [docName, setDocName] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const handleUpdloadStudyMaterial = async (e) => {
    setIsUploading(true);
    setSelectedStudyMaterialDoc(e?.target?.value);
    setDocName(e?.target?.files[0]?.name);
    const data = new FormData();
    data.append("img", e.target.files[0]);
    await apicaller("single-upload", data, "POST", token, "multipart/form-data")
      .then((res) => {
        Utility.sToast("Document uploaded successfully");
        setStudyMaterialUrl(res?.data?.img);
        setIsUploading(false);
      })
      .catch((err) => {
        setIsUploading(false);
        Utility.eToast(
          err?.response?.data?.error || "Oops Something went wrong"
        );
      });
  };

  function isValid() {
    if (chapterTitle?.length === 0) {
      Utility.eToast("Please enter a chapter name");
      return false;
    } else if (videoUrl?.length === 0) {
      Utility.eToast("Please enter video url");
      return false;
    } else if (selectedStudyMaterialDoc?.length === 0) {
      Utility.eToast("Please select study material");
      return false;
    } else {
      return true;
    }
  }

  const handleAddNewChapter = async () => {
    if (isValid()) {
      setIsAddChapterLoading(true);
      var data = {
        title: chapterTitle,
        course_id: courseId,
        section_id: sectionId,
        video_path: videoUrl,
        document: studyMaterialUrl,
        document_name: docName,
      };

      await apicaller("course-module", data, "POST", token)
        .then((res) => {
          setIsAddChapterLoading(false);
          getcoursesectionlist();
          setDocName("");
          setSelectedStudyMaterialDoc(null);
          setStudyMaterialUrl("");
          setChapterTitle("");
          setVideoUrl("");
          props.onHide();
        })
        .catch((err) => {
          if (err?.response?.data?.error === "Token Session expired") {
            Utility.eToast("Token expired, Please login again ! ");
            props.onHide();
            dispatch(clear());
            navigate("/");
          } else {
            Utility.eToast(
              err?.response?.data?.error || "Oops Something went wrong"
            );
          }
          setIsAddChapterLoading(false);
        });
    }
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Chapter
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col md={12}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Enter Chapter Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Chapter name"
                  value={chapterTitle}
                  onChange={(e) => setChapterTitle(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Enter Video Url</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Video Url"
                  value={videoUrl}
                  onChange={(e) => setVideoUrl(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group className="mb-3">
                <Form.Label>Select Study Material</Form.Label>
                <Form.Control
                  type="file"
                  accept=".pdf"
                  value={selectedStudyMaterialDoc}
                  onChange={handleUpdloadStudyMaterial}
                />
              </Form.Group>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <GlobalButton
          title="Close"
          width={130}
          height="2.3rem"
          backgroundColor="red"
          disabled={isUploading}
          marginRight="1rem"
          onclick={() => {
            props.onHide();
          }}
        />
        <GlobalButton
          title={isUploading ? "Uploading" : "Add"}
          width={130}
          height="2.3rem"
          loading={isAddChapterLoading || isUploading}
          onclick={() => {
            handleAddNewChapter();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default AddChapter;
