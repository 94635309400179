import React from "react";
import "./GlobalButton.css";
import { Button, Spinner } from "react-bootstrap";

export default function GlobalButton(props) {
  const {
    title,
    LeftIcon,
    RightIcon,
    iconSize,
    width,
    height,
    backgroundColor,
    textColor,
    fontWeight,
    borderRadius,
    borderStyle,
    borderColor,
    borderWidth,
    onclick,
    loading,
    loadingColor,
    loadingSize,
    boxShadow,
  } = props;

  return (
    <>
      {loading ? (
        <Button
          style={{
            // button
            width: width || "100%",
            height: height,
            background:
              backgroundColor ||
              "linear-gradient(180deg, #396AFF 0%, #381FD1 100%)",
            borderRadius: borderRadius || 10,
            borderStyle: borderStyle || "none",
            borderColor: borderColor || "#381FD1",
            zIndex: 1,
            boxShadow: boxShadow || "none",

            // text
            color: textColor || "#fff",
            fontFamily: "IBM Plex Sans",
            fontWeight: fontWeight || 900,
          }}
          disabled
          onClick={onclick}
        >
          {title}{" "}
          <Spinner
            animation="border"
            color={loadingColor || "#fff"}
            size={loadingSize || "sm"}
          />
        </Button>
      ) : (
        <Button
          style={{
            // button
            width: width || "100%",
            height: height,
            background:
              backgroundColor ||
              "linear-gradient(180deg, #396AFF 0%, #381FD1 100%)",
            borderRadius: borderRadius || 10,
            borderStyle: borderStyle || "solid",
            borderColor: borderColor || "#381FD1",
            borderWidth: borderWidth || 1,
            zIndex: 1,
            boxShadow: boxShadow || "none",

            // text
            color: textColor || "#fff",
            fontFamily: "IBM Plex Sans",
            fontWeight: fontWeight || 900,
          }}
          onClick={onclick}
        >
          {LeftIcon && <LeftIcon style={{ marginRight: 5 }} size={iconSize} />}{" "}
          {title}{" "}
          {RightIcon && <RightIcon style={{ marginLeft: 5 }} size={iconSize} />}
        </Button>
      )}
    </>
  );
}
