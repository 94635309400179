import React, { useEffect, useState } from "react";
import "./NewCourse.css";
import { Col, Container, Row } from "react-bootstrap";
import { CgMenuBoxed } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import AddCourse from "../../components/AddCourse/AddCourse";
import { apicaller } from "../../utils/api";
import { useDispatch, useSelector } from "react-redux";
import MonthwiseCourseComp from "../../components/MonthwiseCourseComp/MonthwiseCourseComp";
import DashPieChart from "../../components/DashPieChart/DashPieChart";
import AllCourses from "../../components/AllCourses/AllCourses";
import RoundLargeButton from "../../components/RoundLargeButton/RoundLargeButton";
import { GetAllCourses, clear } from "../../Redux/Slice/UserSlice";
import Utility from "../../utils/Utility";
import { unwrapResult } from "@reduxjs/toolkit";
import AddCourseModal from "../../components/AddCourseModal/AddCourseModal";

export default function NewCourse() {
  const [totalCourse, setTotalCourse] = useState([]);
  const [activeCours, setActiveCours] = useState([]);
  const [tdyAddCours, setTdyAddCours] = useState([]);
  // get / add course
  const [addCourseMod, setAddCourseMod] = useState(false);
  const [courseList, setCourseList] = useState([]);
  const [filterCourseList, setFilterCourseList] = useState([]);

  const token = useSelector((state) => state?.user?.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    totalCourseData();
    activeCourse();
    todayAddedCourse();
  }, []);

  const totalCourseData = async () => {
    await apicaller("getallcoursenostatus", null, "GET", token, null)
      .then((res) => {
        // console.log("=-=-=-=-=-=-=-=", res);
        setTotalCourse(res?.data?.course?.length);
      })
      .catch((err) => console.log(err));
  };

  const activeCourse = async () => {
    await apicaller("course", null, "GET", token, null)
      .then((res) => {
        // console.log("[][][][][]", res);
        setActiveCours(res?.data?.course?.length);
      })
      .catch((err) => console.log(err));
  };
  // console.log("first", activeCours);

  const todayAddedCourse = async () => {
    await apicaller("addedcoursetoday", null, "GET", token, null)
      .then((res) => setTdyAddCours(res?.data?.courses?.length))
      .catch((err) => console.log(err));
  };

  const getAllCourseList = async () => {
    await dispatch(GetAllCourses({ token: token }))
      .then(unwrapResult)
      .then((res) => {
        console.log(res, "results");
        setFilterCourseList(res);
        setCourseList(res);
      })
      .catch((err) => {
        if (err?.response?.data?.error === "Token Session expired") {
          Utility.eToast(
            "Your token has expired, Please Login again to continue!"
          );
          dispatch(clear());
          navigate("/login");
        }
      });
  };

  return (
    <>
      <Container className="nc8 ">
        <Row>
          <Col sm={12} xs={12} md={3} lg={3} xl={3} xxl={3}>
            <div id="nc1">
              {/* <div className="courses3"> */}
              <RoundLargeButton
                icon="+"
                name="Create"
                event={() => setAddCourseMod(true)}
              />
              {/* </div> */}
            </div>
            <AddCourseModal
              open={addCourseMod}
              close={() => setAddCourseMod(false)}
              fetchCourseList={getAllCourseList}
            />
          </Col>
          <Col sm={12} xs={12} md={4} lg={4} xl={4} xxl={4}>
            <div className="nc2">
              <Row>
                <Col>
                  <div className="nc3">Total Courses</div>
                  <div className="nc4">{totalCourse}</div>
                </Col>
                <Col className="nc6">
                  <CgMenuBoxed className="nc5" />
                </Col>
              </Row>
            </div>

            <div className="nc7">
              <Row>
                <Col>
                  <div className="nc3">Active courses</div>
                  <div className="nc4">{activeCours}</div>
                </Col>
                <Col className="nc6">
                  <CgMenuBoxed className="nc5" />
                </Col>
              </Row>
            </div>
            <div className="nc7">
              <Row>
                <Col>
                  <div className="nc3">Today added</div>
                  <div className="nc4">{tdyAddCours}</div>
                </Col>
                <Col className="nc6">
                  <CgMenuBoxed className="nc5" />
                </Col>
              </Row>
            </div>
          </Col>
          <Col sm={12} xs={12} md={5} lg={5} xl={5} xxl={5} className="nc9">
            {/* <CourseBarChart /> */}
            <MonthwiseCourseComp />
          </Col>
        </Row>
        {/* <Row>
          <TabContext value={activeTab}>
            <Tabs
              value={activeTab}
              onChange={handleChange}
              aria-label="exam tabs"
              className="exam-tabs"
            >
              <Tab
                label="Course"
                value="course"
                className={`exam-tab ${activeTab === "course" ? "active" : ""}`}
              />
              <Tab
                label="Course Settings"
                value="settings"
                className={`exam-tab ${
                  activeTab === "settings" ? "active" : ""
                }`}
              />
            </Tabs>
            <TabPanel value="course">
              <AddCourse />
            </TabPanel>
            <TabPanel value="settings">mhbjkhkj</TabPanel>
          </TabContext>
        </Row> */}
        <Row className="mt-5">
          <Col
            sm={12}
            xs={12}
            md={6}
            lg={6}
            xl={6}
            xxl={6}
            className="addcours102"
          >
            <DashPieChart />
          </Col>
          <Col sm={12} xs={12} md={6} lg={6} xl={6} xxl={6}>
            <AddCourse />
          </Col>
        </Row>
        <Row>
          <AllCourses
            getAllCourseList={getAllCourseList}
            courseList={courseList}
            setCourseList={setCourseList}
            filterCourseList={filterCourseList}
            setFilterCourseList={setFilterCourseList}
          />
        </Row>
      </Container>
    </>
  );
}
